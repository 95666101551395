/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import "./MessageList.css";
import Linkify from "react-linkify";
import { api } from "../Common/Common";

// import Skeleton from "@mui/material/Skeleton";

function TextMessage({ conversation, type }) {
  // eslint-disable-next-line no-useless-escape
  const regexp_url = /(https?|ftp):\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
  const [ogpUrl, setOgpUrl] = useState("");
  const [ogpTitle, setOgpTitle] = useState("");
  const [ogpDescription, setOgpDescription] = useState("");
  const [siteUrl, setSiteUrl] = useState("");
  const urlAllMatches = conversation.message.match(regexp_url);
  const mainproc = async () => {
    if (urlAllMatches != null) {
      // const reqapi = OGP_URL + `?url=${urlAllMatches[0]}`;
      // const result = await axios.post(reqapi);
      const result = await api.get(`?url=${urlAllMatches[0]}`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setSiteUrl(urlAllMatches[0]);
        setOgpTitle(result.data.title);
        setOgpDescription(result.data.description);
        setOgpUrl(result.data.ogpimage);
      }
    }
  };
  useEffect(() => {
    if (type === "message") {
      mainproc();
    }
  }, []);

  return (
    <>
      {type === "replymessage" ? (
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target={`blank${conversation.messageid}`} href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {conversation.replymessage}
        </Linkify>
      ) : type === "message" ? (
        <div>
          {urlAllMatches != null && (
            <>
              {ogpUrl === "" ? (
                <div />
              ) : (
                <div className="chat-message-ogp-image-div">
                  <img
                    src={ogpUrl}
                    //height="300"
                    alt=""
                    className="chat-message-ogp-image"
                    onClick={() => {
                      if (siteUrl != "") {
                        window.open(siteUrl, "_blank");
                      }
                    }}
                    //style={isCurrent ? { border: "1px solid white" } : null}
                  />
                  <div className="chat-message-ogp-title">{ogpTitle}</div>
                  <div className="chat-message-ogp-description">{ogpDescription}</div>
                </div>
              )}
            </>
          )}
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target={`blank${conversation.messageid}`} href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {conversation.message}
          </Linkify>
        </div>
      ) : (
        <div>{conversation.message}</div>
      )}
    </>
  );
}

export default TextMessage;
