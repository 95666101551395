import React from "react";
import "./EmptyChat.css";
//import Lottie from "react-lottie";
import Lottie from "lottie-react";
import animationData from "./34611-chat-bubbles.json";
// import animationData from "./smallevent.json";
const style = {
  height: 180,
  width: 220,
};

const EmptyChat = () => (
  <div className="empty-chat">
    <Lottie
      style={style}
      animationData={animationData}
      loop={true}
      // width={200}
      // height={200}
      // width={250}
      // height={200}
      // options={{
      //   loop: true,
      //   autoplay: true,
      //   animationData,
      //   rendererSettings: {
      //     preserveAspectRatio: "xMidYMid slice",
      //   },
      // }}
    />
    <div>Chatisへようこそ！</div>
  </div>
);

export default EmptyChat;
