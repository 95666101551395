/* eslint-disable react/prop-types */

import React, { useEffect, useState, useCallback } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { API_URL, custom_axios_post } from "../Common/Common";
//import { Store } from "../Store/store";
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledButton = styled(Button)`
  color: grey;
`;

function ShowRoomAccountPhotoAdmin({ open, id, handleClose, callback }) {
  //const { dispatch } = useContext(Store);
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const src = URL.createObjectURL(acceptedFiles[0]);
      setFileUrl(src);
      setSendfiles(acceptedFiles[0]);
    }
  }, []);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: onDrop,
    accept: "image/*",
  });

  const [fileUrl, setFileUrl] = useState(null);
  const [sendfiles, setSendfiles] = useState(null);
  useEffect(() => {
    //setSendfiles(acceptedFiles);
    if (acceptedFiles.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [acceptedFiles]);

  const [isVisible, setIsVisible] = useState(false);

  const handleClear = async () => {
    setSendfiles(null);
    setFileUrl(null);
    setIsVisible(false);
  };
  const handleSave = async () => {
    if (sendfiles) {
      sendImage();
    }
    handleClose();
    ////console.log("GetUser", result);
    //handleClose();
    //if (result.status === 200) {
    //  dispatch({ type: "CHANGE_ACCOUNT", payload: { name: name, subname: subname, phone: phone, color: color } });
    //}
  };
  const sendImage = async () => {
    const params = new FormData();
    params.append("roomid", id);
    params.append("file", sendfiles);
    custom_axios_post(API_URL + "/uploadRoomAvatar", params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((result) => {
        if (result.status === 200) {
          callback();
          //const retFileno = result.data.fileno;
          //dispatch({ type: "CHANGE_PHOTO", payload: retFileno });
        }
        //console.log("upload ok...");
      })
      .catch(() => {
        //console.log("upload failed...");
      });
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">写真選択</StyledDialogTitle>

      <DialogContent>
        <div className="logoff-wrap">
          {fileUrl ? (
            <div className="drop-zone-image-div-wraper">
              <img src={fileUrl} style={{ objectFit: "contain", width: "300px", height: "200px" }} />
            </div>
          ) : (
            <div {...getRootProps({ className: "drop-zone-div-wraper" })}>
              <input {...getInputProps()} />
              <CloudUploadIcon></CloudUploadIcon>
              <p>写真をドラッグアンドドロップ</p>
              <p>または、選択してください</p>
            </div>
          )}

          <div className="read-member-content" style={{ marginTop: "10px" }}>
            <Button
              disabled={isVisible ? false : true}
              style={{ margin: "0 auto" }}
              variant="contained"
              color="primary"
              onClick={handleSave}
              startIcon={<SaveOutlinedIcon />}
            >
              変更
            </Button>
            <StyledButton
              disabled={isVisible ? false : true}
              style={{ margin: "0 auto" }}
              variant="contained"
              //color="primary"
              //className={classes.clearButton}
              onClick={handleClear}
            >
              クリア
            </StyledButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ShowRoomAccountPhotoAdmin;
