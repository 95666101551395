import React, { useContext, useState, useEffect, useMemo } from "react";
import "./ChatRooms.css";
import Chip from "@mui/material/Chip";
import { Store } from "../Store/store";
import { STATIC_URL } from "../Common/Common";
import ShowCallMini from "../Common/ShowCallMini";
// import ShowCall from "../Common/ShowCall";
import Search from "@mui/icons-material/Search";
import { Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import { RiPushpin2Line } from "react-icons/ri";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import styled from "@emotion/styled";
import IconButton from "@mui/material/IconButton";

const StyledDivAccordion = styled("div")`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  height: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledIconButton = styled(IconButton)`
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;
const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledFlex = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: start;
`;
const StyledOptionFlex = styled("div")`
  display: flex;
  //flex-direction: column;
  align-items: start;
`;
const StyledWrapper = styled("div")`
  display: flex;
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary.light : "#f7f8fc")};
  cursor: pointer;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  width: 250px;
  color: #9d9d9d;
  position: relative;
  z-index: 0;
  & .chat-name {
    font-weight: ${(props) => (props.bigfont ? 700 : "normal")};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};

    & .chat-name {
      font-weight: 700;
    }
    & .chat-image {
      border: 1px solid white;
    }
  }
`;

const StyledFlexibleDiv = styled("div")`
  display: flex;
`;

const StyledSpan = styled("span")`
  background: ${(props) => props.theme.palette.primary.main};
`;

//import Icon from "@mui/material";
function ChatRooms() {
  const { state, dispatch } = useContext(Store);
  const [username, setUsername] = useState("");
  // 人名検索
  const [chatsFilter, setChatsFilter] = useState("");
  const [localChatrooms, setLocalChatrooms] = useState([]);
  const handleChange = (e) => {
    setChatsFilter(e.target.value);
  };
  // const [openFa, setOpenFa] = useState(false);

  // const handleFaClose = () => {
  //   setOpenFa(false);
  // };

  const [openCallMini, setOpenCallMini] = useState(false);
  const handleCallMiniBack = () => {
    setOpenCallMini(false);
  };

  const handleCallMiniClose = () => {
    setOpenCallMini(false);
  };

  // useEffect(() => {
  //   console.log("showcall", state, state.callroomid, state.callname);
  //   if (state.callroomid != undefined && state.callroomid !== "" && state.callhonbuid != state.honbuid) {
  //     setOpenCallMini(true);
  //   }
  // }, [state.callroomid, state.callname, state.callhonbuid]);
  useEffect(() => {
    // dispatch({
    //   type: "UPDATE_ISAROUNDBUTTOMSCROLL",
    //   payload: { changeVal: state.isAroundButtomScroll+1 },
    // });
    // console.log("chatsFilterの値", chatsFilter);
    // 人名検索ボックス入力有無
    if (chatsFilter !== "") {
      // 人名検索
      setUsername(state.username);
      const updateList = state.chatrooms.filter((item) => {
        if (item.ishide == "1") {
          return false;
        }
        if (chatsFilter && String(item.roomname).toLowerCase().indexOf(chatsFilter) !== -1) {
          return true;
        }
        if (chatsFilter) {
          const updateListdetail = item.memberlist.filter((item) => {
            if (String(item.name).toLowerCase().indexOf(chatsFilter) !== -1) {
              return true;
            }
            if (String(item.subname).toLowerCase().indexOf(chatsFilter) !== -1) {
              return true;
            }
            return false;
          });
          if (updateListdetail.length > 0) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      });
      setLocalChatrooms(updateList);
    } else {
      // スター（ピン）、最終送信時間
      // console.log("sortした");
      const updateList = state.chatrooms.filter((item) => {
        if (item.ishide == "1") {
          return false;
        }
        return true;
      });
      setLocalChatrooms(
        updateList.sort(function (a, b) {
          if (a.isstar < b.isstar) {
            return 1;
          }
          if (a.isstar > b.isstar) {
            return -1;
          }
          if (a.latestsending < b.latestsending) {
            return 1;
          }
          if (a.latestsending > b.latestsending) {
            return -1;
          }
          return 0;
        })
      );
    }
    let sum = 0;
    state.chatrooms.forEach((e) => {
      if (e.ishide == "0" && e.parentroomid == e.roomid) {
        sum += Number(e.unreadcnt);
      }
    });
    if (sum > 0) {
      document.title = "Chatis ＊";
    } else {
      if (state.isActive) {
        document.title = "Chatis";
      }
      /* if (typeof document.hidden !== "undefined") {
        // Opera 12.10 や Firefox 18 以降でサポート
        console.log("document.hidden", document.hidden);
        if (!document.hidden) document.title = "Chatis";
      } else if (typeof document.msHidden !== "undefined") {
        if (!document.msHidden) document.title = "Chatis";
        console.log("document.msHidden", document.msHidden);
      } else if (typeof document.webkitHidden !== "undefined") {
        if (!document.webkitHidden) document.title = "Chatis";
        console.log("document.webkitHidden", document.webkitHidden);
      } */
    }
  }, [chatsFilter, state]);

  const getRoomName = (chatroom) => {
    //console.log("chatroomの名前", chatroom.roomname);
    if (chatroom.roomname !== "") {
      const solonum = chatroom.memberlist.length + 1;
      return chatroom.roomname + (solonum <= 2 ? "" : "(" + String(solonum) + ")");
    } else {
      //console.log("空っぽの場合", chatroom.memberlist);
      let roomname = chatroom.memberlist.length > 2 ? username : "";
      for (let i = 0; i < chatroom.memberlist.length; i++) {
        roomname = roomname + chatroom.memberlist[i].name + ",";
        //console.log("chatroom.memberlist[i].name", chatroom.memberlist[i].name);
      }
      roomname = roomname.slice(0, -1);
      const groupnum = chatroom.memberlist.length + 1;
      return roomname + (groupnum === 2 ? "" : "(" + String(groupnum) + ")");
    }
  };

  const chatMemberToolTipText = (chatroom, isOneOnOne) => {
    if (isOneOnOne) {
      return (
        <div style={{ whiteSpace: "pre-line" }}>
          {`${getRoomName(chatroom)}(${chatroom.memberlist.length > 0 ? chatroom.memberlist[0].subname : ""})\n${
            chatroom.latestmessage
          }`}
        </div>
      );
    } else {
      return <div style={{ whiteSpace: "pre-line" }}>{`${getRoomName(chatroom)}\n${chatroom.latestmessage}`}</div>;
    }
  };

  // roomidを元に、小部屋の未読があればtrueを返す
  const isUnreadMiniRoom = (argroomid) => {
    const myUnreadMiniRoom = state.chatrooms.filter(
      (f) =>
        f.parentroomid === argroomid &&
        f.parentroomid !== f.roomid &&
        f.ishide === "0" &&
        (parseInt(f.unreadcnt) !== 0 || parseInt(f.unvotecnt) !== 0)
    );

    const ret = myUnreadMiniRoom.length > 0 ? true : false;
    return ret;
  };

  const [groupexpanded, setGroupExpanded] = React.useState(true);
  const [expanded, setExpanded] = React.useState(true);

  const MemoCallMini = useMemo(() => {
    return (
      <ShowCallMini
        open={openCallMini}
        name={state.callname}
        handleClose={handleCallMiniClose}
        handleCallBack={handleCallMiniBack}
      />
    );
  }, [openCallMini, state.callname]);
  return (
    <div>
      <div className="chat-search">
        <div className="chat-search-icon">
          <StyledSearch />
        </div>
        <input
          placeholder="検索"
          value={chatsFilter}
          name="chatsFilter"
          onChange={handleChange}
          className="chat-search-field"
        />
      </div>

      <div className="chats-list">
        <div>
          <StyledDivAccordion onClick={() => setGroupExpanded(!groupexpanded)}>
            {/* <div className="ac-group-between"> */}
            <div className="ac-group" style={{ marginLeft: "6px" }}>
              <GroupIcon></GroupIcon>
              <div>グループ</div>
            </div>
            <StyledIconButton size={"small"}>{groupexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
            {/* </div> */}
          </StyledDivAccordion>
          <div style={{ display: groupexpanded ? "" : "none" }}>
            {localChatrooms &&
              localChatrooms.length > 0 &&
              localChatrooms
                .filter((f) => f.isgroup == 1 && f.isbotroom == 0 && f.ismyroom == 0 && f.parentroomid === f.roomid)
                .map((e) => {
                  return (
                    <div key={e.roomid}>
                      <Tooltip
                        title={chatMemberToolTipText(e, false)}
                        enterDelay={3000}
                        placement="right"
                        arrow
                        interactive
                      >
                        <StyledWrapper
                          key={e.roomid}
                          // bigfontがtrueの時、太字＋ハイライト
                          bigfont={
                            !state.isMiniRoom ? state.currentRoomid === e.roomid : state.parentroomid === e.roomid
                          }
                          // className={`chat-wrapper${state.currentRoomid === e.roomid ? "-user" : ""}`}
                          //className={state.currentRoomid === e.roomid ? classes.chatWrapperUser : classes.chatWrapper}
                          onClick={() => {
                            //let currentroomid = localStorage.getItem("currentRoomid");
                            if (e.roomid == state.currentRoomid) {
                              dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
                            } else {
                              dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                              localStorage.setItem("currentRoomid", e.roomid);
                              localStorage.setItem("parentRoomid", e.roomid);
                              localStorage.setItem("isbot", e.isbot);
                            }
                            // dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                          }}
                        >
                          <div className="avatar-item">
                            <a>
                              {isUnreadMiniRoom(e.roomid) && (
                                <StyledSpan className={"miniroom-unread-badge"}></StyledSpan>
                              )}
                              <img
                                src={
                                  STATIC_URL + `/downloadRoomAvatar?roomid=${e.roomid}&fileno=${e.fileno}`
                                  // (e.memberlist.length > 1 || e.isofficial == 1
                                  //   ? `/downloadRoomAvatar?roomid=${e.roomid}&fileno=${e.fileno}`
                                  //   : `/downloadAvatar?honbuid=${e.memberlist[0].honbuid}&size=small`)
                                }
                                width="32"
                                height="32"
                                alt=""
                                className="chat-image"
                                //style={isCurrent ? { border: "1px solid white" } : null}
                              />
                            </a>
                          </div>

                          <div className="chat-name-wrapper">
                            <div className="chat-name">
                              <StyledFlex>
                                <StyledOptionFlex>
                                  {e.isofficial == "1" && (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="人事連携"
                                    ></StyledChip>
                                  )}
                                  {e.isbukai == "1" && (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="部会"
                                    ></StyledChip>
                                  )}
                                  {e.isdirection == "1" && (
                                    <StyledChip variant="outlined" size="small" label="一方向"></StyledChip>
                                  )}
                                </StyledOptionFlex>
                                {getRoomName(e)}
                              </StyledFlex>
                            </div>

                            <Badge
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              overlap="rectangular"
                              color="primary"
                              badgeContent={Number(e.unreadcnt) + Number(e.unvotecnt)}
                              invisible={Number(e.unreadcnt) + Number(e.unvotecnt) === 0}
                            ></Badge>

                            {/* <div className="chat-last-message">{e.latestmessage}</div> */}
                            {e.ispush == "0" && (
                              <div className="pinicon-wrapper">
                                <NotificationsOffIcon></NotificationsOffIcon>
                              </div>
                            )}

                            {e.isstar == "1" && (
                              <div className="pinicon-wrapper">
                                <RiPushpin2Line></RiPushpin2Line>
                              </div>
                            )}
                          </div>
                        </StyledWrapper>
                      </Tooltip>
                    </div>
                  );
                })}
          </div>
        </div>

        <div>
          <StyledDivAccordion onClick={() => setExpanded(!expanded)}>
            <div className="ac-group" style={{ marginLeft: "6px" }}>
              <PersonIcon></PersonIcon>
              <div>コンタクト</div>
            </div>
            <StyledIconButton size={"small"}>{expanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
          </StyledDivAccordion>
          <div style={{ display: expanded ? "" : "none" }}>
            {localChatrooms &&
              localChatrooms.length > 0 &&
              localChatrooms
                .filter((f) => f.isgroup == false && f.ismyroom == 0 && f.parentroomid === f.roomid)
                .map((e) => {
                  return (
                    <div key={e.roomid}>
                      <Tooltip
                        title={chatMemberToolTipText(e, true)}
                        enterDelay={3000}
                        placement="right"
                        arrow
                        interactive
                      >
                        <StyledWrapper
                          key={e.roomid}
                          bigfont={state.currentRoomid === e.roomid}
                          // className={`chat-wrapper${state.currentRoomid === e.roomid ? "-user" : ""}`}
                          //className={state.currentRoomid === e.roomid ? classes.chatWrapperUser : classes.chatWrapper}
                          onClick={() => {
                            //let currentroomid = localStorage.getItem("currentRoomid");
                            if (e.roomid == state.currentRoomid) {
                              dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
                            } else {
                              dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                              localStorage.setItem("currentRoomid", e.roomid);
                              localStorage.setItem("parentRoomid", e.roomid);
                              localStorage.setItem("isbot", e.isbot);
                            }
                            // dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                          }}
                        >
                          <div className="avatar-item">
                            <a>
                              {isUnreadMiniRoom(e.roomid) && (
                                <StyledSpan className={"miniroom-unread-badge"}></StyledSpan>
                              )}
                              <img
                                src={
                                  STATIC_URL +
                                  (e.memberlist.length > 1 || e.isofficial == 1
                                    ? `/downloadRoomAvatar?roomid=${e.roomid}&fileno=${e.fileno}`
                                    : e.memberlist.length == 0
                                      ? `/downloadRoomAvatar?roomid=${e.roomid}&fileno=${e.fileno}`
                                      : `/downloadAvatar?honbuid=${e.memberlist[0].honbuid}&size=small`)
                                }
                                width="32"
                                height="32"
                                alt=""
                                className="chat-image"
                                //style={isCurrent ? { border: "1px solid white" } : null}
                              />
                            </a>
                          </div>

                          <div className="chat-name-wrapper">
                            {e.memberlist.length == 0 ? (
                              <div className="chat-name">空部屋</div>
                            ) : (
                              <div className="chat-name">
                                <StyledFlexibleDiv>
                                  {e.memberlist[0].isvip == "1" && (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="VIP"
                                    ></StyledChip>
                                  )}
                                  {e.memberlist[0].isbukaiadmin == "1" ? (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="部会管理"
                                    ></StyledChip>
                                  ) : (
                                    <div></div>
                                  )}
                                  {e.memberlist[0].isadmin == "1" ? (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="システム管理"
                                    ></StyledChip>
                                  ) : e.memberlist[0].isadmin == "2" ? (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="施設管理"
                                    ></StyledChip>
                                  ) : e.memberlist[0].isadmin == "3" ? (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="部屋管理"
                                    ></StyledChip>
                                  ) : e.memberlist[0].isadmin == "6" ? (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="施設横断部屋"
                                    ></StyledChip>
                                  ) : e.memberlist[0].isadmin == "4" ? (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="ユーザー管理"
                                    ></StyledChip>
                                  ) : e.memberlist[0].isadmin == "5" ? (
                                    <StyledChip
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      label="部屋・ユーザー管理"
                                    ></StyledChip>
                                  ) : (
                                    <div></div>
                                  )}
                                </StyledFlexibleDiv>
                                {getRoomName(e)}
                                <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.memberlist[0].subname})</span>
                              </div>
                            )}

                            <Badge
                              overlap="rectangular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              color="primary"
                              badgeContent={Number(e.unreadcnt) + Number(e.unvotecnt)}
                              invisible={Number(e.unreadcnt) + Number(e.unvotecnt) === 0}
                            ></Badge>
                            {e.ispush == "0" && (
                              <div className="pinicon-wrapper">
                                <NotificationsOffIcon></NotificationsOffIcon>
                              </div>
                            )}

                            {e.isstar == "1" && (
                              <div className="pinicon-wrapper">
                                <RiPushpin2Line></RiPushpin2Line>
                              </div>
                            )}
                          </div>
                          {/* <div className="chat-last-message">{e.latestmessage}</div> */}
                        </StyledWrapper>
                      </Tooltip>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
      {/* {MemoMember} */}
      {MemoCallMini}
      {/* <ShowCall
        open={openCall}
        handleClose={handleCallClose}
        channel={state.callroomid}
        uid={localStorage.getItem("honbuid")}
        name={state.user_name}
        flag="join"
      ></ShowCall> */}
    </div>
  );
}

export default ChatRooms;
