/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowHonbuidInput({ open, handleClose, callBackProc, afterSaveProc, honbuids, setHonbuids, isAlumni = "0" }) {
  const handleCancel = async () => {
    setHonbuids("");
    setTextMessage("");
    handleClose();
    afterSaveProc();
  };

  const [textmessage, setTextMessage] = useState("");
  const mainproc = async () => {
    if (open) {
      setTextMessage(honbuids);
    }
  };
  useEffect(() => {
    if (open) {
      mainproc();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">
            {isAlumni == "0" ? "本部ID・名前で検索" : "本部ID・名前・生年月日(yyyyMMdd)で検索"}
          </div>
          <div className="question-title-actions">
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="question-create-wrap">
          <TextField
            // inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            placeholder="複数ID・名前の場合は、改行して入力してください"
            label={isAlumni == "0" ? "本部ID・名前" : "本部ID・名前・生年月日(yyyyMMdd)"}
            variant="outlined"
            value={textmessage}
            multiline
            rows={4}
            onChange={(e) => {
              setTextMessage(e.target.value);
            }}
            style={{ margin: "8px", width: "100%" }}
          />
          {/* <div className="sending-area-buttons">(文字:{honbuids.length} / 400)</div> */}
        </div>
      </DialogContent>
      <StyledDialogActions>
        <Button
          //   style={{ margin: "0 auto" }}
          variant="contained"
          color="primary"
          onClick={() => {
            if (textmessage == "") {
              afterSaveProc();
            } else {
              callBackProc(textmessage);
            }
          }}
          startIcon={<SearchIcon />}
        >
          検索
        </Button>
        <Button onClick={handleCancel} variant="outlined" color="primary">
          検索条件解除
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default ShowHonbuidInput;
