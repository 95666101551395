/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import Paper from "@mui/material/Paper";
import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const StyledFlexibleDiv = styled("div")`
  display: flex;
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
function ShowRoomMember({
  open,
  handleClose,
  roomid,
  setOpenModal,
  setLeaveId,
  setLeaveName,
  leavedId,
  setOpenFaAdd,
  addmember,
}) {
  const history = useHistory();

  const [localList, setLocalList] = useState([]);
  useEffect(() => {
    setLocalList([]);
  }, []);
  useEffect(() => {
    const proc = async () => {
      if (open) {
        const result = await custom_axios(API_URL + `/GetRoomMember?roomid=${roomid}`);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        }
        if (status === 200) {
          setLocalList(result.data.members);
        }
      }
    };
    proc();
  }, [open, roomid, addmember]);

  useEffect(() => {
    // console.log("leavedId", leavedId);
    var copylocalList = localList.filter((item) => item.honbuid != leavedId);
    setLocalList(copylocalList);
    // console.log("copylocalList", copylocalList);
  }, [leavedId]);

  const changeMark = async (honbuid) => {
    var copylocalList = localList.map((item) => {
      if (item.honbuid === honbuid) {
        if (item.ismark === "1") {
          return { ...item, ismark: "0" };
        } else {
          return { ...item, ismark: "1" };
        }
      }
      return item;
    });
    setLocalList(copylocalList);
    await custom_axios(API_URL + `/changeMark?roomid=${roomid}&honbuid=${honbuid}`);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div>メンバー</div>
          <StyledButton
            onClick={() => {
              setOpenFaAdd(true);
            }}
            //className={classes.textcolor}
            variant="outlined"
          >
            ユーザー追加
          </StyledButton>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <Paper square>
          <div className="read-member-list">
            {localList &&
              localList.map((e) => {
                return (
                  <div key={e.honbuid} className="read-member-wrapper">
                    <div className="read-member-content">
                      <img
                        src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                        width="30"
                        height="30"
                        alt=""
                        className="read-member-image"
                      />
                      <div className="read-member-text-wrapper">
                        <StyledFlexibleDiv>
                          {e.isvip == "1" && (
                            <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                          )}
                          {e.isbukaiadmin == "1" ? (
                            <StyledChip variant="outlined" color="primary" size="small" label="部会管理"></StyledChip>
                          ) : e.isbukaiadmin == "2" ? (
                            <StyledChip variant="outlined" color="primary" size="small" label="部会部屋"></StyledChip>
                          ) : (
                            <div></div>
                          )}
                          {e.isadmin == "1" ? (
                            <StyledChip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label="システム管理"
                            ></StyledChip>
                          ) : e.isadmin == "2" ? (
                            <StyledChip variant="outlined" color="primary" size="small" label="施設管理"></StyledChip>
                          ) : e.isadmin == "3" ? (
                            <StyledChip variant="outlined" color="primary" size="small" label="部屋管理"></StyledChip>
                          ) : e.isadmin == "6" ? (
                            <StyledChip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label="施設横断部屋"
                            ></StyledChip>
                          ) : e.isadmin == "4" ? (
                            <StyledChip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label="ユーザー管理"
                            ></StyledChip>
                          ) : e.isadmin == "5" ? (
                            <StyledChip
                              variant="outlined"
                              color="primary"
                              size="small"
                              label="部屋・ユーザー管理"
                            ></StyledChip>
                          ) : (
                            <div></div>
                          )}
                        </StyledFlexibleDiv>
                        <div className="read-member-name-wrapper">
                          <div className="read-member-name">{e.name}</div>
                        </div>
                        {/* <div className="read-member-name">{e.hpname}</div> */}
                      </div>
                      {e.isdirection == "1" && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={e.ismark === "1"}
                              onChange={() => {
                                changeMark(e.honbuid);
                              }}
                              name="checkedA"
                            />
                          }
                          label="発言"
                        />
                      )}
                      <Button
                        onClick={() => {
                          setLeaveId(e.honbuid);
                          setLeaveName(e.name);
                          setOpenModal(true);
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        退室
                      </Button>
                    </div>
                  </div>
                );
              })}
          </div>
        </Paper>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowRoomMember;
