/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useReactToPrint } from "react-to-print";
import {ThreeDots as Loader} from "react-loader-spinner";
import topimage from "../image/main.png";
import { API_URL, custom_axios_post } from "../Common/Common";
import { useHistory } from "react-router-dom";
import { MAIN_URL } from "../Common/Common";
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialog = styled(Dialog)`
  align-items: baseline;
`;
const StyledMainDiv = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledMainQRDiv = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
`;
const StyledLeftDiv = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 540px;
`;
const StyledTitleDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledMarginDiv = styled("div")`
  margin-right: 5px;
  font-size: large;
  font-weight: bold;
`;
const StyledFlexDiv = styled("div")`
  display: flex;
`;

const PageBreakWrapper = styled("div")`
  && {
    page-break-after: always;
  }
`;
const StyledTopTitleDiv = styled("div")`
  font-size: large;
`;
const StyledFontDiv = styled("div")`
  font-weight: bold;
`;

function ShowPrint({ open, handleClose, honbuids }) {
  //const { state } = useContext(Store);
  const history = useHistory();
  const componentRef = React.useRef(null);
  useEffect(() => {}, []);
  //   const today = new Date();
  const [isCompleted, setIsCompleted] = React.useState(false);
  const [localhonbuids, setLocalHonbuids] = React.useState([]);
  const handleAfterPrint = React.useCallback(() => {
    console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);
  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console
  }, []);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle:
      localhonbuids.length == 1
        ? localhonbuids[0].honbuid + " " + localhonbuids[0].hpname + " " + localhonbuids[0].name
        : "インストールQR用紙一覧",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const proc = async () => {
    let strhonbuids = honbuids
      .map((e) => {
        return e;
      })
      .join("_");

    const body = {
      honbuids: strhonbuids,
    };
    const result = await custom_axios_post(API_URL + "/getQrStrings", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      var resultlist = [];
      for (let i = 0; i < honbuids.length; i++) {
        for (let j = 0; j < result.data.qrlist.length; j++) {
          if (honbuids[i] == result.data.qrlist[j].honbuid) {
            resultlist.push({
              honbuid: honbuids[i],
              hpcode: result.data.qrlist[j].hpcode,
              hpname: result.data.qrlist[j].hpname,
              unitname: result.data.qrlist[j].unitname,
              name: result.data.qrlist[j].name,
              code: result.data.qrlist[j].code,
              initpass: result.data.qrlist[j].initpass,
            });
          }
        }
      }

      setLocalHonbuids(resultlist);
      setIsCompleted(true);
    }
  };

  useEffect(() => {
    if (open) {
      setIsCompleted(false);
      proc();
    }
  }, [open]);

  return (
    <StyledDialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">QRコード印刷プレビュー</div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        {isCompleted == false ? (
          <StyledTitleDiv>
            <Loader type="ThreeDots" height="100" width="100" />
          </StyledTitleDiv>
        ) : (
          <div ref={componentRef}>
            {localhonbuids.map((e) => {
              return (
                <div key={e.honbuid}>
                  <StyledMainDiv>
                    <StyledTitleDiv>
                      <img src={topimage} style={{ display: "block", margin: "10px" }} height="50" alt="アイコン" />
                      <StyledTopTitleDiv>Chatisインストール用QRコード</StyledTopTitleDiv>
                    </StyledTitleDiv>
                    <br />
                    <StyledLeftDiv>
                      <StyledFlexDiv>
                        <StyledMarginDiv>ID</StyledMarginDiv>
                        <StyledMarginDiv>{e.honbuid}</StyledMarginDiv>
                      </StyledFlexDiv>

                      <StyledFlexDiv>
                        <StyledMarginDiv>{e.unitname}</StyledMarginDiv>

                        <StyledMarginDiv>{e.name}</StyledMarginDiv>
                        <StyledMarginDiv>様</StyledMarginDiv>
                      </StyledFlexDiv>
                    </StyledLeftDiv>
                    <br />
                    <StyledLeftDiv>
                      {/* <div>Chatisを利用頂き有り難う御座います。</div> */}
                      <div>ご自身のスマートフォンのOSに合ったQRコードからインストールをお願いします。</div>
                      {/* <div>※iOSのQRコードはユーザー毎に異なります。他人に渡さないで下さい。</div> */}
                      {/* <div>※AndroidのQRコードはユーザー全て同じです。</div> */}
                    </StyledLeftDiv>

                    <StyledTitleDiv>
                      <StyledMainQRDiv>
                        <div>iOS用</div>
                        <img
                          width="120"
                          height="120"
                          style={{ display: "block", margin: "10px" }}
                          // src={`https://image-charts.com/chart?chs=150x150&cht=qr&chl=https://apps.apple.com/redeem?code=${e.code}&ctx=apps`}
                          src={`${MAIN_URL}/home/downloadiOsQRcode`}
                          alt=""
                          className="show-qrcode-print-image"
                          //onLoad={() => setIsCompleted(true)}
                          //style={isCurrent ? { border: "1px solid white" } : null}
                        />
                      </StyledMainQRDiv>
                      <StyledMainQRDiv>
                        <div>Android用</div>
                        <img
                          width="120"
                          height="120"
                          style={{ display: "block", margin: "10px" }}
                          src={`${MAIN_URL}/home/downloadAndroidQRcode`}
                          alt=""
                          className="show-qrcode-print-image"
                          //onLoad={() => setIsCompleted(true)}
                          //style={isCurrent ? { border: "1px solid white" } : null}
                        />
                      </StyledMainQRDiv>
                    </StyledTitleDiv>
                    <StyledMainDiv>
                      <StyledLeftDiv>
                        <div>インストール後の、初回ログイン情報は以下となります。</div>
                        <StyledFontDiv>URL：{MAIN_URL}</StyledFontDiv>
                        <StyledFontDiv>ログインID：{e.honbuid}</StyledFontDiv>
                        <StyledFontDiv>パスワード：{e.initpass}</StyledFontDiv>
                        {/* <StyledFontDiv>パスワード：a93ldi3ksl123</StyledFontDiv> */}

                        {/* <div>※人事システムに登録されていない方は、パスワードはIDとなります。</div> */}
                        <br />
                        {/* <div>不明な箇所が御座いましたら、以下マニュアルをご参照下さい。</div> */}
                        <div>以下のQRコードをログイン画面で読み取ってもログインが出来ます。</div>
                      </StyledLeftDiv>
                      <StyledMainQRDiv>
                        {/* <div>マニュアル</div> */}
                        <div>ログイン用QRコード</div>
                        <img
                          width="150"
                          height="150"
                          style={{ display: "block", margin: "10px" }}
                          //src={`https://image-charts.com/chart?chs=150x150&cht=qr&chl=https://chat-tis.github.io/mkdocs/`}
                          src={`https://image-charts.com/chart?chs=150x150&cht=qr&chl=${MAIN_URL}@${e.honbuid}@${e.initpass}`}
                          alt=""
                          className="show-qrcode-print-image"
                          //onLoad={() => setIsCompleted(true)}
                          //style={isCurrent ? { border: "1px solid white" } : null}
                        />
                      </StyledMainQRDiv>
                    </StyledMainDiv>
                  </StyledMainDiv>
                  <PageBreakWrapper>&nbsp;</PageBreakWrapper>
                </div>
              );
            })}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePrint} variant="outlined" color="primary">
          印刷
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default ShowPrint;
