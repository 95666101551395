/* eslint-disable react/prop-types */
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { USER_BELL_BUTTON_OPTION } from "../Common/Common";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";

const StyledButton = styled(Button)`
  width: 100%;
`;

function ShowBellButtonSelect({ handleRun, handleDell, bellid }) {
  const [stampAnchorEl, setStampAnchorEl] = useState(null);
  const handleStampClick = (event) => {
    setStampAnchorEl(event.currentTarget);
  };
  const handleStampClose = () => {
    setStampAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="delete" onClick={handleStampClick}>
        <SettingsIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={stampAnchorEl}
        keepMounted
        open={Boolean(stampAnchorEl)}
        onClose={handleStampClose}
      >
        {USER_BELL_BUTTON_OPTION.map((name) => (
          <MenuItem key={name} value={name}>
            <StyledButton
              variant="contained"
              key={name}
              onClick={() => {
                if (name == "削除") {
                  handleDell(bellid);
                  setStampAnchorEl(null);
                }
                if (name == "参照") {
                  handleRun(bellid);
                  setStampAnchorEl(null);
                }
              }}
            >
              {name}
            </StyledButton>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default ShowBellButtonSelect;
