/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React from "react";
import { STATIC_URL } from "../Common/Common";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
function ShowImageInfo({ open, messageid, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"} scroll={"paper"}>
      <DialogContent sx={{ padding: "0px", textAlign: "center" }}>
        <img
          src={STATIC_URL + `/downloadImage?messageID=${messageid}&size=big`}
          // width="200"
          // height="200"
          alt=""
          className="show-image"
        />
      </DialogContent>
    </Dialog>
  );
}
export default ShowImageInfo;
