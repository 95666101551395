import { useContext, useState, useEffect } from "react";
import { Store } from "../Store/store";

function useRoomName() {
  const { state } = useContext(Store);
  const [username] = useState(state.username);
  const [roomnam, setRoomname] = useState("");
  const [parentroomname, setParentroomname] = useState("");
  const [ispush, setIspush] = useState(false);

  useEffect(() => {
    if (state.chatrooms && state.chatrooms.filter((f) => f.roomid === state.currentRoomid).length > 0) {
      let chatroom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0];
      if (chatroom.ispush == "1") {
        setIspush(true);
      } else {
        setIspush(false);
      }
      if (!state.isMiniRoom) {
        // 普通の部屋のとき
        if (chatroom.roomname !== "") {
          const solonum = chatroom.memberlist.length + 1;
          setRoomname(chatroom.roomname + (solonum === 1 ? "" : "(" + String(solonum) + ")"));
        } else {
          //console.log("空っぽの場合２", chatroom.memberlist);
          let roomname = chatroom.memberlist.length > 2 ? username : "";
          for (let i = 0; i < chatroom.memberlist.length; i++) {
            roomname = roomname + chatroom.memberlist[i].name + ",";
            //console.log("chatroom.memberlist[i].name", chatroom.memberlist[i].name);
          }
          roomname = roomname.slice(0, -1);
          const groupnum = chatroom.memberlist.length + 1;
          setRoomname(roomname + (groupnum === 2 ? "" : "(" + String(groupnum) + ")"));
        }
      } else {
        // 小部屋の時

        // 親部屋名を↑と同様のフォーマットで作成
        let parentChatroom = state.chatrooms.filter((f) => f.roomid === state.parentroomid)[0];
        if (parentChatroom.roomname !== "") {
          const solonum = parentChatroom.memberlist.length + 1;
          setParentroomname("@" + parentChatroom.roomname + (solonum === 1 ? "" : "(" + String(solonum) + ")"));
        } else {
          let roomname = parentChatroom.memberlist.length > 2 ? username : "";
          for (let i = 0; i < parentChatroom.memberlist.length; i++) {
            roomname = roomname + parentChatroom.memberlist[i].name + ",";
          }
          roomname = roomname.slice(0, -1);
          const groupnum = parentChatroom.memberlist.length + 1;
          setParentroomname("@" + roomname + (groupnum === 2 ? "" : "(" + String(groupnum) + ")"));
        }

        // 小部屋名も渡す
        if (chatroom.roomname !== "") {
          setRoomname(chatroom.roomname);
        } else {
          // 小部屋は部屋名有のはずだが、一応やっとく
          setRoomname("名称未設定の小部屋");
        }
      }
    }
  }, [state]);

  return [roomnam, ispush, parentroomname];
}

export default useRoomName;
