/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import { useHistory } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Store } from "../Store/store";
import ExcelJS from "exceljs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import encoding from "encoding-japanese";
// import Select from "@mui/material/Select";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ShowNewQueCreate from "../Common/ShowNewQueCreate";
// import MaterialTable from "material-table";
import ShowCommon from "../Common/ShowCommon";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Badge from "@mui/material/Badge";
import Slide from "@mui/material/Slide";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
import styled from "@emotion/styled";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import ShowNewQueVote from "./ShowNewQueVote";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import ShowPlusQuestionUserView from "../Common/ShowPlusQuestionUserView";
import purple from "@mui/material/colors/purple";
import green from "@mui/material/colors/green";
import teal from "@mui/material/colors/teal";
import indigo from "@mui/material/colors/indigo";
import blue from "@mui/material/colors/blue";
import deepPurple from "@mui/material/colors/deepPurple";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";
import deepOrange from "@mui/material/colors/deepOrange";
import orange from "@mui/material/colors/orange";
import TextField from "@mui/material/TextField";
import {
  GridActionsCellItem,
  DataGridPro as DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  // GridToolbarDensitySelector,
  GridToolbarExport,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import MuiPagination from "@mui/material/Pagination";
const StyledDataGrid = styled("div")`
  height: 90%;
  weight: 100%;
`;
const StyledIconButton = styled(IconButton)`
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;
const StyledDivAccordion = styled("div")`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  height: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const StyledChartMain = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledCenter = styled("div")`
  text-align: center;
`;

const StyledJustifyAlignCenter = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  min-width: 300px;
`;

const TopDiv = styled("div")`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledPieCenter = styled("div")`
  width: calc(100vw - 220px);
  height: calc(100vh - 50px);
`;
const StyledChartSub = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledAltButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.primary.contrastText};
`;

const StyledAnpiWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary.light : "#f7f8fc")};
  cursor: pointer;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  /* width: 295px; */
  height: 38px;
  position: relative;
  z-index: 0;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    & .chat-name {
      font-weight: 700;
    }
    & .chat-image {
      border: 1px solid white;
    }
  }
  & .chat-name {
    //color: ${(props) => (props.bigfont ? props.theme.palette.primary.contrastText : "black")};
    font-weight: ${(props) => (props.bigfont ? "700" : "normal")};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;
function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}
function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}
function CustomToolbar() {
  //プロパティから utf8WithBom = Turue を渡してあげる。
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

function ShowPlusQuestionList({ open, handleClose }) {
  const TOPCOLORS = [blue[300], pink[300]];
  const COLORS = [
    indigo[300],
    green[300],
    teal[300],
    blue[300],
    deepPurple[300],
    pink[300],
    red[300],
    purple[300],
    deepOrange[300],
    orange[300],
    // yellow[300],
    // brown[300],
    // lightGreen[300],
    // cyan[300],
    // lightBlue[300],
    // amber[300],
    // lime[300],
    // blueGrey[300],
    // grey[300],
  ];
  const history = useHistory();
  const { state } = useContext(Store);
  const [votesumarry, setVoteSumarry] = useState([]);
  const [textsumarry, setTextSumarry] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [textgroup, setTextGroup] = useState([]);
  const [value, setValue] = React.useState(0);
  const [selectedAnpiid, setSelectedAnpiid] = useState(null);
  const [selectedIsTemp, setSelectedIsTemp] = useState(false);
  const [localList, setLocalList] = useState([]);
  const [currentAdminList, setCurrentAdminList] = useState([]);
  const [currentRoomMemberList, setCurrentRoomMemberList] = useState([]); // メンバーの本部IDと名前だけ持たせる
  const [loading, setLoading] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackpushopen, setSnackPushOpen] = useState(false);
  const [openQuestionVoteObj, setOpenQuestionVoteObj] = React.useState({
    honbuid: 0,
    isopen: false,
    questionid: 0,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleQuestionVoteClose = () => {
    setOpenQuestionVoteObj({ ...openQuestionVoteObj, isopen: false });
  };

  const mainproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      setSelectedAnpiid(null);

      const result = await custom_axios(API_URL + `/GetNewQueList?honbuid=${honbuid}&roomid=${state.currentRoomid}`);
      const status = result.status;

      if (status === 200) {
        setLocalList(result.data.anpilist);
        setCurrentAdminList(result.data.addadminlist);
      } else if (status === 401) {
        history.replace("/loginError");
      } else {
        setLocalList([]);
        setCurrentAdminList([]);
      }
    }
  };
  // メンバーを取得してデータ準備 [{honbuid:111111, name:〇〇太郎},...] の形
  const getCurrentRoomMember = async () => {
    if (open) {
      for (let i = 0; i < state.chatrooms.length; i++) {
        if (state.currentRoomid == state.chatrooms[i].roomid) {
          const currentRoomMemberInfo = state.chatrooms[i].memberlist;
          let retMemberList = [];
          for (let i = 0; i < currentRoomMemberInfo.length; i++) {
            retMemberList = [
              ...retMemberList,
              { honbuid: currentRoomMemberInfo[i].honbuid, name: currentRoomMemberInfo[i].name },
            ];
          }
          setCurrentRoomMemberList([
            { honbuid: localStorage.getItem("honbuid"), name: state.user_name },
            ...retMemberList,
          ]);
          // setCurrentRoomMemberList(retMemberList);
        }
      }
    }
  };

  // 各アンケートに対して、ユーザが管理者であるかを確認する
  const isAdmin = (argsAnpiId) => {
    if (currentAdminList.length > 0) {
      var filted = currentAdminList.filter(
        (ele) => ele.anpiid === argsAnpiId && ele.honbuid === localStorage.getItem("honbuid")
      );
      return filted.length > 0 ? true : false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    mainproc();
    getCurrentRoomMember();
  }, [open]);

  const MemoQuestionVote = useMemo(() => {
    return (
      <ShowNewQueVote
        open={openQuestionVoteObj.isopen}
        honbuid={openQuestionVoteObj.honbuid}
        openAnpiId={openQuestionVoteObj.questionid}
        handleClose={handleQuestionVoteClose}
        afterProc={mainproc}
        messageid={null}
      />
    );
  }, [openQuestionVoteObj.isopen, openQuestionVoteObj.honbuid, openQuestionVoteObj.questionid]);

  /* 左メニューをクリックしたときの動作 */
  const handleClick = async (q, t, isend) => {
    let honbuid = localStorage.getItem("honbuid");
    setSelectedIsTemp(t == "1" ? true : false);
    if (!isAdmin(q)) {
      if (!isend) {
        /* 管理者じゃない && 実施中の場合 */
        setOpenQuestionVoteObj({ honbuid: honbuid, isopen: true, questionid: q });
      }
      // 一般ユーザは終了したアンケートを触っても何も起きない
    } else {
      /* アンケート管理者の場合（一覧みたり、修正できる */
      setSortModel([]);
      setFilterModel({ items: [] });
      setColumnVisibilityModel({});
      setSelectedAnpiid(q);
    }
  };

  const subproc = async () => {
    setLoading(true);
    /* 1安否確認の画面更新 */
    // var apistring = API_URL + `/GetAllQuestionChartMini?anpiid=${selectedAnpiid}`;
    var apistring = API_URL + `/GetAllQuestionChartMiniNewPlus?anpiid=${selectedAnpiid}`;

    const result = await custom_axios(apistring);
    const status = result.status;
    if (status === 200) {
      setVoteSumarry(result.data.votesumarry);
      setTextSumarry(result.data.textsumarry);
      const mstUnit = [];
      const _rows = result.data.rows.map((v) => {
        /* 存在チェックしながら、DDL用のマスタを作成 */
        if (mstUnit.filter((f) => f == v.unitname).length == 0) {
          mstUnit.push(v.unitname);
        }
        return { ...v, fix: "", id: v.honbuid };
      });
      setRows(_rows);
      const cells = [
        {
          field: "fix",
          // headerName: "修正",
          width: 70,
          type: "actions",
          filterable: false,
          getActions: (params) => [
            <GridActionsCellItem
              key={params.row.honbuid}
              icon={<EditIcon />}
              onClick={() =>
                setOpenVoteObj({
                  honbuid: params.row.honbuid,
                  isopen: true,
                  anpiid: selectedAnpiid,
                })
              }
              label="修正"
            />,
          ],
        },
        { field: "honbuid", headerName: "ID" },
        { field: "unitname", headerName: "部署名", type: "singleSelect", valueOptions: mstUnit },
        {
          field: "name",
          headerName: "名前",
          width: 200,
          valueGetter: (value, row) => {
            return row.name;
          },
          renderCell: (params) => {
            return (
              <div className="member-content">
                <img
                  src={
                    STATIC_URL + `/downloadAvatar?honbuid=${params.row.honbuid}&fileno=${params.row.fileno}&size=small`
                  }
                  width="30"
                  height="30"
                  alt=""
                  className="member-image"
                />
                {params.row.name}
              </div>
            );
          },
        },
      ];
      for (let j = 0; j < result.data.columns.length; j++) {
        if (["radio"].includes(result.data.columns[j]["kbn"])) {
          const mstColumn = [];
          const filteredColumnMst = result.data.columnmst.filter((f) => f.seq == result.data.columns[j]["seq"]);
          for (let m = 0; m < filteredColumnMst.length; m++) {
            if (mstColumn.filter((f) => f == filteredColumnMst[m]["text"]).length == 0) {
              mstColumn.push(filteredColumnMst[m]["text"]);
            }
          }
          cells.push({
            field: "val" + (j + 1).toString(),
            headerName: result.data.columns[j]["title"],
            width: 150,
            type: "singleSelect",
            valueOptions: mstColumn,
          });
        } else {
          cells.push({ field: "val" + (j + 1).toString(), headerName: result.data.columns[j]["title"], width: 150 });
        }
      }
      cells.push({ field: "inmmdd", headerName: "入力時刻" });
      cells.push({ field: "upmmdd", headerName: "修正時刻" });
      setColumns(cells);

      var textgroup = result.data.textsumarry.reduce(function (result, current) {
        var element = result.find(function (p) {
          return p.seq === current.seq;
        });
        if (element) {
          element.count++;
        } else {
          result.push({
            seq: current.seq,
            title: current.title,
            count: 1,
            kbn: current.kbn,
          });
        }
        return result;
      }, []);
      setTextGroup(textgroup);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedAnpiid && selectedIsTemp == false) {
      subproc();
    }
  }, [state.addVoteCnt, selectedAnpiid]);
  const [openAnpiCreate, setOpenAnpiCreate] = useState(false);
  const handleAnpiCreateClose = () => {
    setOpenAnpiCreate(false);
  };

  const [openAnpiId, setOpenAnpiId] = useState(null);
  const [iscopy, setIsCopy] = useState(false);
  const [isrun, setIsRun] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const settingClick = (event, e, argisrun) => {
    // let honbuid = localStorage.getItem("honbuid");
    if (isAdmin(e.anpiid)) {
      setIsRun(argisrun);
      setOpenAnpiId(e);
      setAnchorEl(event.currentTarget);
      event.stopPropagation();
    }
  };
  const settingClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openEndModal, setOpenEndModal] = useState(false);

  const delAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/delNewQue?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenModal(false);
    }
  };
  const endAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/endNewQue?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenEndModal(false);
    }
  };
  const [expanded, setExpanded] = React.useState(true);
  const [endexpanded, setEndExpanded] = React.useState(true);
  const [editexpanded, setEditExpanded] = React.useState(true);

  const onPieMainClick = (e) => {
    // console.log(e);
    setOpenViewObj({
      anpiid: selectedAnpiid,
      isopen: true,
      vote: e.voteno,
      seq: "",
      no: "",
      name: e.name,
    });
  };
  const onPieClick = (e) => {
    // console.log(e);
    setOpenViewObj({
      anpiid: selectedAnpiid,
      isopen: true,
      vote: "",
      seq: e.seq,
      no: e.no,
      name: e.name,
    });
  };
  const [openViewObj, setOpenViewObj] = React.useState({
    anpiid: 0,
    isopen: false,
    vote: "",
    seq: "",
    no: "",
    name: "",
  });
  const handleAnpiUserViewClose = () => {
    setOpenViewObj({ ...openViewObj, isopen: false });
  };

  const [showFreeDialogOpen, setShowFreeDialogOpen] = useState(false);
  const [freeEle, setFreeEle] = useState([]);
  const handleOpenShowFreeDialog = (argTextFilter) => {
    setFreeEle(argTextFilter);
    // console.log("freeEle", argTextFilter);
    setShowFreeDialogOpen(true);
  };
  const handleCloseShowFreeDialog = () => {
    setShowFreeDialogOpen(false);
    setFreeEle([]);
  };

  const MemoAnpiCreate = useMemo(() => {
    // アンケート作成編集画面に移動時
    // MultiSelectに合わせてcurrentRoomMemberListを整形
    let memberList = [];
    for (var ele of currentRoomMemberList) {
      if (ele.honbuid !== localStorage.getItem("honbuid")) {
        memberList = [...memberList, { label: ele.name, value: ele.honbuid }];
      }
    }

    let adminIdList = [];
    // MultiSelectに合わせて現在管理者として選択されているListを作る
    if (openAnpiId !== null) {
      let filtedAdminList = currentAdminList.filter((e) => e.anpiid === openAnpiId.anpiid);
      if (filtedAdminList.length > 0) {
        for (var adminele of filtedAdminList) {
          if (adminele.honbuid !== localStorage.getItem("honbuid")) {
            const eleObj = memberList.find(({ value }) => value === adminele.honbuid);
            adminIdList = [...adminIdList, eleObj];
          }
        }
      }
    }

    return (
      <ShowNewQueCreate
        open={openAnpiCreate}
        openAnpiId={openAnpiId}
        handleClose={handleAnpiCreateClose}
        afterProc={mainproc}
        iscopy={iscopy}
        isrun={isrun}
        memberList={memberList}
        adminList={adminIdList}
      />
    );
  }, [openAnpiCreate, openAnpiId, iscopy]);

  const MemoAnpiUserView = useMemo(() => {
    return <ShowPlusQuestionUserView openViewObj={openViewObj} handleClose={handleAnpiUserViewClose} />;
  }, [openViewObj]);

  const [openVoteObj, setOpenVoteObj] = React.useState({ honbuid: 0, isopen: false });
  const handleAnpiVoteClose = () => {
    setOpenVoteObj({ ...openVoteObj, isopen: false });
  };
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowNewQueVote
        open={openVoteObj.isopen}
        honbuid={openVoteObj.honbuid}
        openAnpiId={openVoteObj.anpiid}
        handleClose={handleAnpiVoteClose}
        afterProc={subproc}
        roomid={null}
        isEnabled={true}
      />
    );
  }, [openVoteObj.isopen, openVoteObj.honbuid, openViewObj.anpiid]);

  const handleCsvClick = async () => {
    var apistring = API_URL + `/GetQuestionCsv?anpiid=${selectedAnpiid}`;

    const result = await custom_axios(apistring);
    const status = result.status;
    if (status === 200) {
      var titles = result.data.titles.map((t, i) => {
        return { header: t.title, key: "val" + (i + 1) };
      });

      const workbook = new ExcelJS.Workbook();
      workbook.addWorksheet("sheet1");
      const worksheet = workbook.getWorksheet("sheet1");

      worksheet.columns = [
        { header: "病院名", key: "hpname" },
        { header: "部署名", key: "unitname" },
        { header: "本部ID", key: "honbuid" },
        { header: "名前", key: "name" },
        { header: "入力時刻", key: "inmmdd" },
        { header: "修正時刻", key: "upmmdd" },
        ...titles,
      ];

      result.data.rows.forEach((row) => {
        worksheet.addRow({
          hpname: row.hpname,
          unitname: row.unitname,
          honbuid: row.honbuid,
          name: row.name,
          inmmdd: row.inmmdd,
          upmmdd: row.upmmdd,
          val1: row.val1,
          val2: row.val2,
          val3: row.val3,
          val4: row.val4,
          val5: row.val5,
          val6: row.val6,
          val7: row.val7,
          val8: row.val8,
          val9: row.val9,
          val10: row.val10,
          val11: row.val11,
          val12: row.val12,
          val13: row.val13,
          val14: row.val14,
          val15: row.val15,
          val16: row.val16,
          val17: row.val17,
          val18: row.val18,
          val19: row.val19,
          val20: row.val20,
        });
      });

      const uint8Array = new Uint8Array(
        encoding.convert(await workbook.csv.writeBuffer(), {
          from: "UTF8",
          to: "SJIS",
        })
      );
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "アンケート結果." + "csv";
      a.click();
      a.remove();
    }
  };
  const [sortModel, setSortModel] = React.useState([]);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  return (
    <Dialog fullScreen open={open} onClose={handleClose} fullWidth maxWidth={"xl"} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">アンケート一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                subproc();
              }}
              variant="outlined"
            >
              画面更新
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            {/* {createOk && ( */}
            <StyledButton
              onClick={() => {
                setIsRun(false);
                setOpenAnpiId(null);
                setIsCopy(false);
                setOpenAnpiCreate(true);
              }}
              variant="outlined"
            >
              アンケート作成
            </StyledButton>
            {/* )} */}
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoAnpiCreate}
        {MemoAnpiVote}
        {MemoQuestionVote}
        {MemoAnpiUserView}
        {/* {MemoAnpiChart} */}
        <div className="question-dialog-wraper">
          <div className="anpi-member-list">
            <div>
              <StyledDivAccordion onClick={() => setExpanded(!expanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  実施中
                </div>
                <StyledIconButton size={"small"}>{expanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: expanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemp != "1" && f.isend != "1")
                    .sort(function (a, b) {
                      if (a.timestring > b.timestring) {
                        return -1;
                      }
                      if (a.timestring < b.timestring) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp, false);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%" }}>{e.time}</span>
                            </div>
                            {/* {state.honbuid == e.honbuid ? ( */}
                            {isAdmin(e.anpiid) ? (
                              <div onClick={(event) => settingClick(event, e, true)}>
                                <SettingsIcon color="primary" />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <div>
              <StyledDivAccordion onClick={() => setEndExpanded(!endexpanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  終了
                </div>
                <StyledIconButton size={"small"}>{endexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: endexpanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemp != "1" && f.isend == "1")
                    .sort(function (a, b) {
                      if (a.timestring > b.timestring) {
                        return -1;
                      }
                      if (a.timestring < b.timestring) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp, true);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%" }}>{e.time}</span>
                            </div>
                            {/* {state.honbuid == e.honbuid ? ( */}
                            {isAdmin(e.anpiid) ? (
                              <div onClick={(event) => settingClick(event, e, true)}>
                                <SettingsIcon color="primary" />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <div>
              <StyledDivAccordion onClick={() => setEditExpanded(!editexpanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  一時保存中
                </div>

                <StyledIconButton size={"small"}>{editexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: editexpanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemp == "1")
                    .sort(function (a, b) {
                      if (a.timestring > b.timestring) {
                        return -1;
                      }
                      if (a.timestring < b.timestring) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            // handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%" }}>{e.time}</span>
                            </div>
                            {/* {state.honbuid == e.honbuid ? ( */}
                            {isAdmin(e.anpiid) ? (
                              <div onClick={(event) => settingClick(event, e, false)}>
                                <SettingsIcon color="primary" />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={settingClose}>
              {openAnpiId && openAnpiId.istemp == "1" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setIsCopy(false);
                    setOpenAnpiCreate(true);
                  }}
                >
                  編集
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemp == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setIsCopy(false);
                    setOpenAnpiCreate(true);
                  }}
                >
                  設定変更
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemp == "0" && openAnpiId.isend == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenEndModal(true);
                  }}
                >
                  終了
                </MenuItem>
              )}
              {openAnpiId && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setIsRun(false);
                    setIsCopy(true);
                    setOpenAnpiCreate(true);
                  }}
                >
                  複製
                </MenuItem>
              )}
              {openAnpiId && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenModal(true);
                  }}
                >
                  削除
                </MenuItem>
              )}
            </Menu>
            {/* <ShowCommon
              open={openPushObj.isopen}
              title="プッシュ通知しますか？"
              handleClose={() => {
                handlePushClose();
              }}
              handleCallBack={() => handlePush()}
            ></ShowCommon> */}
            <ShowCommon
              open={openModal}
              title="削除しますか？"
              handleClose={() => {
                setOpenModal(false);
              }}
              handleCallBack={() => delAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
            <ShowCommon
              open={openEndModal}
              title="終了しますか？"
              handleClose={() => {
                setOpenEndModal(false);
              }}
              handleCallBack={() => endAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
          </div>

          {loading ? (
            <div>
              <StyledCircularProgress />
            </div>
          ) : selectedAnpiid ? (
            <StyledPieCenter>
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                <Tab label="概要" />
                {/* <Tab label="チャート" /> */}
                <Tab label="詳細" />
              </Tabs>
              {value == 0 ? (
                <StyledChartMain>
                  <StyledCenter>
                    <TopDiv>
                      <div>
                        <Typography variant="h5">
                          {"報告状況 (" +
                            votesumarry.reduce(function (sum, element) {
                              return sum + element.cnt;
                            }, 0) +
                            "人)"}
                        </Typography>
                      </div>
                      <div>
                        {/* <Tooltip title="CSVダウンロード"> */}
                        <IconButton color="primary" aria-label="send message" onClick={handleCsvClick}>
                          <GetAppIcon />
                        </IconButton>
                        {/* </Tooltip> */}
                      </div>
                    </TopDiv>

                    <PieChart width={600} height={300}>
                      <Tooltip></Tooltip>
                      <Pie
                        data={votesumarry}
                        startAngle={-270}
                        isAnimationActive={false}
                        onClick={onPieMainClick}
                        dataKey="cnt"
                        nameKey="vote"
                        cx="50%"
                        cy="50%"
                        outerRadius={120}
                        label={({ vote, cnt }) => `${vote}:${cnt}人`}
                      >
                        {votesumarry.map((entry, index) => (
                          <Cell cursor="pointer" key={index} fill={entry.vote == "済" ? TOPCOLORS[0] : TOPCOLORS[1]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </StyledCenter>
                  <StyledChartSub>
                    {textgroup.map((e) => {
                      var textfilter = textsumarry.filter((f) => e.seq == f.seq);
                      return (
                        <StyledCenter key={e.seq}>
                          <div>
                            {e.title +
                              " (" +
                              textfilter.reduce(function (sum, element) {
                                return sum + element.cnt;
                              }, 0) +
                              "件)"}
                          </div>
                          {e.kbn === "radio" || e.kbn === "multi" ? (
                            <PieChart width={300} height={200}>
                              <Tooltip></Tooltip>
                              <Pie
                                data={textfilter}
                                startAngle={-270}
                                isAnimationActive={false}
                                onClick={onPieClick}
                                dataKey="cnt"
                                nameKey="text"
                                cx="50%"
                                cy="50%"
                                outerRadius={50}
                                label={({ text, cnt }) => `${text}:${cnt}件`}
                              >
                                {textfilter.map((entry, index) => (
                                  <Cell cursor="pointer" key={index} fill={COLORS[index % COLORS.length]} />
                                ))}
                              </Pie>
                            </PieChart>
                          ) : (
                            <StyledJustifyAlignCenter>
                              <StyledAltButton onClick={() => handleOpenShowFreeDialog(textfilter)} variant="outlined">
                                内容表示
                              </StyledAltButton>
                            </StyledJustifyAlignCenter>
                          )}
                        </StyledCenter>
                      );
                    })}
                  </StyledChartSub>
                </StyledChartMain>
              ) : (
                <div></div>
              )}

              {value == 1 ? (
                <StyledDataGrid>
                  <DataGrid
                    headerFilters
                    headerFilterHeight={80}
                    columnVisibilityModel={columnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                    filterModel={filterModel}
                    onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                    hideFooterSelectedRowCount={true}
                    rows={rows}
                    columns={columns}
                    pagination
                    density={"compact"}
                    slots={{
                      toolbar: CustomToolbar,
                      pagination: CustomPagination,
                    }}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          honbuid: false,
                        },
                      },
                    }}
                    // localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                  ></DataGrid>
                </StyledDataGrid>
              ) : (
                <div></div>
              )}
            </StyledPieCenter>
          ) : (
            <div className="anpi-member-list-right"></div>
          )}
        </div>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="保存しました。"
        ></Snackbar>

        <Snackbar
          open={snackpushopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackPushOpen(false);
          }}
          message="プッシュ通知しました。"
        ></Snackbar>
        <Dialog open={showFreeDialogOpen} onClose={handleCloseShowFreeDialog} maxWidth={"xs"} fullWidth>
          <StyledDialogTitle>回答内容表示</StyledDialogTitle>
          <StyledDialogContent>
            {freeEle.map((e) => {
              return (
                <React.Fragment key={`${e.seq}-${e.value}`}>
                  {/* <FreeEleDiv>{e.value}</FreeEleDiv> */}
                  <TextField
                    defaultValue={e.value}
                    fullWidth
                    multiline
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </React.Fragment>
              );
            })}
          </StyledDialogContent>
          <DialogActions>
            <Button onClick={handleCloseShowFreeDialog} variant="outlined" color="primary">
              閉じる (Esc)
            </Button>
          </DialogActions>
        </Dialog>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowPlusQuestionList;
