/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";
import { API_URL, custom_axios } from "../Common/Common";
import ShowPopupDetail from "../Common/ShowPopupDetail";
import Button from "@mui/material/Button";

import { useHistory } from "react-router-dom";
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: ${(props) => props.theme.palette.primary.light};
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowPopup({ open, honbuid, handleClose }) {
  const [isloading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const proc = async () => {
      if (open) {
        setIsLoading(true);
        const result = await custom_axios(API_URL + `/ShowBell?honbuid=${honbuid}`);
        const status = result.status;
        setIsLoading(false);

        if (status === 200) {
          if (result.data.isok == undefined || result.data.isok == "0") {
            handleClose();
            history.replace("/AlumniMode");
          } else {
            if (result.data.list.length === 0) {
              handleClose();
            } else {
              setList(result.data.list);
            }
          }
        }
      }
    };
    proc();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">{"お知らせ"}</div>

          <div className="question-title-actions">
            <StyledButton onClick={() => handleClose()} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {isloading ? (
          <div>Loading</div>
        ) : (
          <div className="logoff-wrap">
            {list &&
              list.map((e) => {
                return (
                  <ShowPopupDetail
                    title={e.title}
                    message={e.message}
                    yyyymm={e.yyyymm}
                    key={e}
                    // bellid={e.bellid}
                  ></ShowPopupDetail>
                );
              })}
          </div>
        )}
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowPopup;
