/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import purple from "@mui/material/colors/purple";
import green from "@mui/material/colors/green";
import teal from "@mui/material/colors/teal";
import indigo from "@mui/material/colors/indigo";
import blue from "@mui/material/colors/blue";
import deepPurple from "@mui/material/colors/deepPurple";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";
import deepOrange from "@mui/material/colors/deepOrange";
import orange from "@mui/material/colors/orange";
import yellow from "@mui/material/colors/yellow";
import brown from "@mui/material/colors/brown";
import lightGreen from "@mui/material/colors/lightGreen";
import cyan from "@mui/material/colors/cyan";
import lightBlue from "@mui/material/colors/lightBlue";
import amber from "@mui/material/colors/amber";
import lime from "@mui/material/colors/lime";
import blueGrey from "@mui/material/colors/blueGrey";
import grey from "@mui/material/colors/grey";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import styled from "@emotion/styled";
const StyledPink = styled("div")`
  color: ${pink[500]};
  font-weight: bold;
`;
const StyledPurple = styled("div")`
  color: ${purple[500]};
  font-weight: bold;
`;
const StyledGreen = styled("div")`
  color: ${green[500]};
  font-weight: bold;
`;
const StyledTeal = styled("div")`
  color: ${teal[500]};
  font-weight: bold;
`;
const StyledIndigo = styled("div")`
  color: ${indigo[500]};
  font-weight: bold;
`;
const StyledBlue = styled("div")`
  color: ${blue[500]};
  font-weight: bold;
`;
const StyledDeepPurple = styled("div")`
  color: ${deepPurple[500]};
  font-weight: bold;
`;
const StyledRed = styled("div")`
  color: ${red[500]};
  font-weight: bold;
`;
const StyledDeepOrange = styled("div")`
  color: ${deepOrange[500]};
  font-weight: bold;
`;
const StyledOrange = styled("div")`
  color: ${orange[500]};
  font-weight: bold;
`;
const StyledYellow = styled("div")`
  color: ${yellow[500]};
  font-weight: bold;
`;
const StyledBrown = styled("div")`
  color: ${brown[500]};
  font-weight: bold;
`;
const StyledLightGreen = styled("div")`
  color: ${lightGreen[500]};
  font-weight: bold;
`;
const StyledCyan = styled("div")`
  color: ${cyan[500]};
  font-weight: bold;
`;
const StyledLightBlue = styled("div")`
  color: ${lightBlue[500]};
  font-weight: bold;
`;
const StyledAmber = styled("div")`
  color: ${amber[500]};
  font-weight: bold;
`;
const StyledLime = styled("div")`
  color: ${lime[500]};
  font-weight: bold;
`;
const StyledBlueGrey = styled("div")`
  color: ${blueGrey[500]};
  font-weight: bold;
`;
const StyledGrey = styled("div")`
  color: ${grey[500]};
  font-weight: bold;
`;

function ShowColorSelect({ initvalue, setThemeColor }) {
  const [userOption, setUserOption] = React.useState([]);

  const handleChange = (event) => {
    setUserOption(event.target.value);
    setThemeColor(event.target.value);
  };
  useEffect(() => {
    setUserOption(initvalue);
  }, [initvalue]);

  return (
    <FormControl style={{ margin: "8px" }}>
      <InputLabel shrink id="demo-simple-select-placeholder-label-label">
        テーマカラー
      </InputLabel>
      <Select labelId="demo-simple-select-label" id="demo-simple-select" value={userOption} onChange={handleChange}>
        <MenuItem value={"green"}>
          <StyledGreen>green</StyledGreen>
        </MenuItem>
        <MenuItem value={"lightGreen"}>
          <StyledLightGreen>lightGreen</StyledLightGreen>
        </MenuItem>
        <MenuItem value={"teal"}>
          <StyledTeal>teal</StyledTeal>
        </MenuItem>
        <MenuItem value={"cyan"}>
          <StyledCyan>cyan</StyledCyan>
        </MenuItem>
        <MenuItem value={"lightBlue"}>
          <StyledLightBlue>lightBlue</StyledLightBlue>
        </MenuItem>
        <MenuItem value={"indigo"}>
          <StyledIndigo>indigo</StyledIndigo>
        </MenuItem>
        <MenuItem value={"blue"}>
          <StyledBlue>blue</StyledBlue>
        </MenuItem>
        <MenuItem value={"purple"}>
          <StyledPurple>purple</StyledPurple>
        </MenuItem>
        <MenuItem value={"deepPurple"}>
          <StyledDeepPurple>deepPurple</StyledDeepPurple>
        </MenuItem>
        <MenuItem value={"pink"}>
          <StyledPink>pink</StyledPink>
        </MenuItem>
        <MenuItem value={"red"}>
          <StyledRed>red</StyledRed>
        </MenuItem>
        <MenuItem value={"deepOrange"}>
          <StyledDeepOrange>deepOrange</StyledDeepOrange>
        </MenuItem>
        <MenuItem value={"orange"}>
          <StyledOrange>orange</StyledOrange>
        </MenuItem>
        <MenuItem value={"amber"}>
          <StyledAmber>amber</StyledAmber>
        </MenuItem>
        <MenuItem value={"yellow"}>
          <StyledYellow>yellow</StyledYellow>
        </MenuItem>
        <MenuItem value={"lime"}>
          <StyledLime>lime</StyledLime>
        </MenuItem>
        <MenuItem value={"brown"}>
          <StyledBrown>brown</StyledBrown>
        </MenuItem>
        <MenuItem value={"blueGrey"}>
          <StyledBlueGrey>blueGrey</StyledBlueGrey>
        </MenuItem>
        <MenuItem value={"grey"}>
          <StyledGrey>grey</StyledGrey>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export default ShowColorSelect;
