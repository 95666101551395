/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios } from "../Common/Common";
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogContent = styled(DialogContent)`
  //padding: 0px;
`;

function ShowKiyaku({ open, handleClose }) {
  const history = useHistory();
  const honbuid = localStorage.getItem("honbuid");
  const [loginMessage, setLoginMessage] = useState("");

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      const initrun = async () => {
        const result = await custom_axios(API_URL + `/getLoginMessage?honbuid=${honbuid}&kbn=web`);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        }
        if (status === 200) {
          setLoginMessage(result.data.message);
        }
      };
      initrun();
    };
    if (open) {
      proc();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">利用規約</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <div dangerouslySetInnerHTML={{ __html: loginMessage }}></div>
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowKiyaku;
