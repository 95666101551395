/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React, { useState } from "react";
import { STATIC_URL } from "../Common/Common";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
function ShowImageTimelineInfo({ open, timelineid, maxFileid, handleClose }) {
  const [fileid, setFileid] = useState(0);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">ニュース</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                if (0 < fileid) {
                  setFileid(fileid - 1);
                } else {
                  setFileid(Number(maxFileid));
                }
              }}
              variant="outlined"
            >
              ＜
            </StyledButton>
            {(Number(fileid) + 1).toString() + "/" + (Number(maxFileid) + 1).toString()}
            <StyledButton
              onClick={() => {
                if (fileid < Number(maxFileid)) {
                  setFileid(fileid + 1);
                } else {
                  setFileid(0);
                }
              }}
              variant="outlined"
            >
              ＞
            </StyledButton>

            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        <img
          src={
            STATIC_URL +
            (timelineid != null
              ? `/downloadTimeline?timelineid=${timelineid}&fileid=${fileid}&size=big`
              : `/downloadTimelineMaster?size=big`)
          }
          // width="200"
          // height="200"
          alt=""
          className="show-image"
        />
      </StyledDialogContent>
    </Dialog>
  );
}
export default ShowImageTimelineInfo;
