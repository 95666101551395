import React, { useContext, Fragment, useState, useEffect } from "react";
import "./Header.css";
import { Store } from "../Store/store";
import useRoomName from "../Common/useRoomName";
import ShowCommon from "../Common/ShowCommon";
// import ShowCall from "../Common/ShowCall";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { API_URL, custom_axios } from "../Common/Common";
import { useHistory } from "react-router-dom";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import Link from "@mui/material/Link";
// import Button from "@mui/material/Button";
// import { BsArrowLeft } from "react-icons/bs";

function Header() {
  const history = useHistory();
  const { state, dispatch, hubConnection } = useContext(Store);
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    setSearchText("");
  }, [state.currentRoomid]);
  useEffect(() => {
    if (state.chatsFilter == "") {
      setSearchText("");
    }
  }, [state.chatsFilter]);
  const handleKeyDown = (e) => {
    //e.preventDefault();
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (searchText != "") {
        handleSearchClick();
      }
    }
  };
  const handleCloseClick = async () => {
    setSearchText("");
    dispatch({
      type: "CHANGE_CHATFILTER",
      payload: { chatsFilter: "", messages: [] },
    });
  };
  const handleSearchClick = async () => {
    const result = await custom_axios(API_URL + `/searching?roomid=${state.currentRoomid}&message=${searchText}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      dispatch({
        type: "CHANGE_CHATFILTER",
        payload: { chatsFilter: searchText, messages: result.data.messages },
      });
    } else {
      dispatch({
        type: "CHANGE_CHATFILTER",
        payload: { chatsFilter: "", messages: [] },
      });
    }
  };

  const [dispRoomName, ispush, parentroomname] = useRoomName();

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleLeaveRoom = async () => {
    dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/delUserFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      hubConnection.invoke(
        "SendMessage",
        state.currentRoomid,
        state.honbuid,
        state.user_name,
        state.user_name + " が退室しました",
        "information"
      );
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  // const [openCall, setOpenCall] = useState(false);
  // const handleCallClose = () => {
  //   setOpenCall(false);
  // };
  return (
    <div className="chat-area-header">
      <div className="chat-area-header-inner">
        {/* {state.chatrooms.filter((f) => f.roomid === state.currentRoomid).length > 0 &&
          state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].roomname} */}
        {dispRoomName}
        {!ispush && <NotificationsOffIcon style={{ fill: "black" }} />}
        {state.isMiniRoom && (
          <Link
            style={{ marginLeft: "10px" }}
            onClick={() => {
              dispatch({ type: "CHANGE_ROOM", payload: { to: state.parentroomid, from: state.currentRoomid } });
              localStorage.setItem("currentRoomid", state.parentroomid);
              localStorage.setItem("parentRoomid", state.parentroomid);
              localStorage.setItem("isbot", "0");
            }}
          >
            {parentroomname}
          </Link>
        )}
      </div>

      <Fragment>
        <div className="chat-area-header-content">
          <div className="chat-search">
            <div className="chat-search-icon">
              <IconButton color="primary" aria-label="send message" onClick={handleSearchClick}>
                <Search />
              </IconButton>
            </div>
            <input
              placeholder="チャット内検索"
              value={searchText}
              name="chatsFilter"
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className="chat-search-field"
            />
            <div className="chat-search-icon">
              <IconButton color="primary" aria-label="send message" onClick={handleCloseClick}>
                <Close />
              </IconButton>
            </div>
          </div>

          <ShowCommon
            open={openModal}
            title="会話から抜けますか？"
            handleClose={handleModalClose}
            handleCallBack={handleLeaveRoom}
          ></ShowCommon>
          {/* <ShowCall
            open={openCall}
            handleClose={handleCallClose}
            channel={state.currentRoomid}
            uid={localStorage.getItem("honbuid")}
            name={state.user_name}
            flag="create"
          ></ShowCall> */}
        </div>
      </Fragment>
    </div>
  );
}

export default Header;
