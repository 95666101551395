/* eslint-disable react/prop-types */
import React, { useState, useCallback, useEffect, useMemo } from "react";
import "./Login.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
// import { Store } from "../Store/store";
import axios from "axios";
import { LOGIN_URL } from "../Common/Common";
import CircularProgress from "@mui/material/CircularProgress";
import topimage from "../image/main.png";
import styled from "@emotion/styled";
import Link from "@mui/material/Link";
import ShowInitPass from "../Common/ShowInitPass";
import Snackbar from "@mui/material/Snackbar";
// import{ useLocation } from "react-router-dom";
const StyledImg = styled("img")`
  width: 30%;
  height: 30%;
`;
const StyledDivPaper = styled("div")`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledDivSub = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledForm = styled("form")`
  min-width: 300px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;
const StyledLink = styled(Link)`
  margin: 10px;
`;
const StyledSubmit = styled(Button)`
  min-width: 300px;
`;
const StyledWrapper = styled("div")`
  position: relative;
`;
const StyledCircularProgress = styled(CircularProgress)`
  color: green;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;

function Login(props) {
  const [isLoading] = useState(false);
  const [honbuid, setHonbuid] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMe, setIsRememberMe] = useState(false);
  // const { hubConnection } = useContext(Store);
  const handleChangeHonbuid = useCallback((e) => {
    setHonbuid(e.target.value);
  }, []);
  const [snackopen, setSnackOpen] = useState({ open: false, text: "" });

  const handleChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await login();
  };
  const [passOpen, setPassOpen] = useState(false);
  const handlePassClose = () => {
    setPassOpen(false);
  };

  const MemoShowInitPass = useMemo(() => {
    return <ShowInitPass open={passOpen} handleClose={handlePassClose} setSnackOpen={setSnackOpen}></ShowInitPass>;
  }, [passOpen, snackopen]);

  const login = async () => {
    //通信プロトコルを取得する
    var a = window.location.protocol;
    var hostname = window.location.hostname;
    if (hostname != "localhost" && a == "http:") {
      alert("http通信になっています、httpsでアクセスしてください!");
      return;
    }
    const body = { honbuid: honbuid, pass: password };
    const result = await axios.post(LOGIN_URL + "/newlogin", body);
    const status = result.status;
    if (status == 205) {
      alert("アカウントがロックされています!");
    } else if (status !== 200) {
      alert("ログイン情報が正しくありません!");
    } else {
      const initialized = result.data.initialized;
      const enterchecked = result.data.enterchecked;
      const apikey = result.data.apikey;
      // const initpass = result.data.initpass;
      const initphone = result.data.initphone;
      if (initialized == "1") {
        loginProc(enterchecked, apikey);
        // hubConnection.stop();
        localStorage.setItem("login", "true");
        props.history.replace("/");
      } else if (initphone == "1") {
        loginProc(enterchecked, apikey);
        // hubConnection.stop();
        props.history.replace("/initphone");
      } else {
        loginProc(enterchecked, apikey);
        // hubConnection.stop();
        props.history.replace("/initpass");
      }
      // } else {
      //   loginProc(enterchecked, apikey);
      //   hubConnection.stop();
      //   props.history.replace("/chekLogin");
      // }
    }
  };
  const loginProc = (enterchecked, apikey) => {
    //localStorage.setItem("login", "true");
    localStorage.setItem("checkedEnter", enterchecked);
    localStorage.setItem("apikey", apikey);
    if (isRememberMe) {
      localStorage.setItem("rememberme", "true");
      localStorage.setItem("honbuid", honbuid);
      localStorage.setItem("password", password);
    } else {
      localStorage.removeItem("rememberme");
      localStorage.setItem("honbuid", honbuid);
      localStorage.removeItem("password");
    }
  };

  useEffect(() => {
    const rem = localStorage.getItem("rememberme");
    if (rem) {
      setIsRememberMe(true);
      setHonbuid(localStorage.getItem("honbuid"));
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      {MemoShowInitPass}
      <CssBaseline />
      <StyledDivPaper>
        <StyledImg src={topimage} alt="アイコン" />
        <Typography component="h1" variant="h5">
          ChaTisログイン!!
        </Typography>
        <StyledDivSub>
          <Snackbar
            open={snackopen.open}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
            onClose={() => {
              setSnackOpen({ open: false, text: "" });
            }}
            message={snackopen.text}
          ></Snackbar>
          <StyledForm onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="number"
              id="honbuid"
              value={honbuid}
              onChange={handleChangeHonbuid}
              label="本部ID"
              name="honbuid"
              autoComplete="honbuid"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              value={password}
              onChange={handleChangePassword}
              autoComplete="current-password"
            />

            <StyledWrapper>
              <StyledSubmit disabled={isLoading} type="submit" fullWidth variant="contained" color="primary">
                ログイン
              </StyledSubmit>

              {isLoading && <StyledCircularProgress size={24} />}
            </StyledWrapper>
          </StyledForm>
          <StyledLink
            component="button"
            variant="body2"
            onClick={() => {
              setPassOpen(true);
            }}
          >
            パスワードを忘れた
          </StyledLink>
        </StyledDivSub>
      </StyledDivPaper>
    </Container>
  );
}
export default Login;
