/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useContext } from "react";
import { Store } from "../Store/store";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ShowAnpiUserViewRow from "../Common/ShowAnpiUserViewRow";
import styled from "@emotion/styled";
import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import Button from "@mui/material/Button";
import {ThreeDots as Loader} from "react-loader-spinner";
import ShowPushMessage from "../Common/ShowPushMessage";
import ShowAnpiVote from "./ShowAnpiVote";
import Snackbar from "@mui/material/Snackbar";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDiv = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

// eslint-disable-next-line
function ShowAnpiUserViewMini({ openViewObj, handleClose }) {
  const [membersList, setMembersList] = useState([]);
  const { state } = useContext(Store);
  const [isload, setIsLoad] = useState(false);
  const mainproc = async () => {
    setIsLoad(true);
    var apistring =
      API_URL +
      `/GetAllAnpiRowsMini?anpiid=${openViewObj.anpiid}&vote=${openViewObj.vote}&seq=${openViewObj.seq}&no=${openViewObj.no}`;

    const result = await custom_axios(apistring);
    const status = result.status;
    // if (status === 401) {
    //   history.replace("/loginError");
    // }
    if (status === 200) {
      var rows = result.data.rows;
      setMembersList(rows);
    }
    setIsLoad(false);
  };

  useEffect(() => {
    if (openViewObj.isopen) {
      mainproc();
    } else {
      setMembersList([]);
    }
  }, [openViewObj.isopen]);

  const handlePush = async (inputtext) => {
    //let honbuid = localStorage.getItem("honbuid");
    var maps = membersList.map((c) => c.honbuid);
    const body = {
      //anpiid: openViewObj.anpiid,
      honbuids: maps,
      message: inputtext.html,
      roomname: "安否確認",
    };

    console.log("post前", body);
    await custom_axios_post(API_URL + "/pushAnpiMembers", body);

    // if (result.status === 401) {
    //   history.replace("/loginError");
    // }
    handleOpenClose();
    setSnackPushOpen(true);
  };
  const [open, setOpen] = useState(false);
  const handleOpenClose = () => {
    setOpen(false);
  };
  const MemoShowCommon = useMemo(() => {
    return <ShowPushMessage open={open} handleClose={handleOpenClose} handleCallBack={handlePush}></ShowPushMessage>;
  }, [open]);

  const [openVoteObj, setOpenVoteObj] = React.useState({ honbuid: 0, isopen: false });
  const handleAnpiVoteClose = () => {
    setOpenVoteObj({ ...openVoteObj, isopen: false });
  };
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowAnpiVote
        open={openVoteObj.isopen}
        honbuid={openVoteObj.honbuid}
        openAnpiId={openViewObj.anpiid}
        handleClose={handleAnpiVoteClose}
        afterProc={() => {}}
        roomid={null}
        isEnabled={false}
        isadmin={state.isadmin}
        isanpi={state.isanpi}
      />
    );
  }, [openVoteObj.isopen, openVoteObj.honbuid, openViewObj.anpiid]);

  const [snackpushopen, setSnackPushOpen] = useState(false);

  return (
    <Dialog open={openViewObj.isopen} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div>{openViewObj.name + " (" + membersList.length.toString() + "人)"}</div>

          {(["1", "2"].includes(state.isadmin) || state.isanpi == "1") && (
            <StyledButton
              onClick={() => {
                setOpen(true);
              }}
              //className={classes.textcolor}
              variant="outlined"
            >
              {membersList.length.toString() + "人へ再通知"}
            </StyledButton>
          )}
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <Snackbar
          open={snackpushopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackPushOpen(false);
          }}
          message="プッシュ通知しました。"
        ></Snackbar>
        <div>
          {MemoShowCommon}
          {MemoAnpiVote}
          {isload == false ? (
            membersList.map((e) => {
              //console.log("中の処理", e);
              return (
                <ShowAnpiUserViewRow
                  key={e.honbuid}
                  honbuid={e.honbuid}
                  fileno={e.fileno}
                  name={e.name}
                  anpiid={openViewObj.anpiid}
                  setOpenVoteObj={setOpenVoteObj}
                ></ShowAnpiUserViewRow>
              );
            })
          ) : (
            <StyledDiv>
              <Loader type="ThreeDots" height="100" width="100" />
            </StyledDiv>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowAnpiUserViewMini;
