/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import ContentEditable from "react-contenteditable";

const StyledDiv = styled("div")`
  //min-width: 300;
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
function ShowPushMessage({ open, handleClose, handleCallBack }) {
  const myRef = useRef(null);
  const [inputtext, setInputtext] = useState({ html: "" });
  const [charSize, setCharSize] = useState(0);

  const handleKeyDown = (e) => {
    let checked = localStorage.getItem("checkedEnter") == "1" ? true : false;
    const sendMes = () => {
      e.preventDefault();
      handleCallBack(inputtext);
    };
    // Enter押下
    if (e.which === 13) {
      // Ctrl
      if (e.ctrlKey && !e.shiftKey) {
        sendMes();
        // Shift
      } else if (!e.ctrlKey && e.shiftKey) {
        // 改行
        return false;
        // Ctrl, Shiftなし
      } else {
        // 「Enterで送信」チェックの有無
        if (checked) {
          sendMes();
        } else {
          // 改行
          return false;
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">案内するメッセージを入力してください</div>
      </StyledDialogTitle>

      <DialogContent>
        <StyledDiv>
          <ContentEditable
            className={"editablediv"}
            innerRef={myRef}
            html={inputtext.html}
            disabled={false}
            onChange={(e) => {
              let sendstr = myRef.current.innerHTML.replace(/<br>/g, "\n").replace(/<(?:.|)*?>/gm, "");
              //console.log("文字の中身", myRef.current.innerHTML, sendstr);

              setCharSize(sendstr.length);
              setInputtext({ html: e.target.value });
            }} // handle innerHTML change
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <div className="sending-area-buttons">(文字:{charSize} / 1000)</div>
        </StyledDiv>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => handleCallBack(inputtext)} variant="outlined" color="primary">
          送信
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowPushMessage;
