import React, { useContext } from "react";
//import "./Input.css";
import { Store } from "../Store/store";
import ChatRooms from "../ChatRooms/ChatRooms";
import Header from "../Header/Header";
import ChatMember from "../ChatMember/ChatMember";
import ChatConversations from "../ChatConversations/ChatConversations";
import EmptyChat from "../EmptyChat/EmptyChat";
import MuiXLicense from "../Common/MuiXLicense";
//import NotEmptyChat from "../EmptyChat/NotEmptyChat";
// import "react-data-grid/lib/styles.css";
function MainChat() {
  const { state } = useContext(Store);
  // const columns = [
  //   { key: "id", name: "ID" },
  //   { key: "title", name: "Title" },
  // ];

  // const rows = [
  //   { id: 0, title: "Example" },
  //   { id: 1, title: "Demo" },
  // ];

  return (
    <>
      <div className="chat-app">
        <ChatRooms />
        <MuiXLicense />
        {state.currentRoomid != "" && state.chatrooms.filter((f) => f.roomid === state.currentRoomid).length > 0 ? (
          <div className="chat-app-wrap">
            <div className="chat-area">
              <Header />
              <ChatConversations />
            </div>
            <div className="chatmember">
              <ChatMember />
            </div>
          </div>
        ) : (
          <EmptyChat />
        )}
      </div>
      {/* )} */}
    </>
  );
}

export default MainChat;
