import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import "./Login.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Lottie from "lottie-react";
import animationData from "./alumni.json";
import { Store } from "../Store/store";
import axios from "axios";
import { LOGIN_URL, API_URL, custom_axios_post, custom_axios } from "../Common/Common";
import styled from "@emotion/styled";

const AlumniButton = styled(Button)`
  margin: 15px;
`;
const AlumniDiv = styled("div")`
  margin: 15px;
`;

function AlumniMode() {
  const history = useHistory();
  const { dispatch, hubConnection } = useContext(Store);
  const [loginMessage, setLoginMessage] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    //console.log("GetUser前", honbuid);

    const initrun = async () => {
      const result = await custom_axios(API_URL + `/getAlumniLoginMessage?kbn=web`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setLoginMessage(result.data.message);
      }
    };

    initrun();
  }, []);

  const alumniproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
    };
    const result = await custom_axios_post(API_URL + "/createAlumni", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      history.replace("/");
    }
  };
  const style = {
    height: 180,
    width: 220,
  };
  const handleLogoff = async () => {
    hubConnection.methods = [];
    /* for (let i = 0; i < Object.keys(hubConnection.methods).length; i++) {
      if (Object.keys(hubConnection.methods)[i]) {
        hubConnection.off(Object.keys(hubConnection.methods)[i]);
        console.log("hubConnection", Object.keys(hubConnection.methods)[i]);
      }
    } */
    //console.log("hubConnection", hubConnection);

    let honbuid = localStorage.getItem("honbuid");
    await axios(LOGIN_URL + `/deleteWebToken?honbuid=${honbuid}`);

    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("login");
    history.replace("/login");
  };
  return (
    <div className="alumni-wrap">
      <Lottie
        style={style}
        animationData={animationData}
        loop={true}
        // width={200}
        // height={200}
        // width={250}
        // height={200}
        // options={{
        //   loop: true,
        //   autoplay: true,
        //   animationData,
        //   rendererSettings: {
        //     preserveAspectRatio: "xMidYMid slice",
        //   },
        // }}
      />
      <AlumniDiv dangerouslySetInnerHTML={{ __html: loginMessage }}></AlumniDiv>

      <div>
        <FormControlLabel
          control={
            <Checkbox checked={checked} onChange={() => setChecked((v) => !v)} name="checkedB" color="primary" />
          }
          label="確認"
        />
        <AlumniButton
          variant="contained"
          color="primary"
          disabled={checked ? false : true}
          onClick={() => alumniproc()}
        >
          利用開始
        </AlumniButton>
      </div>
      <AlumniButton variant="outlined" color="primary" onClick={() => handleLogoff()}>
        利用しない
      </AlumniButton>
    </div>
  );
}

export default AlumniMode;
