/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useReducer, useContext, useMemo } from "react";
import { API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Search from "@mui/icons-material/Search";
import ShowMemberDetail from "./ShowMemberDetail";
import { MultiSelect } from "react-multi-select-component";
import styled from "@emotion/styled";
import Snackbar from "@mui/material/Snackbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import IconButton from "@mui/material/IconButton";
// import Close from "@mui/icons-material/Close";
import ShowHonbuidInput from "../Common/ShowHonbuidInput";
import Tooltip from "@mui/material/Tooltip";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ReorderIcon from "@mui/icons-material/Reorder";

// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
const StyledSelect = styled(Select)`
  margin-top: 2px;
  margin-bottom: 10px;
`;
const StyledMultiSelect = styled(MultiSelect)`
  max-width: 100%;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledFcolor = styled("div")`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

const StyledFlexDiv = styled("div")`
  display: flex;
`;

const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledTitleDiv = styled("div")`
  display: flex;
  align-items: center;
  gap: 0px 4px; /* 余白 */
`;

function ShowMember({ open, handleClose, isadduser, roomid, iswithme = false, callback }) {
  const { state, hubConnection } = useContext(Store);
  const history = useHistory();
  const [errorsnackopen, setErrorSnackOpen] = useState(false);
  const [bukaisnackopen, setBukaiSnackOpen] = useState(false);
  const [isAllCheck, setIsAllCheck] = useState(false);
  // const [ischeck, setIsCheck] = useState(true);
  const allCheck = () => {
    if (isAllCheck) {
      listdispatch({ type: "CHECK_OFF", payload: [] });
      setIsAllCheck(false);
    } else {
      listdispatch({ type: "CHECK_ON", payload: [] });
      setIsAllCheck(true);
    }
  };
  const honbuidSearch = () => {
    setOpenHonbuidInput(!openHonbuidInput);
  };
  const handleHonbuidInputClose = () => {
    setOpenHonbuidInput(false);
  };
  const [openHonbuidInput, setOpenHonbuidInput] = useState(false);

  const [inputhonbuids, setInputHonbuids] = useState("");

  const searchHonbuidProc = async (word) => {
    setInputHonbuids(word);
    let honbuid = localStorage.getItem("honbuid");
    var ids = list
      .filter((f) => f.flag === "selected")
      .map((v) => v.honbuid)
      .join("_");
    const body = {
      honbuid: honbuid,
      roomid: isadduser ? roomid : "",
      iswithme: iswithme ? "1" : "",
      hascode: "1",
      ids: ids,
      searchone: word, //
    };
    const result = await custom_axios_post(API_URL + "/GetUsershonbuid", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      listdispatch({ type: "INIT", payload: result.data.users });
    }
    handleHonbuidInputClose();
  };
  const afterSaveProc = async () => {
    let joinhps = selected
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinunits = selectedunit
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || joinunits.length == 0) {
      setSearchName("");
      listdispatch({ type: "INIT", payload: list.filter((f) => f.flag === "selected") });
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      var ids = list
        .filter((f) => f.flag === "selected")
        .map((v) => v.honbuid)
        .join("_");
      const body = {
        honbuid: honbuid,
        roomid: isadduser ? roomid : "",
        iswithme: iswithme ? "1" : "",
        hascode: "1",
        ids: ids,
        hpcodes: joinhps,
        unitcodes: joinunits,
        // searchone: searchText,
      };
      const result = await custom_axios_post(API_URL + "/GetUsers", body);

      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setOptions(
          result.data.hps.map((e) => {
            return { label: e.hpname, value: e.hpcode };
          })
        );
        setUnitOptions(
          result.data.units.map((e) => {
            return { label: e.unitname, value: e.unitcode };
          })
        );
        if (result.data.bukais.length > 0) {
          setBukais([
            <MenuItem key={0} value={"0"}>
              {"通常"}
            </MenuItem>,
            ...result.data.bukais.map((e) => {
              return (
                <MenuItem key={e.bukaicode} value={e.bukaicode}>
                  {e.bukainame}
                </MenuItem>
              );
            }),
          ]);
        } else {
          setBukais([]);
        }
        setSearchName("");
        listdispatch({ type: "INIT", payload: result.data.users });
      } else {
        setSearchName("");
        listdispatch({ type: "INIT", payload: result.data.users });
      }
    }
  };
  const MemoHonbuidInput = useMemo(() => {
    return (
      <ShowHonbuidInput
        open={openHonbuidInput}
        handleClose={handleHonbuidInputClose}
        callBackProc={searchHonbuidProc}
        afterSaveProc={afterSaveProc}
        honbuids={inputhonbuids}
        setHonbuids={setInputHonbuids}
      ></ShowHonbuidInput>
    );
  }, [openHonbuidInput, inputhonbuids]);
  const [list, listdispatch] = useReducer((state, action) => {
    //console.log("action.type", action.type);
    //console.log("action.payload", action.payload);
    switch (action.type) {
      case "INIT":
        var ids = state.filter((f) => f.flag === "selected").map((v) => v.honbuid);
        if (ids.length > 0) {
          return action.payload.map((item) => {
            if (ids.includes(item.honbuid)) {
              return { ...item, flag: "selected", search: "" };
            } else {
              return { ...item, flag: "", search: "" };
            }
          });
        } else {
          return action.payload.map((item) => {
            return { ...item, search: "" };
          });
        }
      case "SEARCH":
        var temp = state.map((item) => {
          if (action.payload === "") {
            return { ...item, search: "" };
          } else if (
            (action.payload && String(item.name).indexOf(action.payload) !== -1) ||
            (action.payload && String(item.subname).indexOf(action.payload) !== -1) ||
            (action.payload && String(item.hpname).indexOf(action.payload) !== -1)
          ) {
            return { ...item, search: "hit" };
          } else {
            return { ...item, search: "" };
          }
        });
        return temp;
      case "CHANGE":
        return state.map((item) => {
          if (item.honbuid === action.payload) {
            if (item.flag === "selected") {
              return { ...item, flag: "" };
            } else {
              return { ...item, flag: "selected" };
            }
          }
          return item;
        });
      case "MARK":
        return state.map((item) => {
          if (item.honbuid === action.payload) {
            if (item.mark === "selected") {
              return { ...item, mark: "" };
            } else {
              return { ...item, mark: "selected" };
            }
          }
          return item;
        });
      case "CHECK_ON":
        return state.map((item) => {
          return { ...item, flag: "selected" };
        });
      case "CHECK_OFF":
        return state.map((item) => {
          return { ...item, flag: "" };
        });
      default:
        return state;
    }
  }, []);

  // const [searchText, setSearchText] = useState("");
  const [searchName, setSearchName] = useState("");
  const [sroomname, setSroomname] = useState("");

  const proc = async (localhps, localunits) => {
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinunits = localunits
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || joinunits.length == 0) {
      setSearchName("");
      listdispatch({ type: "INIT", payload: list.filter((f) => f.flag === "selected") });
      return;
    }
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      var ids = list
        .filter((f) => f.flag === "selected")
        .map((v) => v.honbuid)
        .join("_");
      const body = {
        honbuid: honbuid,
        roomid: isadduser ? roomid : "",
        iswithme: iswithme ? "1" : "",
        hascode: "1",
        ids: ids,
        hpcodes: joinhps,
        unitcodes: joinunits,
        // searchone: localsearchText,
      };
      const result = await custom_axios_post(API_URL + "/GetUsers", body);

      // const reqapi =
      //   API_URL +
      //   `/GetUsers?honbuid=${honbuid}${
      //     (isadduser ? "&roomid=" + roomid : "") + (iswithme ? "&iswithme=1" : "")
      //   }&hascode=1&hpcodes=${joinhps}&unitcodes=${joinunits}&ids=${ids}`;
      // const result = await custom_axios(reqapi);

      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setOptions(
          result.data.hps.map((e) => {
            return { label: e.hpname, value: e.hpcode };
          })
        );
        setUnitOptions(
          result.data.units.map((e) => {
            return { label: e.unitname, value: e.unitcode };
          })
        );
        if (result.data.bukais.length > 0) {
          setBukais([
            <MenuItem key={0} value={"0"}>
              {"通常"}
            </MenuItem>,
            ...result.data.bukais.map((e) => {
              return (
                <MenuItem key={e.bukaicode} value={e.bukaicode}>
                  {e.bukainame}
                </MenuItem>
              );
            }),
          ]);
        } else {
          setBukais([]);
        }
        setSearchName("");
        listdispatch({ type: "INIT", payload: result.data.users });
      } else {
        setSearchName("");
        listdispatch({ type: "INIT", payload: result.data.users });
      }
    }
  };

  const handleCreate = async () => {
    let resturl = "";

    const strhonbuid = localStorage.getItem("honbuid");
    const listhonbuid = list.filter((f) => f.flag === "selected").map((v) => v.honbuid);
    const listmarkhonbuid = list.filter((f) => f.mark === "selected").map((v) => v.honbuid);
    const listhonbuid2name = list.filter((f) => f.flag === "selected").map((v) => v.name);
    //console.log("listhonbuid", listhonbuid);

    if (isadduser) {
      /* 既存ルームにユーザー追加時 */
      resturl = `/addUserToRoom?roomid=${roomid}&honbuids=${listhonbuid.join("_")}`;
      const result = await custom_axios(API_URL + resturl);
      const status = result.status;
      //console.log("addUserToRoom", result);
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        for (let m = 0; m < listhonbuid.length; m++) {
          hubConnection.invoke(
            "SendMessage",
            roomid,
            listhonbuid[m],
            state.user_name,
            listhonbuid2name[m] + " を追加しました",
            "information"
          );
        }
        if (iswithme) {
          callback();
        }
      }
    } else if (iswithme) {
      /* ルーム作成時(管理者画面から作成) */
      //部会管理者だけの権限の人は、普通の部屋は作成されないようにする
      if (["0"].includes(state.isadmin) && bukaivalue == "0") {
        setBukaiSnackOpen(true);
        return;
      }
      const body = {
        mainhonbuids: listhonbuid.join("_"),
        roomname: sroomname,
        role: role,
        markmainhonbuids: listmarkhonbuid.join("_"),
        isbukairoom: bukaivalue,
        honbuid: strhonbuid,
      };
      const result = await custom_axios_post(API_URL + "/CreateAdminRoom", body);

      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      //console.log("createRoom", result);
      if (status === 200) {
        hubConnection.invoke(
          "SendMessage",
          result.data.roomid,
          strhonbuid,
          state.user_name,
          "部屋が作成されました。",
          "information"
        );

        callback();
      }
    }

    handleClose();
  };

  // const [joboptions, setJobOptions] = useState([]);
  const [unitoptions, setUnitOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const [bukais, setBukais] = useState([]);
  const [bukaivalue, setBukaivalue] = useState("0");

  // const [selectedjob, setSelectedJob] = useState([]);
  const [selectedunit, setSelectedUnit] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (open) {
      setSelected([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setSelectedUnit([{ label: state.unitcode + ":" + state.unitname, value: state.unitcode }]);
      proc(
        [{ label: state.hpname, value: state.hpcode }],
        [{ label: state.unitname, value: state.unitcode }]
        // searchText
      );
    } else {
      setSearchName("");
      setInputHonbuids("");
      listdispatch({ type: "INIT", payload: [] });
    }
  }, [open]);

  const [role, setRole] = React.useState("0");

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleNameChange = (e) => {
    setSearchName(e.target.value);
  };
  useEffect(() => {
    if (searchName !== "") {
      // 人名検索
      listdispatch({ type: "SEARCH", payload: searchName });
    }
  }, [searchName]);

  const handleBukaiChange = (event) => {
    setBukaivalue(event.target.value);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <StyledFcolor>{isadduser ? "メンバー追加" : "部屋作成"}</StyledFcolor>

          <StyledTitleDiv>
            <div className="chat-search">
              <div className="chat-search-icon">
                <StyledSearch />
              </div>
              <input
                placeholder="名前検索"
                value={searchName}
                name="chatsFilter"
                onChange={handleNameChange}
                className="chat-search-field"
              />
            </div>
            {/* )} */}
            {/* {(["1", "6"].includes(state.isadmin) || state.isbukaiadmin == "1") && ( */}

            <Tooltip title="全てチェック">
              {isAllCheck ? (
                <ReorderIcon onClick={() => allCheck()} className="logoff-button" />
              ) : (
                <PlaylistAddCheckIcon onClick={() => allCheck()} className="logoff-button" />
              )}
            </Tooltip>
            <Tooltip title="本部ID・名前で検索">
              {inputhonbuids == "" ? (
                <PageviewOutlinedIcon onClick={() => honbuidSearch()} className="logoff-button" />
              ) : (
                <PageviewRoundedIcon onClick={() => honbuidSearch()} className="logoff-button" />
              )}
            </Tooltip>
            {/* )} */}
          </StyledTitleDiv>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        {MemoHonbuidInput}
        <div className="full-height">
          <div className="multi-select-mini">
            <StyledMultiSelect
              options={options}
              value={selected}
              onChange={(e) => {
                setSelected(e);
                proc(e, selectedunit);
              }}
              labelledBy={"Select"}
              overrideStrings={{
                selectSomeItems: "選択...",
                allItemsAreSelected: "全選択",
                selectAll: "全選択",
                search: "検索",
                clearSearch: "検索クリア",
              }}
              filterOptions={(options, filter) => {
                if (!filter) {
                  return options;
                }
                const re = new RegExp(filter, "i");
                return options.filter(({ label }) => label && label.match(re));
              }}
            />
          </div>
          <div className="multi-select-mini">
            <StyledMultiSelect
              options={unitoptions}
              value={selectedunit}
              onChange={(e) => {
                setSelectedUnit(e);
                proc(selected, e);
              }}
              labelledBy={"Select"}
              overrideStrings={{
                selectSomeItems: "選択...",
                allItemsAreSelected: "全選択",
                selectAll: "全選択",
                search: "検索",
                clearSearch: "検索クリア",
              }}
              filterOptions={(options, filter) => {
                if (!filter) {
                  return options;
                }
                const re = new RegExp(filter, "i");
                return options.filter(({ label }) => label && label.match(re));
              }}
            />
          </div>
          {list &&
            list.filter((f) => f.flag !== "none") &&
            list
              .filter((f) => f.flag !== "none")
              .filter((f) => searchName == "" || (searchName != "" && f.search == "hit"))
              .map((e) => {
                //console.log("中の処理", e);
                return (
                  <ShowMemberDetail
                    key={e.honbuid}
                    honbuid={e.honbuid}
                    fileno={e.fileno}
                    name={e.name}
                    hpname={e.hpname}
                    subname={e.subname}
                    listdispatch={listdispatch}
                    initselected={e.flag === "selected"}
                    isvip={e.isvip}
                    setErrorSnackOpen={setErrorSnackOpen}
                    isadmin={e.isadmin}
                    initmarked={e.mark === "selected"}
                    ismark={role == "1"}
                    isbukaiadmin={e.isbukaiadmin}
                  ></ShowMemberDetail>
                );
              })}
        </div>
      </DialogContent>

      <DialogActions>
        {list && list.filter((f) => f.flag === "selected").length > 0 && (
          <>
            {isadduser === false && list && list.filter((f) => f.flag === "selected").length > 1 && iswithme && (
              <Select
                labelId="demo-simple-select-direct-label"
                id="demo-simple-select-direct"
                value={role}
                onChange={handleRoleChange}
              >
                <MenuItem value={"0"}>双方向</MenuItem>
                <MenuItem value={"1"}>一方向</MenuItem>
              </Select>
            )}
            {isadduser === false && list && list.filter((f) => f.flag === "selected").length > 1 && (
              <div className="show-create-room-name">
                <input
                  placeholder="新規部屋名を入力"
                  value={sroomname}
                  name="sroomname"
                  onChange={(e) => {
                    setSroomname(e.target.value);
                  }}
                  //className="show-create-room-field"
                  className={[
                    "show-create-room-field",
                    list.filter((f) => f.flag === "selected").length > 1 && sroomname.length === 0 && "plus-background",
                  ].join(" ")}
                />
              </div>
            )}
            {isadduser ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreate}
                disabled={list.filter((f) => f.flag === "selected").length === 0}
              >
                {`ユーザー追加${
                  list.filter((f) => f.flag === "selected").length > 1
                    ? "(" + list.filter((f) => f.flag === "selected").length + "人)"
                    : ""
                }`}
              </Button>
            ) : iswithme && list.filter((f) => f.flag === "selected").length > 1 ? (
              /* 部屋一覧の新規作成から起動 */
              <StyledFlexDiv>
                {bukais.length > 0 && (
                  <StyledSelect
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={bukaivalue}
                    onChange={handleBukaiChange}
                  >
                    {bukais}
                  </StyledSelect>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreate}
                  disabled={!sroomname || list.filter((f) => f.flag === "selected").length < 2}
                >
                  {`部屋作成${
                    list.filter((f) => f.flag === "selected").length > 1
                      ? "(" + list.filter((f) => f.flag === "selected").length + "人)"
                      : ""
                  }`}
                </Button>
              </StyledFlexDiv>
            ) : (
              <div></div>
            )}
          </>
        )}
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
        <Snackbar
          open={errorsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setErrorSnackOpen(false);
          }}
          message="VIPとはコンタクトできません。"
        ></Snackbar>
        <Snackbar
          open={bukaisnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setBukaiSnackOpen(false);
          }}
          message="通常の部屋は作成できません。"
        ></Snackbar>
      </DialogActions>
    </Dialog>
  );
}
export default ShowMember;
