/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios_post, custom_axios } from "../Common/Common";
import { Store } from "../Store/store";
import TextField from "@mui/material/TextField";
//import {MultiSelect} from "react-multi-select-component";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styled from "@emotion/styled";
import ShowAnpiCreateDetail from "../Common/ShowAnpiCreateDetail";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { MultiSelect } from "react-multi-select-component";
import { Container, Draggable } from "react-smooth-dnd";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const testlist = [
  {
    seq: "1",
    kbn: "radio",
    title: "",
    //su: "2",
    list: [
      { no: "1", text: "" },
      { no: "2", text: "" },
    ],
    required: "0",
  },
];
function ShowNewQueCreate({ open, openAnpiId, handleClose, afterProc, iscopy, isrun, memberList, adminList }) {
  const history = useHistory();
  const { state } = useContext(Store);
  const processingRef = useRef(false);

  //const [options, setOptions] = useState([]);
  //const [selectedhp, setSelectedHp] = useState([]);

  const [AnpiTitle, setAnpiTitle] = useState("");
  const [localList, setLocalList] = useState([]);
  const [ischeck, setIsCheck] = useState(false);

  const [selectedAdmin, setSelectedAdmin] = useState([]);

  const mainproc = async () => {
    if (openAnpiId == null) {
      setLocalList(testlist);
      return;
    }
    const result = await custom_axios(API_URL + `/GetNewQueOne?anpiid=${openAnpiId.anpiid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      var list = result.data.list;
      setLocalList(list);
      setAnpiTitle(openAnpiId.title);
      setIsCheck(openAnpiId.ischeck == "1" ? true : false);
    }
  };

  useEffect(() => {
    if (open) {
      setLocalList([]);
      //setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
      setAnpiTitle("");
      setIsCheck(false);
      /* 修正の場合は、DBに保存されたデータを取得するようにする */
      mainproc();
      setSelectedAdmin(adminList);
    }
  }, [open]);
  const editKbn = (seq, kbn) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          if (e.kbn == "text" && kbn != "text") {
            return { ...e, kbn: kbn, list: [{ set: seq, no: "1", text: "" }] };
          } else {
            return { ...e, kbn: kbn };
          }
        }
        return e;
      })
    );
  };
  const editTitle = (seq, title) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return { ...e, title: title };
        }
        return e;
      })
    );
  };
  const editText = (seq, no, text) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return {
            ...e,
            list: e.list.map((n) => {
              if (n.no == no) {
                return { ...n, text: text };
              }
              return n;
            }),
          };
        }
        return e;
      })
    );
  };
  const editSu = (flag, seq) => {
    if (flag == "plus") {
      setLocalList(
        localList.map((e) => {
          if (e.seq == seq) {
            return { ...e, list: [...e.list, { no: String(e.list.length + 1), text: "" }] };
          }
          return e;
        })
      );
    } else {
      setLocalList(
        localList.map((e) => {
          if (e.seq == seq) {
            return { ...e, list: [...e.list.splice(0, e.list.length - 1)] };
          }
          return e;
        })
      );
    }
  };
  const editSeq = (flag) => {
    if (flag == "plus") {
      setLocalList([
        ...localList,
        {
          seq: String(localList.length + 1),
          kbn: "radio",
          title: "",
          //su: "2",
          list: [
            { no: "1", text: "" },
            { no: "2", text: "" },
          ],
          required: "0",
        },
      ]);
    } else {
      setLocalList([...localList.splice(0, localList.length - 1)]);
    }
  };

  const editSeqRequired = (val, seq) => {
    // console.log("val", val);
    // console.log("seq", seq);
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return { ...e, required: e.required === "1" ? "0" : "1" };
        }
        return e;
      })
    );
  };

  const handleSave = async (isSave, argIsRun) => {
    /* 多重クリック防止 */
    if (processingRef.current) return;
    processingRef.current = true;
    let honbuid = localStorage.getItem("honbuid");

    let seqlist = [];
    let nolist = [];

    // 管理者としてチェックされた項目の本部IDだけ配列に取り出す
    let adminIdList = [];
    if (selectedAdmin.length > 0) {
      for (var ele of selectedAdmin) {
        adminIdList = [...adminIdList, ele.value];
      }
    }
    // 自分の本部IDも入れておく
    adminIdList = [...adminIdList, localStorage.getItem("honbuid")];
    const addadminids = adminIdList.join("_");

    if (argIsRun) {
      // サマリ公開・管理者変更のみを行う
      var summary = ischeck ? "1" : "0";
      var result = await custom_axios(
        API_URL + `/changeRunQue?anpiid=${openAnpiId.anpiid}&summary=${summary}&honbuids=${addadminids}`
      );
      processingRef.current = false;

      afterProc();

      if (result.status === 401) {
        history.replace("/loginError");
      }
      handleClose();
    } else {
      // 一次保存・送信の時
      for (let i = 0; i < localList.length; i++) {
        seqlist.push({
          seq: localList[i].seq,
          title: localList[i].title,
          kbn: localList[i].kbn,
          required: localList[i].required,
        });
        if (localList[i].kbn == "radio") {
          for (let j = 0; j < localList[i].list.length; j++) {
            nolist.push({ seq: localList[i].seq, no: localList[i].list[j].no, text: localList[i].list[j].text });
          }
        } else if (localList[i].kbn == "multi") {
          for (let j = 0; j < localList[i].list.length; j++) {
            nolist.push({ seq: localList[i].seq, no: localList[i].list[j].no, text: localList[i].list[j].text });
          }
        } else if (localList[i].kbn == "text") {
          nolist.push({ seq: localList[i].seq, no: "0", text: "" });
        }
      }

      const body = {
        anpiid: iscopy ? "-1" : openAnpiId == null ? "-1" : openAnpiId.anpiid,
        honbuid: honbuid,
        name: state.user_name,
        title: AnpiTitle,
        roomid: state.currentRoomid,
        seqlist: seqlist, //Json型をそのまま投げる。
        nolist: nolist, //Json型をそのまま投げる。
        //hplist: hplist, //Json型をそのまま投げる。
        istemp: isSave ? "1" : "0", //一時保存できるように追加
        ischeck: ischeck ? "1" : "0", //サマリ表示を制御できるように追加
        addadminids: addadminids, //管理者の本部IDを '_' で繋いだ文字列
      };
      // console.log("post前", body);
      const result = await custom_axios_post(API_URL + "/createNewQue", body);

      processingRef.current = false;

      afterProc();

      if (result.status === 401) {
        history.replace("/loginError");
      }
      handleClose();
    }
  };

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    let tempIsVisible = true;
    if (AnpiTitle.length > 0) {
      for (let i = 0; i < localList.length; i++) {
        if (localList[i].title == "") {
          tempIsVisible = false;
        }
        if (localList[i].kbn == "radio") {
          for (let j = 0; j < localList[i].list.length; j++) {
            if (localList[i].list[j].text == "") {
              tempIsVisible = false;
            }
          }
        }
      }
    } else {
      tempIsVisible = false;
    }
    setIsVisible(tempIsVisible);
  }, [AnpiTitle, localList]);

  // 質問をD&Dで並べ替え
  const onDrop = (dropResult) => {
    // removedIndex: 取り除いたindex
    // addedIndex: 置いたindex
    const { removedIndex, addedIndex } = dropResult;
    setLocalList(reorder(removedIndex, addedIndex));
  };
  // onDropの部品 引数の両indexを元にlocalListを並べ替え、seqも再登録
  const reorder = (startIndex, endIndex) => {
    const result = Array.from(localList);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((row, index) => (row.seq = (index + 1).toString()));
    return result;
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason == "escapeKeyDown") {
          handleClose();
        }
      }}
      maxWidth={"sm"}
      fullWidth
    >
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">アンケート作成</div>
          <div className="question-title-actions">
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="question-create-wrap">
          <TextField
            id="outlined-multiline-static-title"
            label="タイトル"
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={AnpiTitle}
            onChange={(e) => setAnpiTitle(e.target.value)}
            margin="dense"
            disabled={isrun ? true : false}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={ischeck}
                onChange={(e) => {
                  setIsCheck(e.target.checked);
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="途中結果(サマリ)を公開しない"
          />
          <div className="anpi-subarea">
            アンケート管理者の追加・変更
            <div className="multi-select">
              <MultiSelect
                options={memberList}
                value={selectedAdmin}
                onChange={(e) => {
                  // proc(e);
                  // setSelectedUnit([]);
                  // setSelectedHp(e);
                  setSelectedAdmin(e);
                }}
                labelledBy={"Select"}
                overrideStrings={{
                  selectSomeItems: "選択...",
                  allItemsAreSelected: "全選択",
                  selectAll: "全選択",
                  search: "検索",
                  clearSearch: "検索クリア",
                }}
                filterOptions={(options, filter) => {
                  if (!filter) {
                    return options;
                  }
                  const re = new RegExp(filter, "i");
                  return options.filter(({ label }) => label && label.match(re));
                }}
              />
            </div>
          </div>
          {!isrun ? (
            <Container
              dragHandleSelector=".dragHandleSelector" // ドラッグ用コンポーネントのセレクタを指定
              lockAxis="y" // 軸の固定を指定
              onDrop={onDrop} // ドラッグ時に呼ばれる関数、dropResult が渡る
            >
              {localList &&
                localList.map((e, index) => {
                  return (
                    <Draggable key={`DraggableInputContainer-${index}`}>
                      <div className="">
                        <ShowAnpiCreateDetail
                          key={index}
                          listlength={localList.length}
                          anpidetail={e}
                          editTitle={editTitle}
                          editKbn={editKbn}
                          editSu={editSu}
                          editSeq={editSeq}
                          editText={editText}
                          isanpi="0"
                          isrun={isrun}
                          editSeqRequired={editSeqRequired}
                        />
                      </div>
                    </Draggable>
                  );
                })}
            </Container>
          ) : (
            localList &&
            localList.map((e, index) => {
              return (
                <ShowAnpiCreateDetail
                  key={index}
                  listlength={localList.length}
                  anpidetail={e}
                  editTitle={editTitle}
                  editKbn={editKbn}
                  editSu={editSu}
                  editSeq={editSeq}
                  editText={editText}
                  isanpi="0"
                  isrun={isrun}
                  editSeqRequired={editSeqRequired}
                />
              );
            })
          )}
        </div>
      </DialogContent>

      <DialogActions>
        {isrun ? (
          <React.Fragment>
            <Button
              disabled={isVisible ? false : true}
              style={{ margin: "0 auto" }}
              variant="contained"
              color="primary"
              onClick={() => handleSave(false, true)}
              startIcon={<MailOutlineIcon />}
            >
              更新
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Button
              disabled={isVisible ? false : true}
              style={{ margin: "0 auto" }}
              variant="contained"
              color="primary"
              onClick={() => handleSave(true, false)}
              startIcon={<MailOutlineIcon />}
            >
              一時保存
            </Button>
            <Button
              disabled={isVisible ? false : true}
              style={{ margin: "0 auto" }}
              variant="contained"
              color="primary"
              onClick={() => handleSave(false, false)}
              startIcon={<MailOutlineIcon />}
            >
              送信
            </Button>
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ShowNewQueCreate;
