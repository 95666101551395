/* eslint-disable react/prop-types */

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import {ThreeDots as Loader} from "react-loader-spinner";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDiv = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function ShowQrcode({ open, url, handleClose }) {
  const [isCompleted, setIsCompleted] = useState(false);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">インストール用QRコード</StyledDialogTitle>

      <DialogContent>
        <div className="logoff-wrap">
          <img
            width="150"
            height="150"
            style={{ display: isCompleted ? "block" : "none" }}
            src={url}
            alt=""
            className="show-qrcode-image"
            onLoad={() => setIsCompleted(true)}
            //style={isCurrent ? { border: "1px solid white" } : null}
          />
          {isCompleted == false && (
            <StyledDiv>
              <Loader type="ThreeDots" height="100" width="100" />
            </StyledDiv>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowQrcode;
