/* eslint-disable react/prop-types */
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "0567e52d317e6d1e2e27a7cf9c7c0e59Tz05NzczMixFPTE3NTc0OTExNDUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=="
);

function MuiXLicense() {
  return null;
}

export default MuiXLicense;
