/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React from "react";
import { STATIC_URL } from "./Common";
import Dialog from "@mui/material/Dialog";
import "video-react/dist/video-react.css";
import { Player } from "video-react";

function ShowAlumniVideoInfo({ open, messageid, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <div className="show-video">
        <Player src={STATIC_URL + `/downloadAlumniVideo?messageID=${messageid}&size=big`} />
      </div>
    </Dialog>
  );
}
export default ShowAlumniVideoInfo;
