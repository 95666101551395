/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios_post } from "../Common/Common";
import TextField from "@mui/material/TextField";

import styled from "@emotion/styled";

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 30px;
`;

const StyledForm = styled("form")`
  width: "100%";
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

function ShowInitPass({ open, handleClose, setSnackOpen }) {
  const history = useHistory();
  const [honbuid, setHonbuid] = useState();

  const handlesms = async (e) => {
    e.preventDefault();
    // const body = {
    //   honbuid: honbuid,
    // };
    const body = new FormData();
    body.append("honbuid", honbuid);
    const result = await custom_axios_post(API_URL + "/sendsmsinitpass", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }

    if (result.status === 200) {
      setSnackOpen({ open: true, text: "送信しました。再度送信する場合は時間をおいてからお試しください。" });
    } else {
      setSnackOpen({
        open: true,
        text: "既に送信済か存在しません。既に送信済の場合は時間をおいてからお試しください。",
      });
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <DialogTitle id="max-width-dialog-title">
        <div className="show-member-search">パスワード再発行</div>
      </DialogTitle>

      <StyledDialogContent>
        <StyledForm onSubmit={handlesms}>
          <div>アカウントに紐付いている電話番号(※1)へパスワード再発行のSMSを送信します。</div>
          <div>(※1)アカウント認証時に使用した電話番号</div>
          <div>パスワードを再発行するアカウントの本部IDを入力してください。</div>

          <StyledTextField
            label="本部ID"
            variant="outlined"
            value={honbuid}
            required
            type="number"
            onChange={(e) => {
              setHonbuid(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <Button
            //disabled={isVisible ? false : true}
            variant="contained"
            color="primary"
            type="submit"
            //onClick={handleClick}
            //startIcon={<SaveOutlinedIcon />}
          >
            SMS送信
          </Button>
        </StyledForm>
      </StyledDialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowInitPass;
