/* eslint-disable no-case-declarations */
const reducer = (state = {}, action) => {
  //console.log("action.type", action.type);
  //console.log("action.payload", action.payload);
  switch (action.type) {
    case "RECEIVE_CALL": {
      return {
        ...state,
        callroomid: action.callroomid,
        callname: action.name,
      };
    }
    case "CHANGE_ISMINIROOM": {
      return {
        ...state,
        isMiniRoom: action.isMiniRoom,
      };
    }
    case "CHANGE_PARENTROOMID": {
      return {
        ...state,
        parentroomid: action.parentroomid,
      };
    }
    case "RECEIVE_GOOD": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          for (let m = 0; m < state.chatrooms[j].conversationlist.length; m++) {
            if (action.payload.messageid == state.chatrooms[j].conversationlist[m].messageid) {
              if (action.payload.message == "on") {
                state.chatrooms[j].conversationlist[m].goodcnt =
                  Number(state.chatrooms[j].conversationlist[m].goodcnt) + 1;
              } else {
                state.chatrooms[j].conversationlist[m].goodcnt =
                  Number(state.chatrooms[j].conversationlist[m].goodcnt) - 1;
              }
              m = state.chatrooms[j].conversationlist.length;
            }
          }
        }
      }
      return {
        ...state,
      };
    }
    case "RECEIVE_GOOD_EMOJI": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          for (let m = 0; m < state.chatrooms[j].conversationlist.length; m++) {
            if (action.payload.messageid == state.chatrooms[j].conversationlist[m].messageid) {
              switch (action.payload.emojikbn) {
                case "emoji1":
                  if (state.honbuid == action.payload.honbuid) {
                    state.chatrooms[j].conversationlist[m].emojiselected1 = action.payload.message == "on" ? "1" : "0";
                  }

                  if (action.payload.message == "on") {
                    state.chatrooms[j].conversationlist[m].emojicnt1 =
                      Number(state.chatrooms[j].conversationlist[m].emojicnt1) + 1;
                  } else {
                    var cnt1 = Number(state.chatrooms[j].conversationlist[m].emojicnt1);
                    state.chatrooms[j].conversationlist[m].emojicnt1 = cnt1 <= 0 ? 0 : cnt1 - 1;
                  }
                  break;
                case "emoji2":
                  if (state.honbuid == action.payload.honbuid) {
                    state.chatrooms[j].conversationlist[m].emojiselected2 = action.payload.message == "on" ? "1" : "0";
                  }

                  if (action.payload.message == "on") {
                    state.chatrooms[j].conversationlist[m].emojicnt2 =
                      Number(state.chatrooms[j].conversationlist[m].emojicnt2) + 1;
                  } else {
                    var cnt2 = Number(state.chatrooms[j].conversationlist[m].emojicnt2);
                    state.chatrooms[j].conversationlist[m].emojicnt2 = cnt2 <= 0 ? 0 : cnt2 - 1;
                  }
                  break;
                case "emoji3":
                  if (state.honbuid == action.payload.honbuid) {
                    state.chatrooms[j].conversationlist[m].emojiselected3 = action.payload.message == "on" ? "1" : "0";
                  }

                  if (action.payload.message == "on") {
                    state.chatrooms[j].conversationlist[m].emojicnt3 =
                      Number(state.chatrooms[j].conversationlist[m].emojicnt3) + 1;
                  } else {
                    var cnt3 = Number(state.chatrooms[j].conversationlist[m].emojicnt3);
                    state.chatrooms[j].conversationlist[m].emojicnt3 = cnt3 <= 0 ? 0 : cnt3 - 1;
                  }
                  break;
                case "emoji4":
                  if (state.honbuid == action.payload.honbuid) {
                    state.chatrooms[j].conversationlist[m].emojiselected4 = action.payload.message == "on" ? "1" : "0";
                  }

                  if (action.payload.message == "on") {
                    state.chatrooms[j].conversationlist[m].emojicnt4 =
                      Number(state.chatrooms[j].conversationlist[m].emojicnt4) + 1;
                  } else {
                    var cnt4 = Number(state.chatrooms[j].conversationlist[m].emojicnt4);
                    state.chatrooms[j].conversationlist[m].emojicnt4 = cnt4 <= 0 ? 0 : cnt4 - 1;
                  }
                  break;
                case "emoji5":
                  if (state.honbuid == action.payload.honbuid) {
                    state.chatrooms[j].conversationlist[m].emojiselected5 = action.payload.message == "on" ? "1" : "0";
                  }

                  if (action.payload.message == "on") {
                    state.chatrooms[j].conversationlist[m].emojicnt5 =
                      Number(state.chatrooms[j].conversationlist[m].emojicnt5) + 1;
                  } else {
                    var cnt5 = Number(state.chatrooms[j].conversationlist[m].emojicnt5);
                    state.chatrooms[j].conversationlist[m].emojicnt5 = cnt5 <= 0 ? 0 : cnt5 - 1;
                  }
                  break;
                default:
              }
              m = state.chatrooms[j].conversationlist.length;
            }
          }
        }
      }
      return {
        ...state,
      };
    }
    case "RECEIVE_DELMESSAGE": {
      console.log("RECEIVE_DELMESSAGE", action.payload.messageid);

      for (let j = 0; j < state.chatrooms.length; j++) {
        //if (state.currentRoomid == state.chatrooms[j].roomid) {
        for (let m = 0; m < state.chatrooms[j].conversationlist.length; m++) {
          if (action.payload.messageid == state.chatrooms[j].conversationlist[m].messageid) {
            if (state.chatrooms[j].conversationlist[m].unreaded === "1") {
              state.chatrooms[j].unreadcnt = Number(state.chatrooms[j].unreadcnt) - 1;
            }

            const deletedlist = state.chatrooms[j].conversationlist.filter(
              (conv) => conv.messageid != action.payload.messageid
            );
            state.chatrooms[j].conversationlist = deletedlist;
            state.chatrooms[j].latestmessage = deletedlist[deletedlist.length - 1].message;
            state.chatrooms[j].latesttime = deletedlist[deletedlist.length - 1].time;
          }
        }
        //}
      }
      return {
        ...state,
        addConversationCnt: state.addConversationCnt + 1,
      };
    }
    case "RECEIVE_DELMESSAGES": {
      // console.log("RECEIVE_DELMESSAGE", action.payload.messageid);

      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          const resetlist = state.chatrooms[j].conversationlist.filter((conv) => conv.messagetype == "information");
          if (resetlist.length > 0) {
            state.chatrooms[j].conversationlist = resetlist;
            state.chatrooms[j].latestmessage = resetlist[0].message;
            state.chatrooms[j].latesttime = resetlist[0].time;
          }
        }
      }
      return {
        ...state,
        addConversationCnt: state.addConversationCnt + 1,
      };
    }
    case "RECEIVE_ANPI": {
      return {
        ...state,
        addAnpiCnt: state.addAnpiCnt + 1,
      };
    }
    case "CHANGE_NOTICE": {
      return {
        ...state,
        isnotice: action.payload,
      };
    }
    case "HIDE_ROOM": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          state.chatrooms[j].ishide = "1";
        }
      }
      return {
        ...state,
        currentRoomid: "",
        chatsFilter: "",
        filteredMessages: [],
      };
    }
    case "CHANGE_HIDE": {
      let tmpIsHide = "";
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          if (state.chatrooms[j].ishide == "1") {
            state.chatrooms[j].ishide = "0";
            state.chatrooms[j].ispush = "1";
          } else {
            state.chatrooms[j].ishide = "1";
            state.chatrooms[j].ispush = "0";
          }
          // state.chatrooms[j].ishide = state.chatrooms[j].ishide == "1" ? "0" : "1";
          tmpIsHide = state.chatrooms[j].ishide;
        }
      }
      // hide後にどの画面を見せるか 非表示にした時は親部屋に移動
      const transitionRoomid =
        tmpIsHide === "0" ? state.currentRoomid : state.isMiniRoom === true ? state.parentroomid : "";
      return {
        ...state,
        currentRoomid: transitionRoomid,
        chatsFilter: "",
        filteredMessages: [],
      };
    }
    case "CHANGE_NOTIFICATION": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload == state.chatrooms[j].roomid) {
          state.chatrooms[j].ispush = state.chatrooms[j].ispush == "1" ? "0" : "1";
        }
      }
      return {
        ...state,
      };
    }
    case "CHANGE_STAR": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload == state.chatrooms[j].roomid) {
          state.chatrooms[j].isstar = state.chatrooms[j].isstar == "1" ? "0" : "1";
        }
      }
      return {
        ...state,
      };
    }
    case "RECEIVE_ROOMNAME": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          state.chatrooms[j].roomname = action.payload.message;
        }
      }
      return {
        ...state,
      };
    }

    case "RECEIVE_ROOMAVATAR": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (state.currentRoomid == state.chatrooms[j].roomid) {
          state.chatrooms[j].fileno = action.payload.fileno;
        }
      }
      return {
        ...state,
      };
    }
    case "RECEIVE_VOTEQUESTION": {
      if (state.honbuid == action.payload.honbuid) {
        for (let j = 0; j < state.chatrooms.length; j++) {
          if (state.currentRoomid == state.chatrooms[j].roomid) {
            /* とりあえず、0以上やったらマイナスにしとく。。 */
            if (Number(state.chatrooms[j].unvotecnt) > 0) {
              state.chatrooms[j].unvotecnt = Number(state.chatrooms[j].unvotecnt) - 1;
            }
          }
        }
      }
      return {
        ...state,
      };
    }
    case "LOCAL_READMESSAGE": {
      const chatRooms = state.chatrooms;
      for (let j = 0; j < chatRooms.length; j++) {
        // 現在のルームを特定
        if (state.currentRoomid == chatRooms[j].roomid) {
          const conversationList = chatRooms[j].conversationlist;
          // 全メッセージをループ
          for (let m = 0; m < conversationList.length; m++) {
            const message = conversationList[m];
            // unreaded == 1 : 未読のものを特定→既読にする
            if (message.unreaded == "1") {
              message.unreaded = "0";
              message.readedcnt = Number(message.readedcnt) + 1;
            }
          }

          var cnt = conversationList.filter((v) => v.unreaded == "1").length;
          chatRooms[j].unreadcnt = cnt;
        }
      }

      return {
        ...state,
      };
    }
    case "RECEIVE_EDITMESSAGE": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (state.currentRoomid == state.chatrooms[j].roomid) {
          for (let m = 0; m < state.chatrooms[j].conversationlist.length; m++) {
            if (state.chatrooms[j].conversationlist[m].messageid == action.payload.messageid) {
              state.chatrooms[j].conversationlist[m].edited = "1";
              state.chatrooms[j].conversationlist[m].message = action.payload.message;
            }
          }
        }
      }
      return {
        ...state,
      };
    }
    case "RECEIVE_READMESSAGE": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          for (let m = 0; m < state.chatrooms[j].memberlist.length; m++) {
            if (state.chatrooms[j].memberlist[m].honbuid === action.payload.honbuid) {
              state.chatrooms[j].memberlist[m].timenum = action.payload.timenum;
            }
          }

          for (let m = 0; m < state.chatrooms[j].conversationlist.length; m++) {
            var count = state.chatrooms[j].memberlist.filter(
              (v) =>
                Number(v.timenum) >= Number(state.chatrooms[j].conversationlist[m].timenum) &&
                v.honbuid != state.chatrooms[j].conversationlist[m].honbuid
            ).length;
            if (state.chatrooms[j].conversationlist[m].honbuid != state.honbuid) {
              count++;
            }
            state.chatrooms[j].conversationlist[m].readedcnt = count;
          }
        }
      }
      return {
        ...state,
      };
    }
    case "UPDATE_READMESSAGE": {
      const chatRooms = state.chatrooms;
      //console.log("UPDATE_READMESSAGE state.currentRoomid", state.currentRoomid);
      //console.log("UPDATE_READMESSAGE action.payload", action.payload);
      for (let j = 0; j < chatRooms.length; j++) {
        if (state.currentRoomid == chatRooms[j].roomid) {
          const currentRoomMemberList = chatRooms[j].memberlist;
          for (let m = 0; m < currentRoomMemberList.length; m++) {
            for (let k = 0; k < action.payload.timelist.length; k++) {
              if (currentRoomMemberList[m].honbuid === action.payload.timelist[k].honbuid) {
                currentRoomMemberList[m].timenum = action.payload.timelist[k].timenum;
                //console.log("UPDATE_READMESSAGE honbuid", currentRoomMemberList[m].timenum);
              }
            }
          }

          for (let m = 0; m < chatRooms[j].conversationlist.length; m++) {
            var readcount = currentRoomMemberList.filter(
              (v) =>
                Number(v.timenum) >= Number(chatRooms[j].conversationlist[m].timenum) &&
                v.honbuid != chatRooms[j].conversationlist[m].honbuid
            ).length;
            if (chatRooms[j].conversationlist[m].honbuid != state.honbuid) {
              readcount++;
            }
            chatRooms[j].conversationlist[m].readedcnt = readcount;
          }
        }
      }

      return {
        ...state,
      };
    }
    case "RECEIVE_MESSAGE": {
      var e_messagetype = action.payload.messagetype;
      if (action.payload.messagetype == "createquestion") {
        for (let j = 0; j < state.chatrooms.length; j++) {
          if (action.payload.roomid == state.chatrooms[j].roomid) {
            state.chatrooms[j].unvotecnt = Number(state.chatrooms[j].unvotecnt) + 1;
          }
        }
        e_messagetype = "question";
      }

      var localmessage = {
        roomid: action.payload.roomid,
        messageid: action.payload.messageid,
        honbuid: action.payload.honbuid,
        name: action.payload.name,
        fileno: action.payload.fileno,
        isavatar: "0",
        message: action.payload.message,
        messagetype: e_messagetype,
        unreaded: state.honbuid == action.payload.honbuid ? "0" : "1",
        readedcnt: "0",
        time: action.payload.time,
        timenum: action.payload.timenum,
        gooded: "0",
        goodcnt: "0",
        emojicnt1: "0",
        emojiselected1: "0",
        emojicnt2: "0",
        emojiselected2: "0",
        emojicnt3: "0",
        emojiselected3: "0",
        emojicnt4: "0",
        emojiselected4: "0",
        emojicnt5: "0",
        emojiselected5: "0",
      };
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          state.chatrooms[j].ishide = "0";
          state.chatrooms[j].latesttime = action.payload.time;
          state.chatrooms[j].latestsending = action.payload.timenum;
          state.chatrooms[j].latestmessage = action.payload.message;
          if (action.payload.honbuid != state.honbuid) {
            state.chatrooms[j].unreadcnt = Number(state.chatrooms[j].unreadcnt) + 1;
          }

          state.chatrooms[j].conversationlist = [
            localmessage,
            ...state.chatrooms[j].conversationlist.filter((f) => f.messageid != localmessage.messageid),
          ];
          //console.log("RECEIVE_MESSAGE LIST", state.chatrooms[j].conversationlist);
        }
      }

      state.chatrooms.sort(function (a, b) {
        // if (a.isstar < b.isstar) {
        //   return 1;
        // }
        // if (a.isstar > b.isstar) {
        //   return -1;
        // }
        if (a.latestsending < b.latestsending) {
          return 1;
        }
        if (a.latestsending > b.latestsending) {
          return -1;
        }
        return 0;
      });

      if (action.payload.callroomid != "") {
        return {
          callroomid: action.payload.callroomid,
          callname: action.payload.callname,
          callhonbuid: action.payload.callhonbuid,
          ...state,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case "READ_PROCESSING": {
      return {
        ...state,
      };
    }

    case "RECEIVE_COMMENTQUESTION": {
      return {
        ...state,
      };
    }

    case "INACTIVE": {
      return {
        ...state,
        isActive: action.payload,
      };
    }
    case "ACTIVE": {
      return {
        ...state,
        isActive: action.payload,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        honbuid: action.payload,
      };
    }
    case "LOGOUT": {
      return {
        isActive: true,
        honbuid: "",
        currentRoomid: "",
        chatrooms: [],
        chatsFilter: "",
        filteredMessages: [],
      };
    }
    case "CHANGE_ACCOUNT": {
      return {
        ...state,
        user_name: action.payload.name,
        //subname: action.payload.subname,
        phone: action.payload.phone,
        email: action.payload.email,
        color: action.payload.color,
      };
    }
    case "CHANGE_PHOTO": {
      return {
        ...state,
        fileno: action.payload,
      };
    }
    case "ADD_STAMP": {
      return {
        ...state,
        stamps: [{ stampid: action.payload }, ...state.stamps],
      };
    }
    case "INIT_CONVERSASTION": {
      localStorage.setItem("color", action.payload.color);
      return {
        ...state,
        ...action.payload,
      };
    }
    case "PRE_UPDATE_CONVERSASTION": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (state.currentRoomid == state.chatrooms[j].roomid) {
          state.chatrooms[j].conversationlist = [action.payload, ...state.chatrooms[j].conversationlist];
        }
      }
      return {
        ...state,
      };
    }
    case "UPDATE_CONVERSASTION": {
      const rooms = action.payload;
      //fetchData(action.payload).then((rooms) => {
      //console.log("UPDATE_CONVERSASTION", rooms);

      for (let i = 0; i < rooms.chatrooms.length; i++) {
        let newflg = true;
        for (let j = 0; j < state.chatrooms.length; j++) {
          if (rooms.chatrooms[i].roomid == state.chatrooms[j].roomid) {
            newflg = false;
            //console.log("rooms.chatrooms[i]", rooms.chatrooms[i]);

            if (rooms.chatrooms[i].conversationlist.length > 0) {
              const lastid =
                rooms.chatrooms[i].conversationlist[rooms.chatrooms[i].conversationlist.length - 1].messageid;
              const fromlist = state.chatrooms[j].conversationlist.filter(
                (conv) => Number(conv.messageid) < Number(lastid) && conv.messageid != 0
              ); //messageid==0は、ローカルで一時的に追加したやつので削除する

              if (fromlist.length > 0) {
                rooms.chatrooms[i].conversationlist = [...rooms.chatrooms[i].conversationlist, ...fromlist];
              }
              state.chatrooms[j] = rooms.chatrooms[i];
            }
          }
        }

        if (newflg) {
          state.chatrooms = [...state.chatrooms, rooms.chatrooms[i]];
        }
      }
      state.chatrooms.sort(function (a, b) {
        if (a.isstar < b.isstar) {
          return 1;
        }
        if (a.isstar > b.isstar) {
          return -1;
        }
        if (a.latestsending < b.latestsending) {
          return 1;
        }
        if (a.latestsending > b.latestsending) {
          return -1;
        }
        return 0;
      });
      //});
      //console.log("UPDATE_CONVERSASTION", state);
      return { ...state };
    }
    case "ADD_CONVERSASTION": {
      if (action.payload[0].roomid == state.currentRoomid) {
        for (let j = 0; j < state.chatrooms.length; j++) {
          if (state.currentRoomid == state.chatrooms[j].roomid) {
            state.chatrooms[j].conversationlist = [...action.payload, ...state.chatrooms[j].conversationlist];
          }
        }
      }
      return {
        ...state,
        addConversationCnt: state.addConversationCnt + 1,
      };
    }
    case "SET_CONVERSASTION": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (state.currentRoomid == state.chatrooms[j].roomid) {
          state.chatrooms[j].conversationlist = [...action.payload];
        }
      }
      return {
        ...state,
      };
    }
    case "CHANGE_ROOM": {
      if (action.payload.from != "") {
        for (let j = 0; j < state.chatrooms.length; j++) {
          if (action.payload.from == state.chatrooms[j].roomid) {
            state.chatrooms[j].conversationlist = state.chatrooms[j].conversationlist.slice(-10);
          }
        }
      }
      return {
        ...state,
        currentRoomid: action.payload.to,
        chatsFilter: "",
        filteredMessages: [],
      };
    }
    case "CHANGE_CHATFILTER": {
      return {
        ...state,
        chatsFilter: action.payload.chatsFilter,
        filteredMessages: action.payload.messages,
      };
    }
    case "CHANGE_MESSAGES": {
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (state.currentRoomid == state.chatrooms[j].roomid) {
          //console.log("元：", state.chatrooms[j].conversationlist);
          //console.log("CHANGE_MESSAGES", action.payload);
          state.chatrooms[j].conversationlist = [...action.payload.conversationlist];
        }
      }
      return {
        ...state,
        chatsFilter: action.payload.chatsFilter,
        filteredMessages: action.payload.messages,
      };
    }
    case "DEL_STAMP": {
      for (let j = 0; j < state.stamps.length; j++) {
        if (action.payload == state.stamps[j].stampid) {
          state.stamps.splice(j, 1);
        }
      }
      return {
        ...state,
      };
    }
    case "DEL_ROOM": {
      // 退出する部屋に小部屋がある場合は、先に小部屋を退出してから親を削除
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (
          state.chatrooms[j].roomid !== state.chatrooms[j].parentroomid &&
          action.payload == state.chatrooms[j].parentroomid
        ) {
          state.chatrooms.splice(j, 1);
        }
      }

      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload == state.chatrooms[j].roomid) {
          state.chatrooms.splice(j, 1);
        }
      }
      return {
        ...state,
      };
    }
    /*case "DEL_CONVERSATION":
      for (let j = 0; j < state.chatrooms.length; j++) {
        if (action.payload.roomid == state.chatrooms[j].roomid) {
          state.chatrooms[j].conversationlist = state.chatrooms[j].conversationlist.filter(
            (s) => s.messageid != action.payload.messageid
          );
        }
      }
      return {
        ...state,
      };*/
    default: {
      return state;
    }
  }
};

export default reducer;
