/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import Linkify from "react-linkify";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios } from "./Common";
import styled from "@emotion/styled";

const MessageDiv = styled("div")`
  white-space: pre-line;
`;

const StyledBef = styled("div")`
  //margin-top: 10;
  margin-bottom: 5px;
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledAft = styled("div")`
  margin-top: 10px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
function ShowGetEditMessage({ open, handleClose, messageid }) {
  const history = useHistory();
  const [value, setValue] = React.useState({ bef: "", aft: "" });

  useEffect(() => {
    const proc = async () => {
      if (open) {
        const result = await custom_axios(API_URL + `/GetEditMessage?messageid=${messageid}`);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        }
        if (status === 200) {
          setValue({ bef: result.data.bef, aft: result.data.aft });
        }
      }
    };
    proc();
  }, [open, messageid]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">修正内容</div>
      </StyledDialogTitle>

      <DialogContent>
        <div>
          <StyledBef>修正前</StyledBef>
          <MessageDiv>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target={`blank${messageid}`} href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {value.bef}
            </Linkify>
          </MessageDiv>
          <StyledAft>修正後</StyledAft>
          <MessageDiv>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target={`blank${messageid}`} href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {value.aft}
            </Linkify>
          </MessageDiv>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowGetEditMessage;
