/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios_post, custom_axios } from "../Common/Common";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styled from "@emotion/styled";
import ShowAnpiVoteDetail from "../Common/ShowAnpiVoteDetail";
import ShowAnpiChartMini from "../Common/ShowAnpiChartMini";
import Divider from "@mui/material/Divider";
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDivider = styled(Divider)`
  margin-bottom: 5px;
  margin-top: 5px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  margin-left: 10px;
`;

function ShowAnpiVote({ open, honbuid, openAnpiId, handleClose, afterProc, roomid, isEnabled, isadmin, isanpi }) {
  const history = useHistory();
  const processingRef = useRef(false);
  const anpidRef = useRef(0);
  const [openChartObj, setOpenChartObj] = React.useState({
    anpiid: "",
    isopen: false,
  });
  const handleAnpiChartClose = () => {
    setOpenChartObj({ ...openChartObj, isopen: false });
  };
  const handleOpenChart = () => {
    setOpenChartObj({ ...openChartObj, isopen: true });
  };
  const MemoAnpiChartMini = useMemo(() => {
    return <ShowAnpiChartMini openChartObj={openChartObj} handleClose={handleAnpiChartClose} />;
  }, [openChartObj.isopen]);

  const [localList, setLocalList] = useState([]);
  const [memo, setMemo] = useState("");
  const mainproc = async () => {
    if (open) {
      var sqlstring = "";
      if (roomid == null) {
        sqlstring = API_URL + `/GetAnpiValues?honbuid=${honbuid}&anpiid=${openAnpiId}`;
      } else {
        sqlstring = API_URL + `/GetAnpiValuesWithRoom?honbuid=${honbuid}&roomid=${roomid}`;
      }
      const result = await custom_axios(sqlstring);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        var seqlist = result.data.seqlist;
        var nolist = result.data.nolist;
        var memo = result.data.memo;
        var localanpi = "";
        for (let i = 0; i < seqlist.length; i++) {
          localanpi = seqlist[i].anpiid;
          seqlist[i].list = [];
          for (let j = 0; j < nolist.length; j++) {
            if (seqlist[i].seq == nolist[j].seq) {
              if (seqlist[i].kbn == "radio") {
                seqlist[i].list.push(nolist[j]);
              } else {
                seqlist[i].value = nolist[j].value;
              }
            }
          }
        }
        setOpenChartObj({ ...openChartObj, anpiid: localanpi });
        //console.log("seqlist", seqlist);
        setMemo(memo);
        setLocalList(seqlist);

        anpidRef.current = seqlist[0].anpiid;
      }
    }
  };
  useEffect(() => {
    if (open) {
      mainproc();
    }
  }, [open]);

  const inputNoValue = (seq, no, value) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return {
            ...e,
            list: e.list.map((n) => {
              if (n.no == no) {
                return { ...n, value: value == "1" ? "0" : "1" };
              }
              return { ...n, value: "0" };
            }),
          };
        }
        return e;
      })
    );
  };
  const inputTextValue = (seq, value) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return {
            ...e,
            value: value,
          };
        }
        return e;
      })
    );
  };
  const inputMemoValue = (value) => {
    setMemo(value);
  };
  const handleSave = async () => {
    /* 多重クリック防止 */
    if (processingRef.current) return;
    processingRef.current = true;

    let seqlist = [];

    for (let i = 0; i < localList.length; i++) {
      //seqlist.push({ seq: localList[i].seq, title: localList[i].title, kbn: localList[i].kbn });
      if (localList[i].kbn == "radio") {
        for (let j = 0; j < localList[i].list.length; j++) {
          if (localList[i].list[j].value == "1") {
            seqlist.push({ seq: localList[i].seq, no: localList[i].list[j].no, value: "1" });
          }
        }
      } else {
        if (localList[i].value != "") {
          seqlist.push({ seq: localList[i].seq, no: "0", value: localList[i].value });
        }
      }
    }
    const body = {
      anpiid: anpidRef.current,
      honbuid: honbuid,
      seqlist: seqlist, //Json型をそのまま投げる。
      memo: memo,
    };

    //console.log("post前", body);
    const result = await custom_axios_post(API_URL + "/voteAnpi", body);

    processingRef.current = false;

    afterProc(seqlist, memo);

    if (result.status === 401) {
      history.replace("/loginError");
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">安否回答</div>
          <div className="question-title-actions">
            <StyledButton onClick={handleOpenChart} variant="outlined">
              サマリ表示
            </StyledButton>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="question-create-wrap">
          {MemoAnpiChartMini}
          {(["1", "2"].includes(isadmin) || isanpi == "1") && (
            <>
              <ShowAnpiVoteDetail
                anpidetail={{ title: "メモ欄", kbn: "memo", value: memo }}
                // inputNoValue={}
                inputTextValue={inputMemoValue}
              />
              <StyledDivider />
            </>
          )}

          {localList &&
            localList.map((e, index) => {
              return (
                <ShowAnpiVoteDetail
                  key={index}
                  anpidetail={e}
                  inputNoValue={inputNoValue}
                  inputTextValue={inputTextValue}
                />
              );
            })}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          style={{ margin: "0 auto" }}
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          startIcon={<MailOutlineIcon />}
          disabled={isEnabled ? false : true}
        >
          送信
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowAnpiVote;
