/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios_post, custom_axios } from "../Common/Common";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import styled from "@emotion/styled";
// eslint-disable-next-line no-unused-vars
import ShowAnpiVoteDetail from "../Common/ShowAnpiVoteDetail";
import ShowNewQueChartMini from "../Common/ShowNewQueChartMini";
import Snackbar from "@mui/material/Snackbar";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  margin-left: 10px;
`;

const StyledFlexibleDiv = styled("div")`
  text-align: center;
  font-size: medium;
`;

function ShowNewQueVote({ open, honbuid, openAnpiId, handleClose, afterProc, messageid }) {
  const history = useHistory();
  const processingRef = useRef(false);
  const anpidRef = useRef(0);

  const [localList, setLocalList] = useState([]);
  const [seqValidationList, setSeqValidationList] = useState([]);

  const mainproc = async () => {
    if (open) {
      var sqlstring = "";
      if (messageid == null) {
        sqlstring = API_URL + `/GetNewQueValues?honbuid=${honbuid}&questionid=${openAnpiId}`;
      } else {
        sqlstring = API_URL + `/GetNewQueValuesWithId?honbuid=${honbuid}&messageid=${messageid}`;
      }
      const result = await custom_axios(sqlstring);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        var seqlist = result.data.seqlist;
        var nolist = result.data.nolist;
        // 必須回答Validation関係 seqを取ってきたら、同じlengthのbool配列を作成
        let tmpSeqValidationList = [...Array(seqlist.length)].map(() => false);
        for (let i = 0; i < seqlist.length; i++) {
          seqlist[i].list = [];
          for (let j = 0; j < nolist.length; j++) {
            if (seqlist[i].seq == nolist[j].seq) {
              if (seqlist[i].kbn != "text") {
                seqlist[i].list.push(nolist[j]);
              } else {
                seqlist[i].value = nolist[j].value;
              }
            }
          }
          // 各項目入力確認
          if (seqlist[i].required == "1") {
            // 必須なら入力内容に関わらず一旦false
            tmpSeqValidationList[i] = false;
            // すでに回答済みの場合に備えて必須Validationを検証
            if (seqlist[i].kbn != "text") {
              // 選択肢の場合は、1つでも選択されていたらtrue
              for (var ele of seqlist[i].list) {
                if (ele.value === "1") {
                  tmpSeqValidationList[i] = true;
                }
              }
            } else {
              // フリー入力の場合も何かしら入力されていたらtrue
              tmpSeqValidationList[i] = seqlist[i].value !== "" ? true : false;
            }
          } else {
            // 任意項目の場合は無条件true
            tmpSeqValidationList[i] = true;
          }
        }
        setSeqValidationList(tmpSeqValidationList);
        setOpenChartObj({ ...openChartObj, anpiid: seqlist[0].anpiid });
        // console.log("seqlist", seqlist);
        setLocalList(seqlist);
        anpidRef.current = seqlist[0].anpiid;
      } else {
        setLocalList([]);
      }
    }
  };
  useEffect(() => {
    mainproc();
  }, [open]);

  // eslint-disable-next-line no-unused-vars
  const inputNoValue = (seq, no, value) => {
    const retLocalList = localList.map((e) => {
      if (e.seq == seq) {
        return {
          ...e,
          list: e.list.map((n) => {
            if (n.no == no) {
              return { ...n, value: value == "1" ? "0" : "1" };
            }
            /* multi対応 */
            if (e.kbn == "radio") {
              return { ...n, value: "0" };
            } else {
              return { ...n };
            }
          }),
        };
      }
      return e;
    });
    setLocalList(retLocalList);
    // 選択肢のValidationチェック
    let targetSeq = {};
    let targetIndex;
    for (let i = 0; i < localList.length; i++) {
      if (localList[i].seq === seq) {
        targetSeq = localList[i];
        targetIndex = i;
      }
    }
    if (targetSeq.required === "1") {
      const targetList = retLocalList[targetIndex].list;
      let retValue = false;
      for (var ele of targetList) {
        if (ele.value === "1") {
          retValue = true;
        }
      }
      setSeqValidationList((prevState) => prevState.map((val, index) => (index === targetIndex ? retValue : val)));
    }
  };
  // eslint-disable-next-line no-unused-vars
  const inputTextValue = (seq, value) => {
    setLocalList(
      localList.map((e) => {
        if (e.seq == seq) {
          return {
            ...e,
            value: value,
          };
        }
        return e;
      })
    );
    // フリー入力のvalidationチェック
    let targetSeq = {};
    let targetIndex;
    for (let i = 0; i < localList.length; i++) {
      if (localList[i].seq === seq) {
        targetSeq = localList[i];
        targetIndex = i;
      }
    }
    if (targetSeq.required === "1") {
      let retValue = value.length === 0 ? false : true;
      setSeqValidationList((prevState) => prevState.map((val, index) => (index === targetIndex ? retValue : val)));
    }
  };

  const handleSave = async () => {
    /* 多重クリック防止 */
    if (processingRef.current) return;
    processingRef.current = true;

    let seqlist = [];

    for (let i = 0; i < localList.length; i++) {
      //seqlist.push({ seq: localList[i].seq, title: localList[i].title, kbn: localList[i].kbn });
      if (localList[i].kbn == "radio") {
        for (let j = 0; j < localList[i].list.length; j++) {
          if (localList[i].list[j].value == "1") {
            seqlist.push({ seq: localList[i].seq, no: localList[i].list[j].no, value: "1" });
          }
        }
      } else if (localList[i].kbn == "multi") {
        for (let j = 0; j < localList[i].list.length; j++) {
          if (localList[i].list[j].value == "1") {
            seqlist.push({ seq: localList[i].seq, no: localList[i].list[j].no, value: "1" });
          }
        }
      } else {
        if (localList[i].value != "") {
          seqlist.push({ seq: localList[i].seq, no: "0", value: localList[i].value });
        }
      }
    }

    const body = {
      anpiid: anpidRef.current,
      honbuid: honbuid,
      seqlist: seqlist, //Json型をそのまま投げる。
    };

    // console.log("post前", body);
    const result = await custom_axios_post(API_URL + "/voteNewQue", body);

    processingRef.current = false;

    afterProc(seqlist);

    if (result.status === 401) {
      history.replace("/loginError");
    }
    handleClose();
  };
  const [openChartObj, setOpenChartObj] = React.useState({
    anpiid: "",
    isopen: false,
  });
  const handleAnpiChartClose = () => {
    setOpenChartObj({ ...openChartObj, isopen: false });
  };
  const handleOpenChart = async () => {
    const result = await custom_axios(API_URL + `/GetNewQueSummary?questionid=${openChartObj.anpiid}`);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    } else if (status === 200) {
      setOpenChartObj({ ...openChartObj, isopen: true });
    } else {
      setSnackOpen(true);
    }
  };
  const MemoNewQueChartMini = useMemo(() => {
    return <ShowNewQueChartMini openChartObj={openChartObj} handleClose={handleAnpiChartClose} />;
  }, [openChartObj.isopen]);
  const [snackopen, setSnackOpen] = useState(false);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">アンケート回答</div>
          <div className="question-title-actions">
            <StyledButton onClick={handleOpenChart} variant="outlined">
              サマリ表示
            </StyledButton>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div className="question-create-wrap">
          {MemoNewQueChartMini}
          {localList.length > 0 ? (
            localList.map((e, index) => {
              //console.log("anpidetail", e);
              return (
                <ShowAnpiVoteDetail
                  key={index}
                  anpidetail={e}
                  inputNoValue={inputNoValue}
                  inputTextValue={inputTextValue}
                />
              );
            })
          ) : (
            <StyledFlexibleDiv>アンケートが終了しました。</StyledFlexibleDiv>
          )}
        </div>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="サマリ表示がOFFになっています。"
        ></Snackbar>
      </DialogContent>

      <DialogActions>
        {localList.length > 0 && (
          <Button
            style={{ margin: "0 auto" }}
            disabled={seqValidationList.every((ele) => ele === true) ? false : true}
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            startIcon={<MailOutlineIcon />}
          >
            送信
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ShowNewQueVote;
