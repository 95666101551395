/* eslint-disable react/prop-types */
import React from "react";
import { STATIC_URL } from "../Common/Common";
import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const StyledWrapper = styled("div")`
  display: flex;
`;
const StyledButtonWrapper = styled("div")`
  margin-left: 5px;
  flex-grow: 0;
`;
const StyledMemberWrapper = styled("div")`
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary.light : "#f7f8fc")};

  cursor: pointer;
  -webkittransition: width 0.3s;
  transition: width 0.3s;
  position: relative;
  z-index: 0;
  & .member-text-wrapper {
    font-weight: ${(props) => (props.bigfont ? "700" : "#normal")};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    font-weight: 700;
    & .member-text-wrapper {
      font-weight: 700;
    }
    & .member-image {
      border: 1px solid white;
    }
  }
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledFlexibleDiv = styled("div")`
  display: flex;
`;
function ShowMemberDetail({
  honbuid,
  fileno,
  name,
  //hpname,
  subname,
  listdispatch,
  initselected,
  isvip,
  //setErrorSnackOpen,
  isadmin,
  initmarked,
  ismark,
  isbukaiadmin,
}) {
  return (
    <StyledWrapper>
      <StyledMemberWrapper
        key={honbuid}
        bigfont={initselected}
        //className={initselected ? classes.memberWrapperSelected : classes.memberWrapper}
        onClick={() => {
          // if (isvip == "1") {
          //   setErrorSnackOpen(true);
          //   return;
          // }
          listdispatch({ type: "CHANGE", payload: honbuid });
          //setSelected((pre) => setSelected(!pre));
        }}
      >
        <div className="member-content">
          <img
            src={STATIC_URL + `/downloadAvatar?honbuid=${honbuid}&fileno=${fileno}&size=small`}
            width="30"
            height="30"
            alt=""
            className="member-image"
            //style={isCurrent ? { border: "1px solid white" } : null}
          />
          <div className="member-text-vip-wrapper">
            <StyledFlexibleDiv>
              {isvip == "1" && <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>}
              {isbukaiadmin == "1" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="部会管理"></StyledChip>
              ) : isbukaiadmin == "2" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="部会部屋"></StyledChip>
              ) : (
                <div></div>
              )}
              {isadmin == "1" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="システム管理"></StyledChip>
              ) : isadmin == "2" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="施設管理"></StyledChip>
              ) : isadmin == "3" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="部屋管理"></StyledChip>
              ) : isadmin == "6" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="施設横断部屋"></StyledChip>
              ) : isadmin == "4" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="ユーザー管理"></StyledChip>
              ) : isadmin == "5" ? (
                <StyledChip variant="outlined" color="primary" size="small" label="部屋・ユーザー管理"></StyledChip>
              ) : (
                <div></div>
              )}
            </StyledFlexibleDiv>
            <div className="member-search-name-wrapper">
              <div className="">{name}</div>

              <div className="member-search-subname">({subname})</div>
            </div>
            {/* <div className="member-subname">{hpname}</div> */}
          </div>
        </div>
      </StyledMemberWrapper>
      {ismark && initselected && (
        <StyledButtonWrapper>
          <FormControlLabel
            control={
              <Checkbox
                checked={initmarked}
                onChange={() => {
                  listdispatch({ type: "MARK", payload: honbuid });
                }}
                name="checkedA"
              />
            }
            label="発言"
          />
        </StyledButtonWrapper>
      )}
    </StyledWrapper>
  );
}

export default ShowMemberDetail;
