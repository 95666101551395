/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios, custom_axios_post } from "./Common";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import ShowCommon from "./ShowCommon";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
  overflow-y: hidden;
`;
const StyledFormControl = styled(FormControl)`
  margin: ${(props) => props.theme.spacing(1) + "px"};
  width: 200px;
`;

function ShowUnitName({ open, honbuid, hpcode, hpname, unitcode, unitname, handleClose, callBack }) {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [unitoptions, setUnitOptions] = useState([]);

  const [selectedhp, setSelectedHp] = useState(hpcode + ":" + hpname);
  const [selectedunit, setSelectedUnit] = useState(unitcode + ":" + unitname);

  const [hpopen, setHpOpen] = useState(false);
  const [unitopen, setUnitOpen] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleSave = async () => {
    handleModalClose();

    const body = {
      hpcode: selectedhp.split(":")[0],
      honbuid: honbuid,
      unitcode: selectedunit.split(":")[0],
    };
    const result = await custom_axios_post(API_URL + "/moveHpUnit", body);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
    if (result.status === 200) {
      setSelectedUnit([]);
      callBack();
      handleClose(); /* 自身も閉じる */
    }
  };
  const hpchange = async (localhpcode) => {
    const result = await custom_axios(API_URL + `/GetOneUnitSelect?hpcodes=${localhpcode.split(":")[0]}&hascode=1`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    } else if (status === 200) {
      setUnitOptions(
        result.data.units.map((e) => {
          return (
            <MenuItem key={e.unitcode} value={e.unitname}>
              {e.unitname}
            </MenuItem>
          );
        })
      );
      setSelectedUnit([]);
    }
  };
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    //let ret = false;
    let okCnt = 0;

    if (selectedhp != hpcode + ":" + hpname) {
      okCnt++;
    }
    if (selectedhp == hpcode + ":" + hpname && selectedunit != unitcode + ":" + unitname) {
      okCnt++;
    }
    if (selectedhp != "" && selectedunit != "") {
      okCnt++;
    }

    setIsVisible(okCnt == 2);
  }, [selectedhp, selectedunit]);

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      let honbuid = localStorage.getItem("honbuid");
      //console.log("GetMultiSelect");
      if (open) {
        const result = await custom_axios(API_URL + `/GetMultiSelect?honbuid=${honbuid}&hpcodes=${hpcode}&hascode=1`);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        } else if (status === 200) {
          setOptions(
            result.data.hps.map((e) => {
              return (
                <MenuItem key={e.hpcode} value={e.hpname}>
                  {e.hpname}
                </MenuItem>
              );
            })
          );
          setUnitOptions(
            result.data.units.map((e) => {
              return (
                <MenuItem key={e.unitcode} value={e.unitname}>
                  {e.unitname}
                </MenuItem>
              );
            })
          );
          setSelectedHp(hpcode + ":" + hpname);
          setSelectedUnit(unitcode + ":" + unitname);
        }
      }
    };
    proc();
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">部署変更</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <div className="question-create-wrap">
          <StyledFormControl required>
            <InputLabel>施設</InputLabel>
            <Select
              error={selectedhp.length == 0}
              open={hpopen}
              onClose={() => {
                setHpOpen(false);
              }}
              onOpen={() => {
                setHpOpen(true);
              }}
              value={selectedhp}
              onChange={(e) => {
                //console.log("e.target.value", e.target.value);
                setSelectedHp(e.target.value);
                hpchange(e.target.value);
              }}
            >
              {options}
            </Select>
          </StyledFormControl>

          <StyledFormControl required>
            <InputLabel>部署</InputLabel>
            <Select
              error={selectedunit.length == 0}
              open={unitopen}
              onClose={() => {
                setUnitOpen(false);
              }}
              onOpen={() => {
                setUnitOpen(true);
              }}
              value={selectedunit}
              onChange={(e) => {
                //console.log("e.target.value", e.target.value);
                setSelectedUnit(e.target.value);
              }}
            >
              {unitoptions}
            </Select>
          </StyledFormControl>
        </div>
        <ShowCommon
          open={openModal}
          title="変更しますか？"
          handleClose={handleModalClose}
          handleCallBack={handleSave}
        ></ShowCommon>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          startIcon={<SaveOutlinedIcon />}
        >
          変更
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowUnitName;
