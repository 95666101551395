/* eslint-disable react/prop-types */
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Login.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import axios from "axios";
import { API_URL } from "../Common/Common";
import TextField from "@mui/material/TextField";
import styled from "@emotion/styled";
// import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
const StyledDivPaper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const StyledForm = styled("form")`
  width: "100%";
  margin-top: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;
function LoginInitPhone() {
  const history = useHistory();
  const honbuid = localStorage.getItem("honbuid"); //props.match.params;
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = async () => {
    let urlstr = API_URL + `/changeAccount?honbuid=${honbuid}&type=password&text=${pass1}`;
    await axios(urlstr);
    history.replace("/initpass");
  };

  useEffect(() => {
    let ret = false;
    if (ret && pass1.length == 0 && pass2.length == 0) {
      ret = true;
    } else {
      ret = false;
      if (pass1 && pass1.length > 7 && pass2 && pass2.length > 7) {
        if (pass1 == pass2) {
          ret = true;
        }
      }
    }

    setIsVisible(ret);
  }, [pass1, pass2]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isVisible) {
      await handleClick();
    }
  };
  return (
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <StyledDivPaper>
        <div>
          <StyledForm onSubmit={handleSubmit}>
            <div className="login-initpass-div">
              <h1>パスワード変更のお願い</h1>
              <div>セキュリティ向上のため パスワードの変更をお願いします。</div>
              <div>パスワードは8桁以上で設定お願いします。変更後、サービスが利用できます。</div>

              <TextField
                label="新パスワード"
                type="password"
                autoFocus
                required
                id="pass1"
                name="pass1"
                variant="outlined"
                defaultValue=""
                helperText={pass1 != "" && pass1.length < 8 ? "パスワードは8桁入力してください" : ""}
                onChange={(e) => {
                  setPass1(e.target.value);
                }}
                style={{ margin: "8px", maxWidth: "400px", marginTop: "10px" }}
                // inputProps={{
                //   autoComplete: "new-password",
                //   form: {
                //     autoComplete: "off",
                //   },
                // }}
              />
              <TextField
                label="新パスワード(再入力)"
                type="password"
                required
                id="pass2"
                name="pass2"
                variant="outlined"
                defaultValue=""
                helperText={pass1 != "" && pass2 != "" && pass1 != pass2 ? "1つ目のパスワードと異なります。" : ""}
                onChange={(e) => {
                  setPass2(e.target.value);
                }}
                style={{ margin: "8px", maxWidth: "400px" }}
                // inputProps={{
                //   autoComplete: "new-password",
                //   form: {
                //     autoComplete: "off",
                //   },
                // }}
              />
            </div>
            <Button
              //disabled={isVisible ? false : true}
              variant="contained"
              color="primary"
              type="submit"
              //onClick={handleClick}
              //startIcon={<SaveOutlinedIcon />}
            >
              変更してサービス開始
            </Button>
          </StyledForm>
        </div>
      </StyledDivPaper>
    </Container>
  );
}
export default LoginInitPhone;
