/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import ShowRoomAccountPhotoAdmin from "../Common/ShowRoomAccountPhotoAdmin";
import TextField from "@mui/material/TextField";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

function ShowRoomAccountAdmin({ open, handleClose, filenochange, namechange }) {
  const history = useHistory();
  const [name, setName] = useState();

  const handleSave = async () => {
    let urlstr = API_URL + `/changeRoomName?roomid=${open.id}&roomname=${name}`;

    const result = await custom_axios(urlstr);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    namechange(open.id, name);
    handleClose();
  };
  const [isVisible, setIsVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  // const [changecnt, setChangeCnt] = useState(0);
  useEffect(() => {
    let ret = false;
    if (name && name.length > 0 && name != open.name) {
      ret = true;
    }

    setIsVisible(ret);
  }, [name]);

  const MemoPhoto = useMemo(() => {
    return (
      <ShowRoomAccountPhotoAdmin
        open={modalOpen}
        id={open.id}
        handleClose={handleModalClose}
        callback={() => {
          // setChangeCnt(changecnt + 1);
          filenochange(open.id);
        }}
      ></ShowRoomAccountPhotoAdmin>
    );
  }, [modalOpen]);

  return (
    <Dialog open={open.open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">部屋変更</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoPhoto}
        <div className="question-create-wrap">
          <div className="show-user-wrap">
            <div className="show-user-image-back">
              <img
                src={STATIC_URL + `/downloadRoomAvatar?roomid=${open.id}&fileno=${open.fileno}`}
                alt=""
                className="show-user-image-back-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
            </div>

            <div className="show-user-image-wraper">
              <img
                src={STATIC_URL + `/downloadRoomAvatar?roomid=${open.id}&fileno=${open.fileno}`}
                width="150"
                height="150"
                alt=""
                className="show-user-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
            </div>

            <div className="show-user-information-camera">
              <IconButton style={{ color: "white" }} onClick={() => setModalOpen(true)}>
                <CameraAltIcon></CameraAltIcon>
              </IconButton>
            </div>
          </div>

          <TextField
            label="RoomID"
            variant="outlined"
            defaultValue={open.id}
            disabled
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <TextField
            label="ApiKey"
            variant="outlined"
            defaultValue={open.apikey}
            disabled
            style={{ margin: "8px", minWidth: "300px" }}
          />
          <TextField
            label="名前"
            variant="outlined"
            defaultValue={open.name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
        </div>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveOutlinedIcon />}
        >
          変更
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowRoomAccountAdmin;
