/* eslint-disable react/prop-types */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowCommonPlus({ open, title, handleClose, handleCallBackOn, handleCallBackOff }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">{title}</StyledDialogTitle>

      <DialogContent>
        <div className="logoff-wrap">
          <Button onClick={handleCallBackOn} style={{ margin: "5px" }} variant="contained" color="primary">
            一括ONする
          </Button>
          <Button onClick={handleCallBackOff} style={{ margin: "5px" }} variant="contained" color="primary">
            一括OFFする
          </Button>
          <Button onClick={handleClose} style={{ margin: "5px" }} variant="contained">
            キャンセル
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ShowCommonPlus;
