/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import ShowAnpiUserViewRow from "../Common/ShowAnpiUserViewRow";
import styled from "@emotion/styled";
import { API_URL, custom_axios } from "../Common/Common";
import Button from "@mui/material/Button";
import {ThreeDots as Loader} from "react-loader-spinner";
// import ShowCommon from "../Common/ShowCommon";
import Typography from "@mui/material/Typography";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import purple from "@mui/material/colors/purple";
import green from "@mui/material/colors/green";
import teal from "@mui/material/colors/teal";
import indigo from "@mui/material/colors/indigo";
import blue from "@mui/material/colors/blue";
import deepPurple from "@mui/material/colors/deepPurple";
import pink from "@mui/material/colors/pink";
import red from "@mui/material/colors/red";
import deepOrange from "@mui/material/colors/deepOrange";
import orange from "@mui/material/colors/orange";
import TextField from "@mui/material/TextField";
// import ShowAnpiUserViewMini from "../Common/ShowAnpiUserViewMini";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledDiv = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledChartMain = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const StyledCenter = styled("div")`
  text-align: center;
`;
const TopDiv = styled("div")`
  margin-top: 5px;
`;
const StyledChartSub = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;
const StyledJustifyAlignCenter = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  min-width: 300px;
`;

const StyledAltButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.primary.contrastText};
`;

// eslint-disable-next-line
function ShowNewQueChartMini({ openChartObj, handleClose }) {
  const [votesumarry, setVoteSumarry] = useState([]);
  const [textsumarry, setTextSumarry] = useState([]);
  const [textgroup, setTextGroup] = useState([]);
  const [isload, setIsLoad] = useState(false);
  const mainproc = async () => {
    setIsLoad(true);
    // var apistring = API_URL + `/GetAllQuestionChartMini?anpiid=${openChartObj.anpiid}`;
    var apistring = API_URL + `/GetAllQuestionChartMiniNew?anpiid=${openChartObj.anpiid}`;

    const result = await custom_axios(apistring);
    const status = result.status;
    if (status === 200) {
      setVoteSumarry(result.data.votesumarry);
      setTextSumarry(result.data.textsumarry);
      var textgroup = result.data.textsumarry.reduce(function (result, current) {
        var element = result.find(function (p) {
          return p.seq === current.seq;
        });
        if (element) {
          element.count++;
        } else {
          result.push({
            seq: current.seq,
            title: current.title,
            count: 1,
            kbn: current.kbn,
          });
        }
        return result;
      }, []);
      setTextGroup(textgroup);
    }
    setIsLoad(false);
  };

  useEffect(() => {
    if (openChartObj.isopen) {
      mainproc();
    } else {
      setVoteSumarry([]);
      setTextSumarry([]);
    }
  }, [openChartObj.isopen]);

  const TOPCOLORS = [blue[300], pink[300]];
  const COLORS = [
    indigo[300],
    green[300],
    teal[300],
    blue[300],
    deepPurple[300],
    pink[300],
    red[300],
    purple[300],
    deepOrange[300],
    orange[300],
  ];

  const [showFreeDialogOpen, setShowFreeDialogOpen] = useState(false);
  const [freeEle, setFreeEle] = useState([]);
  const handleOpenShowFreeDialog = (argTextFilter) => {
    setFreeEle(argTextFilter);
    console.log("freeEle", argTextFilter);
    setShowFreeDialogOpen(true);
  };
  const handleCloseShowFreeDialog = () => {
    setShowFreeDialogOpen(false);
    setFreeEle([]);
  };

  return (
    <Dialog open={openChartObj.isopen} onClose={handleClose} maxWidth={"xl"} fullWidth>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div>全体</div>
        </div>
      </StyledDialogTitle>

      <DialogContent>
        <div>
          {/* {MemoAnpiUserViewMini} */}
          {isload == false ? (
            <StyledChartMain>
              <StyledCenter>
                <TopDiv>
                  <Typography variant="h5">
                    {"報告状況 (" +
                      votesumarry.reduce(function (sum, element) {
                        return sum + element.cnt;
                      }, 0) +
                      "人)"}
                  </Typography>
                </TopDiv>

                <PieChart width={600} height={300}>
                  <Tooltip></Tooltip>
                  <Pie
                    data={votesumarry}
                    startAngle={-270}
                    isAnimationActive={false}
                    // onClick={onPieMainClick}
                    dataKey="cnt"
                    nameKey="vote"
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    label={({ vote, cnt }) => `${vote}:${cnt}人`}
                  >
                    {votesumarry.map((entry, index) => (
                      <Cell key={index} fill={entry.vote == "済" ? TOPCOLORS[0] : TOPCOLORS[1]} />
                    ))}
                  </Pie>
                </PieChart>
              </StyledCenter>
              <StyledChartSub>
                {textgroup.map((e) => {
                  var textfilter = textsumarry.filter((f) => e.seq == f.seq);
                  return (
                    <StyledCenter key={e.seq}>
                      <div>
                        {e.title +
                          " (" +
                          textfilter.reduce(function (sum, element) {
                            return sum + element.cnt;
                          }, 0) +
                          "件)"}
                      </div>
                      {e.kbn === "radio" || e.kbn === "multi" ? (
                        <PieChart width={300} height={200}>
                          <Tooltip></Tooltip>
                          <Pie
                            data={textfilter}
                            startAngle={-270}
                            isAnimationActive={false}
                            //   onClick={onPieClick}
                            dataKey="cnt"
                            nameKey="text"
                            cx="50%"
                            cy="50%"
                            outerRadius={50}
                            label={({ text, cnt }) => `${text}:${cnt}件`}
                          >
                            {textfilter.map((entry, index) => (
                              <Cell key={index} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                        </PieChart>
                      ) : (
                        <StyledJustifyAlignCenter>
                          <StyledAltButton onClick={() => handleOpenShowFreeDialog(textfilter)} variant="outlined">
                            内容表示
                          </StyledAltButton>
                        </StyledJustifyAlignCenter>
                      )}
                    </StyledCenter>
                  );
                })}
              </StyledChartSub>
            </StyledChartMain>
          ) : (
            <StyledDiv>
              <Loader type="ThreeDots" height="100" width="100" />
            </StyledDiv>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
      <Dialog open={showFreeDialogOpen} onClose={handleCloseShowFreeDialog} maxWidth={"xs"} fullWidth>
        <StyledDialogTitle>回答内容表示</StyledDialogTitle>
        <StyledDialogContent>
          {freeEle.map((e) => {
            return (
              <React.Fragment key={`${e.seq}-${e.value}`}>
                {/* <FreeEleDiv>{e.value}</FreeEleDiv> */}
                <TextField
                  defaultValue={e.value}
                  fullWidth
                  multiline
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </React.Fragment>
            );
          })}
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={handleCloseShowFreeDialog} variant="outlined" color="primary">
            閉じる (Esc)
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
}

export default ShowNewQueChartMini;
