/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
// eslint-disable-next-line no-unused-vars
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
const StyledRoot = styled("div")`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
`;
const StyledMemoRoot = styled("div")`
  /* border: 1px solid; */
  border-radius: 5px;
  /* padding: 5px; */
  margin-top: 5px;
  margin-bottom: 10px;
`;
const StyledButtonDiv = styled("div")`
  margin-top: 8px;
  margin-bottom: 8px;
`;
const StyledMemo = styled("div")`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;
const StyledTopMarginDiv = styled("div")`
  margin-top: 8px;
`;

const StyledRequiredB = styled("b")`
  font-size: 12px;
  color: red;
  padding-bottom: 3px;
  margin-left: 7px;
`;

// eslint-disable-next-line no-unused-vars
function ShowAnpiVoteDetail({ anpidetail, inputNoValue, inputTextValue }) {
  useEffect(() => {
    //console.log("anpidetail2", anpidetail);
  }, []);

  return (
    <StyledTopMarginDiv>
      <Typography variant="h6" gutterBottom>
        {anpidetail.title == "メモ欄" ? (
          <StyledMemo>
            <CreateOutlinedIcon></CreateOutlinedIcon>
            <div>{anpidetail.title}</div>
            <div>(管理者にしか表示されません)</div>
          </StyledMemo>
        ) : (
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div>{anpidetail.title}</div>
            {anpidetail.required === "1" && <StyledRequiredB>＊必須項目</StyledRequiredB>}
          </div>
        )}
      </Typography>

      {anpidetail.kbn == "radio" || anpidetail.kbn == "multi" ? (
        <StyledRoot>
          {anpidetail.list.map((e, index) => {
            return (
              <StyledButtonDiv key={index}>
                <Button
                  variant="contained"
                  onClick={() => {
                    inputNoValue(anpidetail.seq, e.no, e.value);
                  }}
                  fullWidth
                  color={e.value == "1" ? "primary" : "default"}
                >
                  {e.text}
                </Button>
              </StyledButtonDiv>
            );
          })}
        </StyledRoot>
      ) : anpidetail.kbn == "text" ? (
        <StyledRoot>
          <TextField
            id={`outlined-multiline-static-${anpidetail.seq}`}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            minRows={3}
            variant="outlined"
            value={anpidetail.value}
            onChange={(ev) => inputTextValue(anpidetail.seq, ev.target.value)}
            margin="dense"
            style={{ width: "100%" }}
          />
        </StyledRoot>
      ) : (
        <StyledMemoRoot>
          <TextField
            id={`outlined-multiline-static-${anpidetail.seq}`}
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            minRows={3}
            variant="outlined"
            value={anpidetail.value}
            onChange={(ev) => inputTextValue(ev.target.value)}
            margin="dense"
            style={{ width: "100%" }}
          />
        </StyledMemoRoot>
      )}
    </StyledTopMarginDiv>
  );
}
export default ShowAnpiVoteDetail;
