/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, custom_axios } from "../Common/Common";
import TextField from "@mui/material/TextField";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

function ShowUserEmail({ open, honbuid, handleClose, callBack }) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [orgemail, setOrgEmail] = useState("");

  const proc = async () => {
    let strurl = API_URL + `/getEmail?honbuid=${honbuid}`;
    const result = await custom_axios(strurl);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      if (open) {
        setEmail(result.data.email);
        setOrgEmail(result.data.email);
      }
    }
  };
  useEffect(() => {
    if (open) {
      proc();
    }
  }, [open]);

  const handleSave = async () => {
    let urlstr = API_URL + `/changeEmail?honbuid=${honbuid}&email=${email}`;

    const result = await custom_axios(urlstr);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    setEmail("");
    callBack(honbuid, email);
    handleClose();
  };
  const [isVisible, setIsVisible] = useState(false);

  // const [changecnt, setChangeCnt] = useState(0);
  useEffect(() => {
    let pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
    let ret = false;
    if (pattern.test(email) || email == "") {
      if (orgemail != email) {
        ret = true;
      }
    }

    setIsVisible(ret);
  }, [email]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">Email変更</div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <div className="question-create-wrap">
          <TextField
            label="Email"
            variant="outlined"
            defaultValue={email}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={{ margin: "8px", minWidth: "300px" }}
          />
        </div>
      </StyledDialogContent>

      <DialogActions>
        <Button
          disabled={isVisible ? false : true}
          variant="contained"
          color="primary"
          onClick={handleSave}
          startIcon={<SaveOutlinedIcon />}
        >
          変更
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowUserEmail;
