/* eslint-disable react/prop-types */
import React from "react";
import { STATIC_URL } from "../Common/Common";
import styled from "@emotion/styled";
//import ShowAnpiVote from "./ShowAnpiVote";

const StyledWrapper = styled("div")`
  display: flex;
`;

const StyledMemberWrapper = styled("div")`
  flex-grow: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary.light : "#f7f8fc")};

  //cursor: pointer;
  -webkittransition: width 0.3s;
  transition: width 0.3s;
  position: relative;
  z-index: 0;
  & .member-text-wrapper {
    font-weight: ${(props) => (props.bigfont ? "700" : "#normal")};
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    font-weight: 700;
    & .member-text-wrapper {
      font-weight: 700;
    }
    & .member-image {
      border: 1px solid white;
    }
  }
`;

function ShowAnpiUserViewRow({ honbuid, name, fileno, setOpenVoteObj }) {
  return (
    <StyledWrapper>
      <StyledMemberWrapper
        key={honbuid}
        //bigfont={initselected}
        //className={initselected ? classes.memberWrapperSelected : classes.memberWrapper}
        onClick={() => {
          setOpenVoteObj({ honbuid: honbuid, isopen: true });
        }}
      >
        <div className="member-content">
          <img
            src={STATIC_URL + `/downloadAvatar?honbuid=${honbuid}&fileno=${fileno}&size=small`}
            width="30"
            height="30"
            alt=""
            className="member-image"
            //style={isCurrent ? { border: "1px solid white" } : null}
          />

          <div className="">{name}</div>
        </div>
      </StyledMemberWrapper>
    </StyledWrapper>
  );
}

export default ShowAnpiUserViewRow;
