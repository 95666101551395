/* eslint-disable react/prop-types */
import React from "react";
import { STATIC_URL } from "../Common/Common";
import "./MessageList.css";

import DescriptionIcon from "@mui/icons-material/Description";

function FileAlumniMessage({ conversation, sent, isReplySource }) {
  const srcID = isReplySource ? conversation.replyid : conversation.messageid;

  return (
    <>
      {isReplySource ? (
        <div className="file-download-wraper">
          <DescriptionIcon />
          <div className="file-download-wraper-message">{conversation.replymessage}</div>
        </div>
      ) : (
        <a
          className={`file-download file-download-${sent ? "sent" : "received"}`}
          // onClick={() => {
          //   setDocurl({ url: STATIC_URL + `/downloadfile?messageID=${srcID}`, open: true });
          // }}
          href={STATIC_URL + `/downloadAlumnifile?messageID=${srcID}`}
          download="test"
        >
          <div className="file-download-wraper">
            <DescriptionIcon />
            <div className="file-download-wraper-message">{conversation.message}</div>
          </div>
        </a>
      )}
    </>
  );
}

export default FileAlumniMessage;
