/* eslint-disable react/prop-types */
import React from "react";
import { STATIC_URL } from "../Common/Common";
import "./MessageList.css";
import TextMessage from "./TextMessage";
import VideoMessage from "./VideoMessage";
import PhotoMessage from "./PhotoMessage";
import FileMessage from "./FileMessage";
import StampMessage from "./StampMessage";

import Tooltip from "@mui/material/Tooltip";

function ReplyMessage({ conversation, setOpenReplyid, setOpenReply, sent }) {
  return (
    <>
      <div>
        <Tooltip title="返信元メッセージ">
          <div
            className="chat-area-reply-jump"
            onClick={() => {
              setOpenReplyid(conversation.replyid);
              setOpenReply(true);
            }}
          >
            <div className="chat-area-reply-wrap">
              <img
                src={
                  STATIC_URL +
                  `/downloadAvatar?honbuid=${conversation.replyhonbuid}&fileno=${conversation.replyfileno}&size=small`
                }
                width="30"
                height="30"
                alt=""
                className="chat-reply-avatar-image"
                //style={isCurrent ? { border: "1px solid white" } : null}
              />
              <div className="chat-area-reply-name">{conversation.replyname}</div>
            </div>
            <div className="chat-area-reply-top">
              {conversation.replymessagetype === "photo" ? (
                <PhotoMessage
                  conversation={conversation}
                  handleImgClickOpen={() => {}}
                  isReplySource={true}
                ></PhotoMessage>
              ) : conversation.replymessagetype === "video" ? (
                <VideoMessage
                  conversation={conversation}
                  handleVideoClickOpen={() => {}}
                  isReplySource={true}
                ></VideoMessage>
              ) : conversation.replymessagetype === "stamp" ? (
                <StampMessage conversation={conversation} isReplySource={true}></StampMessage>
              ) : conversation.replymessagetype === "file" ? (
                <FileMessage conversation={conversation} sent={sent} isReplySource={true}></FileMessage>
              ) : (
                <TextMessage conversation={conversation} type="replymessage"></TextMessage>
              )}
            </div>
          </div>
        </Tooltip>

        <div className="chat-area-reply-bot">
          <TextMessage conversation={conversation} type="message"></TextMessage>
        </div>
      </div>
    </>
  );
}

export default ReplyMessage;
