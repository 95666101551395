/* eslint-disable react/prop-types */
import React from "react";
import "./MessageList.css";
import StampMessage from "./StampMessage";
import TextMessage from "./TextMessage";
import VideoReservedMessage from "./VideoReservedMessage";
import PhotoReservedMessage from "./PhotoReservedMessage";
import FileReservedMessage from "./FileReservedMessage";

//import ShowCommon from "../Common/ShowCommon";
import styled from "@emotion/styled";
import { css } from "@mui/material";
//import animationData from "./39679-star-burst.json";
import "video-react/dist/video-react.css"; // import css
//import { RootRef } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  cursor: default;
  &:hover {
    cursor: pointer;
  }
`;
const StyledLeftRight = styled("div")`
  ${(props) =>
    props.leftmessage
      ? css`
          border-style: solid;
          border-color: #c7c7d3;
          border-width: 3px;
          color: #000000;
          box-shadow: grey -1px 7px 13px -4px;
          &::before {
            content: "";
            border: 10px solid transparent;
            border-right-color: #c7c7d3;
            position: absolute;
            left: -20px;
          }
          &::after {
            content: "";
            border: 10px solid transparent;
            /* border-right-color: rgb(247, 248, 252); */
            border-right-color: white;
            position: absolute;
            top: 38px;
            left: -17px;
          }
        `
      : (props) => css`
          border-style: solid;
          border-color: ${props.theme.palette.primary.main};
          border-width: 3px;
          color: #000000;
          box-shadow: grey -1px 7px 13px -4px;

          &::before {
            content: "";
            border: 10px solid transparent;
            border-left-color: ${props.theme.palette.primary.main};
            position: absolute;
            top: 13px;
            right: -20px;
          }
          &::after {
            content: "";
            border: 10px solid transparent;
            /* border-left-color: rgb(247, 248, 252); */
            border-left-color: white;
            position: absolute;
            top: 13px;
            right: -17px;
          }
        `}
`;

function ReservedMessage({
  conversation,
  setInitMessageid,
  setImgOpen,
  setVideoOpen,
  handleMenuView,
  isreadonly = false,
}) {
  //const me = localStorage.getItem("honbuid");
  const sent = true;
  ////console.log("Message sent", sent);
  ////console.log("conversation", conversation);

  //const { hubConnection, dispatch } = useContext(Store);
  const handleVideoClickOpen = () => {
    setInitMessageid(conversation.messageid);
    setVideoOpen(true);
  };

  const handleImgClickOpen = () => {
    setInitMessageid(conversation.messageid);
    setImgOpen(true);
  };
  return (
    <>
      {conversation.messagetype === "information" ? (
        <div className="message-information-wrapper">
          <div className="message-information-wrapper-child">{conversation.message}</div>
        </div>
      ) : (
        <div className={`message-in-avatar-${sent ? "sent" : "received"}`}>
          {/* {conversation.messageid == "0" && <div>送信中...</div>} */}

          <div className={`message-options-wrapper-${sent ? "sent" : "received"}`}>
            {!sent && <div>{conversation.name}</div>}

            {conversation.messagetype === "stamp" ? (
              <StampMessage conversation={conversation} isReplySource={false}></StampMessage>
            ) : (
              <StyledLeftRight leftmessage={!sent} className={`message`}>
                {conversation.messagetype === "message" ? (
                  <TextMessage conversation={conversation} type="message"></TextMessage>
                ) : conversation.messagetype === "video" ? (
                  <VideoReservedMessage
                    conversation={conversation}
                    handleVideoClickOpen={handleVideoClickOpen}
                    isReplySource={false}
                  ></VideoReservedMessage>
                ) : conversation.messagetype === "photo" ? (
                  <PhotoReservedMessage
                    conversation={conversation}
                    handleImgClickOpen={handleImgClickOpen}
                    isReplySource={false}
                  ></PhotoReservedMessage>
                ) : conversation.messagetype === "file" ? (
                  <FileReservedMessage
                    conversation={conversation}
                    sent={sent}
                    isReplySource={false}
                    // setDocurl={setDocurl}
                  ></FileReservedMessage>
                ) : (
                  <div>{conversation.message}</div>
                )}
              </StyledLeftRight>
            )}
            {isreadonly ? (
              <div></div>
            ) : (
              <div className={`message-options-time-${sent ? "sent" : "received"}`}>
                <div>
                  <StyledExpandMoreIcon
                    //className={classes.pointicon}
                    onClick={(e) => {
                      setInitMessageid(conversation.messageid);
                      handleMenuView(e);
                    }}
                  ></StyledExpandMoreIcon>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default ReservedMessage;
