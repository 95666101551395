/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
function ShowAdminSelect({ ownvalue, initvalue, callBack, honbuid, setUpperSnackOpen }) {
  const [role, setRole] = React.useState("0");

  /* 横断部屋は、システム管理者のみが付与できる */
  const handleChange = (event) => {
    /* 管理者じゃない場合（部会管理） */
    if (ownvalue == "0") {
      setUpperSnackOpen(true);
      return;
    }

    /* ユーザー権限の場合 */
    if (ownvalue == "4" && ["1", "2", "3", "5", "6"].includes(event.target.value)) {
      setUpperSnackOpen(true);
      return;
    }
    /* 部屋・ユーザー権限の場合 */
    if (ownvalue == "5" && ["1", "2", "6"].includes(event.target.value)) {
      setUpperSnackOpen(true);
      return;
    }
    /* 施設管理権限の場合 */
    if (ownvalue == "2" && ["1", "6"].includes(event.target.value)) {
      setUpperSnackOpen(true);
      return;
    }
    setRole(event.target.value);
    callBack(honbuid, event.target.value);
  };
  useEffect(() => {
    setRole(initvalue);
  }, [initvalue]);
  return (
    <FormControl size="small">
      <Select labelId="demo-simple-select-label" id="demo-simple-select" value={role} onChange={handleChange}>
        <MenuItem value={"0"}>-</MenuItem>
        <MenuItem value={"1"}>システム管理者</MenuItem>
        <MenuItem value={"2"}>施設管理者</MenuItem>
        <MenuItem value={"6"}>施設横断部屋</MenuItem>
        <MenuItem value={"3"}>部屋</MenuItem>
        <MenuItem value={"4"}>ユーザー</MenuItem>
        <MenuItem value={"5"}>部屋・ユーザー</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ShowAdminSelect;
