/* eslint-disable react/prop-types */
import React, { useContext, useState, useEffect, useRef, useMemo } from "react";
import "./Input.css";
import { Store } from "../Store/store";
import IconButton from "@mui/material/IconButton";
import Send from "@mui/icons-material/Send";
import AttachFile from "@mui/icons-material/AttachFile";
import Face from "@mui/icons-material/Face";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ClearIcon from "@mui/icons-material/Clear";
import SettingsIcon from "@mui/icons-material/Settings";
import styled from "@emotion/styled";
import { useDropzone } from "react-dropzone";
import { STAMP_URL, API_URL, STATIC_URL, custom_axios, custom_axios_post, unescapeHTML } from "../Common/Common";
import { useHistory } from "react-router-dom";
import ShowPlusQuestionList from "../Common/ShowPlusQuestionList";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ShowCommon from "../Common/ShowCommon";
// import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
// import { createTheme } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ContentEditable from "react-contenteditable";
import Tooltip from "@mui/material/Tooltip";
import ShowStampPhoto from "../Common/ShowStampPhoto";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";

const filetype = ["image/gif", "image/jpeg", "image/png", "image/bmp"];
const videofiletype = ["video/mp4"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.light};
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
const StyledBotTitleDiv = styled("div")`
  font-weight: bold;
  font-size: 19px;
`;
const StyledBotBoxDiv = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary[700]};
    color: ${(props) => props.theme.palette.primary.contrastText};
  }
`;

// eslint-disable-next-line react/prop-types
function Input({ setIsReply, isReply, isMark, isDirection, myRefInput, isBotRoom }) {
  const history = useHistory();

  const [checkedEnter, setCheckedEnter] = useState(true);
  const [charSize, setCharSize] = useState(0);
  const [currentConversation, setCurrentConversation] = useState("");
  const { state } = useContext(Store);
  const [inputtext, setInputtext] = useState({ html: "" });
  const [inputRole, setInputRole] = useState("");
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ noClick: true });
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileNumError, setFileNumError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stampAnchorEl, setStampAnchorEl] = useState(null);
  const [botAnchorEl, setBotAnchorEl] = useState(null);
  const [openQuestion, setOpenQuestion] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [inputTemplateRefFileNum, setInputTemplateRefFileNum] = useState(0);

  //const myRef = useRef(null);
  const replyRef = useRef(null);
  const InputElRef = useRef(null);
  const inputTemplateRef = useRef(null);
  const [mouseOverId, setMouseOverId] = useState(null);
  const [openStamp, setOpenStamp] = useState(false);
  const [openStampModal, setOpenStampModal] = useState(false);

  const [openBotBar, setOpenBotBar] = useState(false);
  const [openResetBar, setOpenResetBar] = useState(false);
  useEffect(() => {
    if (myRefInput.current !== null && currentRoom !== null) {
      currentRoom.tmpMessage = inputtext;
    }
  }, [inputtext]);

  // 部屋移動時の初期化(destory)
  useEffect(() => {
    const tmpCurrentRoom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0];
    setCurrentRoom(tmpCurrentRoom);
    if (tmpCurrentRoom !== null && tmpCurrentRoom.tmpMessage !== undefined) {
      setInputtext(tmpCurrentRoom.tmpMessage);
    } else {
      setInputtext({ html: "" });
    }
    delFilesInInputTemplateRef();
    setFileNumError(false);
    setFileSizeError(false);
    setCharSize(0);
  }, [state.currentRoomid]);

  // state.chatroomsの現部屋を取得
  // useEffect(() => {
  //   setCurrentRoom(state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0]);
  // }, [state]);

  // 返信
  useEffect(() => {
    if (isReply != 0) {
      const tmpCurrentRoom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0];
      setCurrentConversation(tmpCurrentRoom.conversationlist.filter((f) => f.messageid === isReply)[0]);
      replyRef.current = tmpCurrentRoom.conversationlist.filter((f) => f.messageid === isReply)[0];
    } else {
      setCurrentConversation("");
    }
  }, [isReply]);

  // メッセージ入力欄でのkeydown
  const handleOnContentEditableKeyDown = (e) => {
    let checked = localStorage.getItem("checkedEnter") == "1" ? true : false;
    const sendMes = () => {
      e.preventDefault();
      if (!(fileSizeError || fileNumError)) {
        sendServer();
      }
    };
    // Enter押下
    if (e.which === 13) {
      if (e.ctrlKey && !e.shiftKey) {
        // ＋Ctrl 送信
        sendMes();
      } else if (!e.ctrlKey && e.shiftKey) {
        // ＋Shift 改行
        return false;
      } else {
        // Ctrl, Shiftなし
        //「Enterで送信」チェックの有無
        if (checked) {
          sendMes();
        } else {
          return false;
        }
      }
    }
  };

  // ファイル追加ボタンクリック
  const handleAddFileClick = async (event) => {
    if (inputTemplateRef.current.files.length === 0) {
      // エクスプローラ開く
      await InputElRef.current.click();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  // ファイルボタンに紐付いてるのはこの要素（InputElRef）
  // InputTemplateRefに追加する
  const onInputElRefChange = (e) => {
    const files = e.target.files;
    addFilesInInputTemplateRef(files);
  };

  // ドロップファイルをInputTemplateRefに追加
  useEffect(() => {
    addFilesInInputTemplateRef(acceptedFiles);
  }, [acceptedFiles]);

  // ファイル追加ボタン-Menu-ファイル選択をクリック
  const handleAddFileMenuClick = () => {
    setAnchorEl(null);
    InputElRef.current.click();
  };

  // ファイル追加ボタン-Menu-ファイル削除をクリック
  const handleDelFileMenuClick = () => {
    setAnchorEl(null);
    delFilesInInputTemplateRef();
  };

  // ファイルメニューを閉じる
  const handleFileMenuClose = () => {
    setAnchorEl(null);
  };

  // ファイル追加エラー閉じる
  const handleFileErrorClose = () => {
    setFileNumError(false);
    setFileSizeError(false);
  };

  // スタンプボタンクリック
  const handleStampClick = (event) => {
    setStampAnchorEl(event.currentTarget);
  };
  // チャットボットボタンクリック
  const handleBotClick = async (event) => {
    setBotAnchorEl(event.currentTarget);
    const result = await custom_axios(API_URL + `/getGeminiRole?honbuid=${state.honbuid}`);
    const status = result.status;

    if (status === 200) {
      var airole = result.data.airole;
      setInputRole(airole);
    }
  };
  const handleRoleSave = async () => {
    const body = {
      honbuid: state.honbuid,
      airole: inputRole,
    };
    //const result = await custom_axios_post(API_URL + "/GetDebugAdminUsers", body);
    const result = await custom_axios_post(API_URL + "/setGeminirole", body);
    const status = result.status;

    if (status === 200) {
      setBotAnchorEl(null);
      setOpenBotBar(true);
    }
  };

  const handleAiReset = async () => {
    const body = {
      honbuid: state.honbuid,
      roomid: localStorage.getItem("currentRoomid"),
    };
    //const result = await custom_axios_post(API_URL + "/GetDebugAdminUsers", body);
    const result = await custom_axios_post(API_URL + "/resetGeminiChat", body);
    const status = result.status;

    if (status === 200) {
      setBotAnchorEl(null);
      setOpenResetBar(true);
    }
  };

  // スタンプ送信
  const sendStamp = (stamp) => {
    //hubConnection.invoke("SendMessage", state.currentRoomid, state.honbuid, state.user_name, stamp, "stamp");

    const params = new FormData();
    params.append("roomid", state.currentRoomid);
    params.append("honbuid", state.honbuid);
    params.append("name", state.user_name);
    params.append("message", stamp);
    params.append("messagetype", "stamp");
    custom_axios_post(API_URL + "/SendMessage", params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then(() => {
        //console.log("upload ok...");
      })
      .catch(() => {
        //console.log("upload failed...");
      });

    setStampAnchorEl(null);
  };

  const choseStamp = () => {
    setOpenStamp(true);
  };

  // スタンプ閉じる
  const handleStampClose = () => {
    setStampAnchorEl(null);
  };
  // ボット閉じる
  const handleBotClose = () => {
    setBotAnchorEl(null);
  };

  // アンケート閉じる
  const handleQuestionClose = () => {
    setOpenQuestion(false);
  };

  const handleOpenStampClose = () => {
    setOpenStamp(false);
  };

  // 「Enterで送信」のチェックを確認
  useEffect(() => {
    let inivalue = localStorage.getItem("checkedEnter") == "0" ? false : true;
    setCheckedEnter(inivalue);
  }, []);

  // 「Enterで送信」のチェックをDBに保存する
  const changeCheckedEnter = async (checked) => {
    const httpstring = `/changeCheckedEnter?honbuid=${state.honbuid}&enterchecked=${checked ? 1 : 0}`;
    var result = await custom_axios(API_URL + httpstring);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  // 「Enterで送信」のチェックの変更時
  const handleCheckEnterChange = (e) => {
    setCheckedEnter(e.target.checked);
    if (e.target.checked) {
      localStorage.setItem("checkedEnter", "1");
    } else {
      localStorage.setItem("checkedEnter", "0");
    }
    /* ここで「Enterで送信」のチェックをDBに保存する */
    changeCheckedEnter(e.target.checked);
  };

  // 送信ボタンクリック
  const handleSendBtnClick = (e) => {
    e.preventDefault();
    sendServer();
  };

  // メッセージ送信
  const sendServer = async () => {
    /* ファイル送信について: PR121に詳細記載 */
    /* ドロップとボタンで追加されたファイルの準備 */
    // 送信する処理する前にクリアにする（テキストボックスに0.5秒ぐらい残り違和感）
    const messageNodes = [...myRefInput.current.childNodes];
    //console.log("myRef.current", myRef.current);

    const sendFiles = [];
    [].forEach.call(inputTemplateRef.current.files, (file) => {
      sendFiles.push(file);
    });
    delFilesInInputTemplateRef();
    /* ペーストでの画像の準備 */
    // const sendPastedFiles = [];
    let sendMessage = "";
    for (let i = 0; i < messageNodes.length; i++) {
      const node = messageNodes[i];
      if (node.nodeName === "IMG") {
        // IMGを区切りにtextメッセージを送信する
        if (sendMessage !== "") {
          sendMessage = sendMessage.replace(/<br>/g, "\n");
          await sendText(sendMessage);
          sendMessage = "";
        }
        // Imageを送信する
        const byteString = window.atob(node.src.split(",")[1]);
        const mimeType = node.src.split(";")[0].split(":")[1];
        const content = new Uint8Array(byteString.length);
        for (let j = 0; j < byteString.length; j++) {
          content[j] = byteString.charCodeAt(j);
        }
        const blob = new File([content], "test", {
          type: mimeType,
        });
        // sendPastedFiles.push(blob);
        await sendImage(blob);
      } else if (node.nodeName === "#text") {
        sendMessage += node.data;
      } else if (node.nodeName === "FONT") {
        sendMessage += node.textContent;
      } else if (node.nodeName === "BR") {
        sendMessage += "<br>";
      } else {
        // IMG, #text, BR以外は何もしない
      }
    }

    setInputtext({ html: "" });
    setCharSize(0);
    setIsReply(0);

    if (sendMessage !== "") {
      sendMessage = sendMessage.replace(/<br>/g, "\n");
      await sendText(sendMessage);
      sendMessage = "";
    }

    /* 添付ファイルでの画像送信 */
    if (sendFiles.length > 0) {
      for (let i = 0; i < sendFiles.length; i++) {
        await sendImage(sendFiles[i]);
      }
    }
  };

  const sendText = async (text) => {
    // if (currentConversation == "") {
    //   console.log("ok");
    // }
    /* contentEditable=true をしてると、何故かhooksが取得できないのでローカルストレージから取得 */
    const sendRoomid = localStorage.getItem("currentRoomid");
    const isbot = localStorage.getItem("isbot");
    /* テキスト送信 */
    if (text.trim() != "") {
      const localCurrentConversation = replyRef.current;

      /* BOT判定 LocalStorageのcurrentRoomidじゃないと、何故か前回のroomidがひっぱってこられる場合があるから、ここで計算 */
      // const localCurrentRoom = state.chatrooms.filter((f) => f.roomid === sendRoomid)[0];
      // const botlist = localCurrentRoom.memberlist.filter((item) => {
      //   return item.memo.indexOf("bot") != -1;
      // });
      // const memberlist = localCurrentRoom.memberlist;
      // const isSendBot = localCurrentRoom.isbotroom;

      const params = new FormData();
      params.append("roomid", sendRoomid);
      params.append("honbuid", state.honbuid);
      params.append("name", state.user_name);
      params.append("message", unescapeHTML(text.substring(0, 1000).trim()));
      params.append(
        "messagetype",
        isReply != 0 && localCurrentConversation != undefined
          ? "replymessage@" + localCurrentConversation.messageid.toString()
          : isbot == "1"
            ? "botmessage"
            : "message"
      );
      await custom_axios_post(API_URL + "/SendMessage", params, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });
    }
  };

  // 画像送信
  const sendImage = async (file) => {
    let sendURL = "";
    let sendMessage = "ファイルを送信しました";
    if (filetype.includes(file.type)) {
      sendURL = "/uploadImage";
    } else if (videofiletype.includes(file.type)) {
      sendURL = "/uploadVideo";

      if (file.size >= 1000000) {
        sendMessage = String(Math.round(file.size / 1000000)) + "MB";
      } else {
        sendMessage = String(Math.round(file.size / 1000)) + "KB";
      }
    } else {
      sendURL = "/uploadFile";
    }
    const params = new FormData();

    params.append("roomid", localStorage.getItem("currentRoomid"));
    params.append("honbuid", localStorage.getItem("honbuid"));
    params.append("name", state.user_name);
    params.append("message", sendMessage);
    params.append("file", file);
    params.append("filename", file.name);
    params.append("mimetype", file.type);
    await custom_axios_post(API_URL + sendURL, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  // InputTemplateRefに入れてるファイルを消す
  const delFilesInInputTemplateRef = () => {
    const dataTransfer = new DataTransfer();
    inputTemplateRef.current.files = dataTransfer.files;
    setInputTemplateRefFileNum(inputTemplateRef.current.files.length);
  };

  // InputTemplateRefにファイルを追加
  const addFilesInInputTemplateRef = (addFiles) => {
    let fileerr = false;
    // 追加する前にファイル数やサイズ確認
    if (inputTemplateRef.current.files.length + addFiles.length > 5) {
      /* 添付ファイル数5を超えた場合 */
      setFileNumError(true);
      fileerr = true;
    } else {
      for (let i = 0; i < addFiles.length; i++) {
        if (addFiles[i].size > 20000000) {
          /* 1ファイル20Mを超えた場合 */
          setFileSizeError(true);
          fileerr = true;
        }
      }
    }
    if (!fileerr && addFiles.length !== 0) {
      // https://qiita.com/jkr_2255/items/1c30f7afefe6959506d2
      const dataTransfer = new DataTransfer();
      // すでにあるファイルたち
      [].forEach.call(inputTemplateRef.current.files, (file) => {
        dataTransfer.items.add(file);
      });
      // 追加
      // addFilesが[object FileList]
      if (toString.call(addFiles) === toString.call(inputTemplateRef.current.files)) {
        [].forEach.call(addFiles, (file) => {
          dataTransfer.items.add(file);
        });
      }
      // addFilesが[object Array]
      if (toString.call(addFiles) === toString.call([])) {
        addFiles.forEach((file) => {
          dataTransfer.items.add(file);
        });
      }
      // FileListを上書き
      inputTemplateRef.current.files = dataTransfer.files;
    }
    setInputTemplateRefFileNum(inputTemplateRef.current.files.length);
  };

  // アンケートの実体
  // const MemoQuestion = useMemo(() => {
  //   return <ShowNewQueList open={openQuestion} handleClose={handleQuestionClose} createOK={true}></ShowNewQueList>;
  // }, [openQuestion]);
  const MemoQuestion = useMemo(() => {
    return <ShowPlusQuestionList open={openQuestion} handleClose={handleQuestionClose}></ShowPlusQuestionList>;
  }, [openQuestion]);

  const MemoStamp = useMemo(() => {
    return <ShowStampPhoto open={openStamp} handleClose={handleOpenStampClose}></ShowStampPhoto>;
  }, [openStamp]);
  const { dispatch } = useContext(Store);
  const deleteStamp = async (ids) => {
    const params = new FormData();
    params.append("honbuid", localStorage.getItem("honbuid"));
    params.append("stampids", ids);
    custom_axios_post(API_URL + "/DeleteStamps", params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: "DEL_STAMP", payload: ids });
        }
        //console.log("upload ok...");
      })
      .catch(() => {
        //console.log("upload failed...");
      });
  };
  const MemoShowOpenBar = useMemo(() => {
    return (
      <Snackbar
        open={openBotBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={1000}
        onClose={() => {
          setOpenBotBar(false);
        }}
        message="保存しました。"
      ></Snackbar>
    );
  }, [openBotBar]);
  const MemoShowResetBar = useMemo(() => {
    return (
      <Snackbar
        open={openResetBar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={1000}
        onClose={() => {
          setOpenResetBar(false);
        }}
        message="リセットしました。"
      ></Snackbar>
    );
  }, [openResetBar]);
  return (
    <div className="resizepanelbottom">
      <div {...getRootProps({ className: "message-box-wraper" })}>
        <input {...getInputProps()} />
        {isDirection == "1" && isMark == "0" ? (
          <div className="size-over-background">
            <div>発言権がありません</div>
          </div>
        ) : fileSizeError || fileNumError ? (
          <div className="size-over-background">
            <Tooltip title="OK">
              <div onClick={handleFileErrorClose} style={{ cursor: "pointer" }}>
                {fileNumError && "1送信5ファイルまでです!!"}
                {fileSizeError && "1ファイル20MBまでです!!"}
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="input-div-wrap">
            {isReply != 0 && currentConversation != "" && currentConversation != undefined ? (
              <div className="input-div-reply">
                <div className="input-div-reply-flex">
                  <img
                    className="chat-reply-avatar-image"
                    src={
                      STATIC_URL +
                      `/downloadAvatar?honbuid=${currentConversation.honbuid}&fileno=${currentConversation.fileno}&size=small`
                    }
                    width="25"
                    height="25"
                    alt=""
                    //style={isCurrent ? { border: "1px solid white" } : null}
                  />
                  <div className="input-div-reply-name">{currentConversation.name}</div>
                  <div className="input-div-reply-text"></div>
                  <div className="input-div-reply-button" onClick={() => setIsReply(0)}>
                    <ClearIcon />
                  </div>
                </div>
                <div className="input-div-reply-flex">
                  <div className="input-div-reply-text">{currentConversation.message}</div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <ContentEditable
              translate="no"
              onPaste={(e) => {
                let imgs = [];
                for (var i = 0; i < e.clipboardData.items.length; i++) {
                  // Skip content if not image
                  if (e.clipboardData.items[i].type.indexOf("image") == -1) continue;
                  // Retrieve image on clipboard as blob
                  var blob = e.clipboardData.items[i].getAsFile();
                  imgs.push(blob);
                }
                if (imgs.length > 0) {
                  /* 画像データ貼り付け時 */
                  //console.log("inputjs past元", blob);
                  //document.execCommand("insertHTML", false, e.clipboardData);
                } else {
                  /* テキスト貼り付け時 */
                  e.preventDefault();
                  var text = e.clipboardData.getData("text/plain");
                  //console.log("inputjs past加工", text);
                  document.execCommand("insertHTML", false, text.replace(/\r?\n/g, "<br>"));
                }
              }}
              className={isReply != 0 && currentConversation != undefined ? "editabledivreply" : "editablediv"}
              innerRef={myRefInput}
              html={inputtext.html}
              disabled={false}
              onChange={(e) => {
                let sendstr = myRefInput.current.innerHTML.replace(/<br>/g, "\n").replace(/<(?:.|)*?>/gm, "");
                //console.log("文字の中身", myRef.current.innerHTML, sendstr);

                setCharSize(sendstr.length);
                setInputtext({ html: e.target.value });
                //console.error("e.target.value", e.target.value);
              }} // handle innerHTML change
              onKeyDown={(e) => {
                return handleOnContentEditableKeyDown(e);
              }}
            />
          </div>
        )}
      </div>

      {/* アイコンたち */}
      <div className="sending-area-buttom">
        <div className="sending-area-buttons">
          {MemoQuestion}
          {MemoStamp}
          {MemoShowOpenBar}
          {MemoShowResetBar}
          {isBotRoom == "1" ? (
            <></>
          ) : (
            <Tooltip title="アンケート一覧">
              <IconButton color="primary" aria-label="send message" onClick={() => setOpenQuestion(true)}>
                <QuestionAnswerIcon />
                {currentRoom && (
                  <Badge
                    overlap="rectangular"
                    color="primary"
                    badgeContent={Number(currentRoom.unvotecnt)}
                    invisible={Number(currentRoom.unvotecnt) === 0}
                  ></Badge>
                )}
              </IconButton>
            </Tooltip>
          )}
          {(isDirection == "1" && isMark == "0") || isBotRoom == "1" ? (
            <></>
          ) : (
            <Tooltip title="添付ファイル">
              <IconButton color="primary" aria-label="send message" onClick={handleAddFileClick}>
                <AttachFile />
                <Badge
                  overlap="rectangular"
                  color="primary"
                  badgeContent={inputTemplateRefFileNum}
                  invisible={inputTemplateRefFileNum === 0}
                ></Badge>
              </IconButton>
            </Tooltip>
          )}
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleFileMenuClose}>
            <MenuItem onClick={handleAddFileMenuClick}>ファイル選択</MenuItem>
            {inputTemplateRef.current !== null && inputTemplateRef.current.files.length > 0 && (
              <MenuItem onClick={handleDelFileMenuClick}>ファイル削除</MenuItem>
            )}
          </Menu>
          <input
            ref={InputElRef}
            className="input-single-file"
            id="icon-button-file"
            type="file"
            onChange={onInputElRefChange}
            multiple="multiple"
          />
          <input
            ref={inputTemplateRef}
            className="input-single-file"
            id="input-templete"
            type="file"
            multiple="multiple"
          />
          {(isDirection == "1" && isMark == "0") || isBotRoom == "1" ? (
            <></>
          ) : (
            <Tooltip title="スタンプ">
              <IconButton color="primary" aria-label="send message" onClick={handleStampClick}>
                <Face />
              </IconButton>
            </Tooltip>
          )}
          {isBotRoom == "1" ? (
            <Tooltip title="AI設定">
              <IconButton color="primary" aria-label="bot setting" onClick={handleBotClick}>
                <SettingsIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <></>
          )}
          <ShowCommon
            open={openStampModal}
            title="削除しますか？"
            handleClose={() => {
              setOpenStampModal(false);
            }}
            handleCallBack={() => {
              deleteStamp(mouseOverId);
              setOpenStampModal(false);
            }}
          ></ShowCommon>
          {/* <MuiThemeProvider theme={theme}> */}
          <Menu
            id="simple-botmenu"
            anchorEl={botAnchorEl}
            // keepMounted
            open={Boolean(botAnchorEl)}
            onClose={handleBotClose}
          >
            <div className="bot-top-settings">
              <StyledBotTitleDiv>役割設定</StyledBotTitleDiv>
              <StyledBotBoxDiv>
                <div>AIに役割を設定する事で、AIの回答をコントロールする事ができます。</div>
                <div style={{ marginBot: "3px" }}>以下のテキストボックスに役割を入力してお試しください。</div>

                <TextField
                  label="役割"
                  placeholder="例：あなたは関西弁の薬剤師です"
                  type="text"
                  id="pass1"
                  name="pass1"
                  variant="outlined"
                  value={inputRole}
                  onChange={(e) => {
                    setInputRole(e.target.value);
                  }}
                  style={{ margin: "3px", width: "600px", marginTop: "3px" }}
                />

                <StyledButton
                  style={{ margin: "0 auto" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleRoleSave()}
                >
                  保存
                </StyledButton>
              </StyledBotBoxDiv>

              <div style={{ margin: "10px" }}></div>

              <StyledBotTitleDiv>会話リセット</StyledBotTitleDiv>
              <StyledBotBoxDiv>
                <div>現在行っているAIとの会話をリセットします。</div>

                <StyledButton
                  style={{ margin: "0 auto" }}
                  variant="contained"
                  color="primary"
                  onClick={() => handleAiReset()}
                >
                  リセット
                </StyledButton>
              </StyledBotBoxDiv>
            </div>
          </Menu>
          {/* </MuiThemeProvider>
          <MuiThemeProvider theme={theme}> */}
          <Menu
            id="simple-menu"
            anchorEl={stampAnchorEl}
            keepMounted
            open={Boolean(stampAnchorEl)}
            onClose={handleStampClose}
          >
            <div className="stamp-box-wrap">
              <div className="stamp-box">
                {state.stamps.map((stamp) => {
                  return (
                    <div
                      key={stamp.stampid}
                      className="stamp-box-mouseover"
                      onMouseOver={() => setMouseOverId(stamp.stampid)}
                    >
                      <img
                        src={API_URL + `/getstamp?stampid=${stamp.stampid}`}
                        width="120"
                        height="120"
                        alt=""
                        className="stamp-box-child"
                        onClick={() => sendStamp(stamp.stampid)}
                      />
                      <div className="stamp-box-mouseover-child">
                        {mouseOverId == null || mouseOverId != stamp.stampid ? (
                          <div></div>
                        ) : (
                          <StyledIconButton aria-label="send message" onClick={() => setOpenStampModal(true)}>
                            <Delete />
                          </StyledIconButton>
                        )}
                      </div>
                    </div>
                  );
                })}
                <div className="stamp-box-plus">
                  <img
                    src={STAMP_URL + `/plus.png`}
                    width="70"
                    height="70"
                    alt=""
                    className="stamp-box-plus-child"
                    onClick={() => choseStamp()}
                  />
                </div>
              </div>
            </div>
          </Menu>
          {/* </MuiThemeProvider> */}
        </div>
        <div className="sending-area-buttons">(文字:{charSize} / 1000)</div>
        <div className="sending-area-buttons">
          {/* <FormControlLabel
            control={<Checkbox checked={checkedSilent} onChange={handleCheckSilent} name="checkedB" color="primary" />}
            label="サイレントモード"
          /> */}
          {(isDirection == "1") & (isMark == "0") ? (
            <></>
          ) : (
            <FormControlLabel
              control={
                <Checkbox checked={checkedEnter} onChange={handleCheckEnterChange} name="checkedB" color="primary" />
              }
              label="Enterで送信"
            />
          )}
          {(isDirection == "1") & (isMark == "0") ? (
            <></>
          ) : (
            <Tooltip title="送信">
              <IconButton
                disabled={fileSizeError || fileNumError}
                color="primary"
                aria-label="send message"
                onClick={handleSendBtnClick}
              >
                <Send />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

export default Input;
