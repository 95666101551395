/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
function ShowDirectionSelect({ initvalue, callBack, roomid }) {
  const [role, setRole] = React.useState("0");

  const handleChange = (event) => {
    setRole(event.target.value);
    callBack(roomid, event.target.value);
  };
  useEffect(() => {
    setRole(initvalue);
  }, [initvalue]);
  return (
    <FormControl size="small">
      <Select labelId="demo-simple-select-label" id="demo-simple-select" value={role} onChange={handleChange}>
        <MenuItem value={"0"}>双方向</MenuItem>
        <MenuItem value={"1"}>一方向</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ShowDirectionSelect;
