/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useMemo, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ShowAnpiVote from "../Common/ShowAnpiVote";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { API_URL, STATIC_URL, custom_axios, GOOD_EMOJI } from "../Common/Common";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import styled from "@emotion/styled";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Search from "@mui/icons-material/Search";
import ShowUserInfo from "../Common/ShowUserInfo";
import ojigiman from "../image/ojigiman.gif";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
const StyledWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2px;
  background: #f7f8fc;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  color: #9d9d9d;
  position: relative;
  z-index: 0;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const StyledFlexibleDiv = styled("div")`
  display: flex;
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledLink = styled(Link)`
  cursor: default;
  &:hover {
    cursor: pointer;
  }
`;

const StyledReadedFont = styled("div")`
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary.main};
`;
function ShowReadMember({ open, handleClose, messageid, roomid, isadmin, isanpi }) {
  const history = useHistory();
  //console.log("useMemo", "ShowReadMember", open);
  const [localList, setLocalList] = useState([]);
  const [isnothing, setIsNothing] = useState(false);
  useEffect(() => {
    setLocalList([]);
  }, []);
  useEffect(() => {
    let honbuid = localStorage.getItem("honbuid");
    const proc = async () => {
      if (open.open) {
        var sql = "";
        if (open.kbn == "message") {
          sql = API_URL + `/getReadedUser?honbuid=${honbuid}&messageid=${messageid}`;
        } else if (open.kbn == "anpi") {
          sql = API_URL + `/getReadedAnpiUser?honbuid=${honbuid}&messageid=${messageid}&roomid=${roomid}`;
        } else {
          sql = API_URL + `/getReadedNewQueUser?honbuid=${honbuid}&messageid=${messageid}`;
        }
        const result = await custom_axios(sql);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        }
        if (status === 204) {
          setIsNothing(true);
        }
        if (status === 200) {
          setIsNothing(false);
          setLocalList(result.data.readedmember);
        }
      }
    };
    proc();
  }, [open, messageid]);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [openAnpi, setOpenAnpi] = useState({ honbuid: "", isOpen: false });
  const handleAnpiVoteClose = () => {
    setOpenAnpi({ ...openAnpi, isOpen: false });
  };
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowAnpiVote
        open={openAnpi.isOpen}
        honbuid={openAnpi.honbuid}
        roomid={roomid}
        //openAnpiId={selectedAnpiid}
        handleClose={handleAnpiVoteClose}
        afterProc={() => {}}
        isEnabled={false}
        isadmin={isadmin}
        isanpi={isanpi}
      />
    );
  }, [openAnpi, roomid]);

  const [chatsFilter, setChatsFilter] = useState("");
  const handleFilterChange = (e) => {
    setChatsFilter(e.target.value);
  };
  const [showuser, setShowUser] = useState({ open: false, honbuid: "" });
  const handleMeClose = () => {
    setShowUser({ ...showuser, open: false });
  };
  const handleClickOpen = (honbuid) => {
    setShowUser({ open: true, honbuid: honbuid });
  };
  const MemoAvatar = useMemo(() => {
    return (
      <ShowUserInfo
        open={showuser.open}
        honbuid={showuser.honbuid}
        handleClose={handleMeClose}
        handleMeClose={handleClose}
      />
    );
  }, [showuser]);

  return (
    <Dialog open={open.open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">メンバー</div>
      </StyledDialogTitle>

      <DialogContent>
        {MemoAnpiVote}
        {MemoAvatar}
        {isnothing ? (
          <div>
            <div>このグループでは、既読・未読者を表示できません。</div>
          </div>
        ) : (
          <Paper square>
            <div className="chat-search-read">
              <div className="chat-search-icon">
                <StyledSearch />
              </div>
              <input
                placeholder="メンバー検索"
                value={chatsFilter}
                name="chatsFilter"
                onChange={handleFilterChange}
                className="chat-filter-search-field"
              />
            </div>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
              variant="fullWidth"
            >
              <Tab
                label={
                  "既読(" +
                  localList
                    .filter((e) => {
                      if (chatsFilter != "") {
                        if (
                          String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                          String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                        ) {
                          return e.isreaded === "1";
                        } else {
                          return false;
                        }
                      } else {
                        return e.isreaded === "1";
                      }
                    })
                    .length.toString() +
                  ")"
                }
              />
              <Tab
                label={
                  "未読(" +
                  localList
                    .filter((e) => {
                      if (chatsFilter != "") {
                        if (
                          String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                          String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                        ) {
                          return e.isreaded === "0";
                        } else {
                          return false;
                        }
                      } else {
                        return e.isreaded === "0";
                      }
                    })
                    .length.toString() +
                  ")"
                }
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div className="read-member-list">
                {localList &&
                  localList
                    .filter((e) => {
                      if (chatsFilter != "") {
                        if (
                          String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                          String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                        ) {
                          return e.isreaded === "1";
                        } else {
                          return false;
                        }
                      } else {
                        return e.isreaded === "1";
                      }
                    })
                    .map((e) => {
                      return (
                        <StyledWrapper key={e.honbuid} onClick={() => handleClickOpen(e.honbuid)}>
                          <div className="read-member-content">
                            <img
                              src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                              width="30"
                              height="30"
                              alt=""
                              className="read-member-image"
                            />
                            <div className="read-member-text-wrapper">
                              <StyledFlexibleDiv>
                                {e.isvip == "1" && (
                                  <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                                )}
                                {e.isbukaiadmin == "1" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="部会管理"
                                  ></StyledChip>
                                ) : e.isbukaiadmin == "2" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="部会部屋"
                                  ></StyledChip>
                                ) : (
                                  <div></div>
                                )}
                                {e.isadmin == "1" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="システム管理"
                                  ></StyledChip>
                                ) : e.isadmin == "2" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="施設管理"
                                  ></StyledChip>
                                ) : e.isadmin == "3" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="部屋管理"
                                  ></StyledChip>
                                ) : e.isadmin == "6" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="施設横断部屋"
                                  ></StyledChip>
                                ) : e.isadmin == "4" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="ユーザー管理"
                                  ></StyledChip>
                                ) : e.isadmin == "5" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="部屋・ユーザー管理"
                                  ></StyledChip>
                                ) : (
                                  <div></div>
                                )}
                              </StyledFlexibleDiv>
                              <div className="read-member-name-wrapper">
                                <div className="show-read-member-name">
                                  {e.name}
                                  <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.subname})</span>
                                </div>
                              </div>
                              {/* <div className="read-member-name">{e.hpname}</div> */}
                            </div>
                          </div>
                          <div className="read-member-content" style={{ marginRight: "5px" }}>
                            <div>
                              {e.isquestion === "1" && <StyledReadedFont>回答済</StyledReadedFont>}
                              {e.isanpi === "1" && (
                                <StyledLink
                                  onClick={(event) => {
                                    setOpenAnpi({ isOpen: true, honbuid: e.honbuid });
                                    event.stopPropagation();
                                  }}
                                >
                                  <StyledReadedFont>回答済</StyledReadedFont>
                                </StyledLink>
                              )}
                            </div>
                            {/* <div className="goodedhonbuid">{e.gooded == "1" && <FavoriteIcon></FavoriteIcon>}</div> */}
                            <div>{e.emojicnt1 == "0" && e.gooded == "0" ? null : GOOD_EMOJI[0]}</div>
                            <div>{e.emojicnt2 == "0" ? null : GOOD_EMOJI[1]}</div>
                            <div>{e.emojicnt3 == "0" ? null : GOOD_EMOJI[2]}</div>
                            <div>{e.emojicnt4 == "0" ? null : GOOD_EMOJI[3]}</div>
                            <div>
                              {e.emojicnt5 == "0" ? null : (
                                <img
                                  style={{ marginTop: "3px", marginLeft: "6px", marginRight: "6px" }}
                                  src={ojigiman}
                                  height="16"
                                  alt="アイコン"
                                />
                              )}
                            </div>
                          </div>
                        </StyledWrapper>
                      );
                    })}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="read-member-list">
                {localList &&
                  localList
                    .filter((e) => {
                      if (chatsFilter != "") {
                        if (
                          String(e.name).toLowerCase().indexOf(chatsFilter) !== -1 ||
                          String(e.subname).toLowerCase().indexOf(chatsFilter) !== -1
                        ) {
                          return e.isreaded === "0";
                        } else {
                          return false;
                        }
                      } else {
                        return e.isreaded === "0";
                      }
                    })
                    .map((e) => {
                      return (
                        <StyledWrapper key={e.honbuid} onClick={() => handleClickOpen(e.honbuid)}>
                          <div className="read-member-content">
                            <img
                              src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                              width="30"
                              height="30"
                              alt=""
                              className="read-member-image"
                            />
                            <div className="read-member-text-wrapper">
                              <StyledFlexibleDiv>
                                {e.isvip == "1" && (
                                  <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                                )}
                                {e.isbukaiadmin == "1" && (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="部会管理"
                                  ></StyledChip>
                                )}
                                {e.isadmin == "1" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="システム管理"
                                  ></StyledChip>
                                ) : e.isadmin == "2" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="施設管理"
                                  ></StyledChip>
                                ) : e.isadmin == "3" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="部屋管理"
                                  ></StyledChip>
                                ) : e.isadmin == "6" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="施設横断部屋"
                                  ></StyledChip>
                                ) : e.isadmin == "4" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="ユーザー管理"
                                  ></StyledChip>
                                ) : e.isadmin == "5" ? (
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="部屋・ユーザー管理"
                                  ></StyledChip>
                                ) : (
                                  <div></div>
                                )}
                              </StyledFlexibleDiv>
                              <div className="read-member-name-wrapper">
                                <div className="show-read-member-name">
                                  {e.name}
                                  <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.subname})</span>
                                </div>
                              </div>
                              {/* <div className="read-member-name">{e.hpname}</div> */}
                            </div>
                          </div>
                          <div className="read-member-content" style={{ marginRight: "5px" }}>
                            <div>
                              {e.isquestion === "1" && <StyledReadedFont>回答済</StyledReadedFont>}
                              {e.isanpi === "1" && <StyledReadedFont>回答済</StyledReadedFont>}
                            </div>
                            <div>{e.emojicnt1 == "0" && e.gooded == "0" ? null : GOOD_EMOJI[0]}</div>
                            <div>{e.emojicnt2 == "0" ? null : GOOD_EMOJI[1]}</div>
                            <div>{e.emojicnt3 == "0" ? null : GOOD_EMOJI[2]}</div>
                            <div>{e.emojicnt4 == "0" ? null : GOOD_EMOJI[3]}</div>
                            <div>
                              {e.emojicnt5 == "0" ? null : (
                                <img
                                  style={{ marginTop: "3px", marginLeft: "6px", marginRight: "6px" }}
                                  src={ojigiman}
                                  height="16"
                                  alt="アイコン"
                                />
                              )}
                            </div>
                          </div>
                        </StyledWrapper>
                      );
                    })}
              </div>
            </TabPanel>
          </Paper>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ShowReadMember;
