import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import "./Login.css";
// import Lottie from "react-lottie";
import Lottie from "lottie-react";
import animationData from "./38463-error";
import { Store } from "../Store/store";
import axios from "axios";
import { LOGIN_URL } from "../Common/Common";

function LoginError() {
  const history = useHistory();
  const { dispatch, hubConnection } = useContext(Store);
  useEffect(() => {
    hubConnection.methods = [];
    /* for (let i = 0; i < Object.keys(hubConnection.methods).length; i++) {
    if (Object.keys(hubConnection.methods)[i]) {
      hubConnection.off(Object.keys(hubConnection.methods)[i]);
      console.log("hubConnection", Object.keys(hubConnection.methods)[i]);
    }
  } */
    //console.log("hubConnection", hubConnection);

    let honbuid = localStorage.getItem("honbuid");
    axios(LOGIN_URL + `/deleteWebToken?honbuid=${honbuid}`);

    dispatch({ type: "LOGOUT" });
    localStorage.removeItem("login");
    //history.replace("/login");
  }, []);
  const style = {
    height: 200,
    width: 200,
  };
  return (
    <div className="error-chat">
      <Lottie
        style={style}
        animationData={animationData}
        loop={true}
        //width={200}
        //height={200}
        // width={200}
        // height={200}
        // options={{
        //   loop: true,
        //   autoplay: true,
        //   animationData,
        //   rendererSettings: {
        //     preserveAspectRatio: "xMidYMid slice",
        //   },
        // }}
      />
      <div>アカウント情報が不正です。再ログインしてください。</div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={() => {
          history.replace("/login");
        }}
      >
        ログインページへ
      </Button>
    </div>
  );
}

export default LoginError;
