/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";

import React, { useEffect, useState, useContext, useMemo } from "react";
import { Store } from "../Store/store";
import { API_URL, STATIC_URL, custom_axios } from "../Common/Common";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import ShowFavorMember from "../Common/ShowFavorMember";
import styled from "@emotion/styled";
import Snackbar from "@mui/material/Snackbar";
import Chip from "@mui/material/Chip";

const StyledFlexibleDiv = styled("div")`
  display: flex;
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
function ShowUserInfo({ open, honbuid, handleClose, handleMeClose }) {
  const history = useHistory();
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [subname, setSubname] = useState("");
  const [jobname, setJobname] = useState("");
  // const [phone, setPhone] = useState("");
  const [hpname, setHpname] = useState("");
  const [isadmin, setIsadmin] = useState("");
  const [isvip, setIsvip] = useState("");
  const [isbukaiadmin, setIsbukaiadmin] = useState("");
  // const [favorcount, setFavorcount] = useState("");
  const { state, dispatch, hubConnection } = useContext(Store);
  const myhonbuid = localStorage.getItem("honbuid");

  const handleCreate = async () => {
    /* 一方向部屋ではコンタクトは禁止 */
    if (
      state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isdirection == "1" &&
      state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ismarkme == "0"
    ) {
      setContactErrorSnackOpen(true);
      return;
    }
    /* コンタクト禁止部屋ではコンタクトは禁止 */
    if (state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].iscontact == "0") {
      setContactErrorSnackOpen(true);
      return;
    }

    if (isvip == "1") {
      setErrorSnackOpen(true);
      return;
    }
    let resturl = "";
    const targetroom = state.chatrooms.filter((f) => f.memberlist.length === 1 && f.memberlist[0].honbuid === honbuid);
    //console.log("一人部屋チェック", targetroom);
    if (targetroom.length > 0) {
      //let currentroomid = localStorage.getItem("currentRoomid");
      dispatch({ type: "CHANGE_ROOM", payload: { to: targetroom[0].roomid, from: state.currentRoomid } });
      localStorage.setItem("currentRoomid", targetroom[0].roomid);
      localStorage.setItem("parentRoomid", targetroom[0].roomid);
      localStorage.setItem("isbot", "0");
      handleMeClose();
      handleClose();
      return;
    }

    resturl = `/createRoom?honbuid=${myhonbuid}&mainhonbuids=${honbuid}`;

    const result = await custom_axios(API_URL + resturl);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      fetchData("").then((r) => {
        //console.log("INIT_CONVERSASTION", r);
        dispatch({ type: "INIT_CONVERSASTION", payload: r });
        //addChatConnection(result.data.roomid);
        hubConnection.invoke("addGroup", result.data.roomid);
        //let currentroomid = localStorage.getItem("currentRoomid");
        dispatch({ type: "CHANGE_ROOM", payload: { to: result.data.roomid, from: state.currentRoomid } });
        localStorage.setItem("currentRoomid", result.data.roomid);
        localStorage.setItem("parentRoomid", result.data.roomid);
        localStorage.setItem("isbot", "0");
      });
    }
    handleMeClose();
    handleClose();
  };

  const fetchData = async (roomid) => {
    let honbuid = localStorage.getItem("honbuid");
    const httpstring =
      roomid !== ""
        ? `/getConversationList?honbuid=${honbuid}`
        : `/getConversationListOne?honbuid=${honbuid}&roomid=${roomid}`;
    const result = await custom_axios(API_URL + httpstring);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      return result.data;
    }
  };

  useEffect(() => {
    //console.log("GetUser前", honbuid);
    const proc = async () => {
      ////console.log("GetUser前", honbuid);
      if (open) {
        const result = await custom_axios(API_URL + `/GetUser?honbuid=${honbuid}`);
        const status = result.status;
        if (status === 401) {
          history.replace("/loginError");
        }
        if (status === 200) {
          setUrl(STATIC_URL + `/downloadAvatar?honbuid=${honbuid}&fileno=${result.data.fileno}`);
          setName(result.data.name);
          setSubname(result.data.subname);
          setJobname(result.data.memo);
          setHpname(result.data.hpname);
          setIsadmin(result.data.isadmin);
          setIsvip(result.data.isvip);
          setIsbukaiadmin(result.data.isbukaiadmin);
        }
      }
    };
    proc();
  }, [open]);

  // useEffect(() => {
  //   //console.log("GetUser前", honbuid);
  //   const proc = async () => {
  //     ////console.log("GetUser前", honbuid);
  //     if (open) {
  //       const result = await custom_axios(API_URL + `/getFavorCount?honbuid=${honbuid}`);
  //       const status = result.status;
  //       if (status === 401) {
  //         history.replace("/loginError");
  //       }
  //       if (status === 200) {
  //         setFavorcount(result.data.count);
  //       }
  //     }
  //   };
  //   proc();
  // }, [open]);

  const [openFavor, setOpenFavor] = useState(false);
  const handleFavorClose = () => {
    setOpenFavor(false);
  };
  const MemoFavor = useMemo(() => {
    return <ShowFavorMember open={openFavor} handleClose={handleFavorClose}></ShowFavorMember>;
  }, [openFavor]);
  const [errorsnackopen, setErrorSnackOpen] = useState(false);
  const [errorcontactsnackopen, setContactErrorSnackOpen] = useState(false);
  return (
    <Dialog open={open} onClose={handleClose}>
      <StyledDialogTitle id="max-width-dialog-title">ユーザー情報</StyledDialogTitle>
      <StyledDialogContent>
        {MemoFavor}
        <div className="show-user-wrap">
          <div className="show-user-image-back">
            <img
              src={url}
              alt=""
              className="show-user-image-back-image"
              //style={isCurrent ? { border: "1px solid white" } : null}
            />
          </div>

          <div className="show-user-image-wraper">
            <img
              src={url}
              width="150"
              height="150"
              alt=""
              className="show-user-image"
              //style={isCurrent ? { border: "1px solid white" } : null}
            />
          </div>

          {/* <div className="show-user-image-wraper-good" onClick={() => setOpenFavor(true)}>
            <div className="favor-icon">
              <FavoriteIcon></FavoriteIcon>
            </div>
            <div className="favor-count">{favorcount}</div>
          </div> */}
        </div>
        <div className="label-input">
          <StyledFlexibleDiv>
            {isvip == "1" && <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>}
            {isbukaiadmin == "1" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="部会管理"></StyledChip>
            ) : isbukaiadmin == "2" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="部会部屋"></StyledChip>
            ) : (
              <div></div>
            )}
            {isadmin == "1" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="システム管理"></StyledChip>
            ) : isadmin == "2" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="施設管理"></StyledChip>
            ) : isadmin == "3" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="部屋管理"></StyledChip>
            ) : isadmin == "6" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="施設横断部屋"></StyledChip>
            ) : isadmin == "4" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="ユーザー管理"></StyledChip>
            ) : isadmin == "5" ? (
              <StyledChip variant="outlined" color="primary" size="small" label="部屋・ユーザー管理"></StyledChip>
            ) : (
              <div></div>
            )}
          </StyledFlexibleDiv>
        </div>
        <div className="label-input">
          <div className="form1-label">本部ID</div>
          <input type="text" className="form1-input" disabled value={honbuid} />
        </div>
        <div className="label-input">
          <div className="form1-label">施設</div>
          <input type="text" className="form1-input" disabled value={hpname} />
        </div>

        <div className="label-input">
          <div className="form1-label">部署</div>
          <input type="text" className="form1-input" disabled value={subname} />
        </div>
        <div className="label-input">
          <div className="form1-label">職種</div>
          <input type="text" className="form1-input" disabled value={jobname} />
        </div>
        <div className="label-input">
          <div className="form1-label">名前</div>
          <input type="text" className="form1-input" disabled value={name} />
        </div>
        {/* <div className="label-input">
          <div className="form1-label">番号</div>
          <input type="text" className="form1-input" disabled value={phone} />
        </div> */}
      </StyledDialogContent>
      <DialogActions>
        {honbuid != myhonbuid && (
          <Button onClick={handleCreate} variant="outlined" color="primary">
            コンタクト開始
          </Button>
        )}

        <Button onClick={handleClose} variant="outlined" color="primary">
          閉じる (Esc)
        </Button>
        <Snackbar
          open={errorsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setErrorSnackOpen(false);
          }}
          message="VIPとはコンタクトできません。"
        ></Snackbar>
        <Snackbar
          open={errorcontactsnackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={() => {
            setContactErrorSnackOpen(false);
          }}
          message="このグループではコンタクトできません。"
        ></Snackbar>
      </DialogActions>
    </Dialog>
  );
}
export default ShowUserInfo;
