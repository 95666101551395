/* eslint-disable react/prop-types */
import React from "react";
import "./MessageList.css";

function BotPhotoMessage({ url, handleImgClickOpen }) {
  return (
    <img
      src={url}
      //height="300"
      alt=""
      className="chat-message-image"
      onClick={() => handleImgClickOpen()}
      //style={isCurrent ? { border: "1px solid white" } : null}
    />
  );
}

export default BotPhotoMessage;
