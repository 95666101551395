/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { API_URL, custom_axios, custom_axios_post, jobOptions } from "./Common";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import Slide from "@mui/material/Slide";
import styled from "@emotion/styled";
import ShowAlumniButtonSelect from "./ShowAlumniButtonSelect";
import ShowAlumniChat from "../Common/ShowAlumniChat";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DataGridPro as DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  // GridToolbarDensitySelector,
  // GridToolbarExport,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";
import ShowCommon from "../Common/ShowCommon";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});
const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;
function CustomToolbar() {
  //プロパティから utf8WithBom = Turue を渡してあげる。
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      {/* <GridToolbarDensitySelector /> */}
      {/* <GridToolbarExport csvOptions={{ utf8WithBom: true }} /> */}
    </GridToolbarContainer>
  );
}
function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}
const headCells = [
  { id: "kbns", label: "区分" },
  { id: "title", label: "概要" },
  { id: "jobs", label: "職種" },
  { id: "time", label: "送信日付" },
  { id: "target", label: "対象数" },
  { id: "readedcnt", label: "既読数" },
  { id: "issend", label: "状態" },
  { id: "option", label: "オプション" },
];

const StyledDataGrid = styled("div")`
  height: 100%;
  weight: 100%;
`;
const StyledRapDataGrid = styled("div")`
  height: calc(100vh - 130px);
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledRoot = styled("div")`
  width: 100%;
`;
const StyledStatusOK = styled("div")`
  border-radius: 16px;
  color: rgb(27, 94, 32);
  border: 1px solid rgb(46, 125, 50);
  padding: 0px 5px;
`;

const StyledStatusNG = styled("div")`
  border-radius: 16px;
  color: rgb(230, 81, 0);
  border: 1px solid rgb(237, 108, 2);
  padding: 0px 5px;
`;

const StyledColChat = styled("div")`
  color: rgb(27, 94, 32);
`;
const StyledColNews = styled("div")`
  color: rgb(1, 87, 155);
`;

const dateFormat = {
  _fmt: {
    yyyy: function (date) {
      return date.getFullYear() + "";
    },
    MM: function (date) {
      return ("0" + (date.getMonth() + 1)).slice(-2);
    },
    dd: function (date) {
      return ("0" + date.getDate()).slice(-2);
    },
    hh: function (date) {
      return ("0" + date.getHours()).slice(-2);
    },
    mm: function (date) {
      return ("0" + date.getMinutes()).slice(-2);
    },
    ss: function (date) {
      return ("0" + date.getSeconds()).slice(-2);
    },
  },
  _priority: ["yyyy", "MM", "dd", "hh", "mm", "ss"],
  format: function (date, format) {
    return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format);
  },
};
function ShowAlumni({ open, handleClose }) {
  const history = useHistory();
  const [alumniList, setAlumniList] = useState([]);
  const [alumniListColumns, setAlumniListColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAlumni, setSelectedAlumni] = useState({
    open: false,
    alumniid: "",
    isnew: true,
    issend: "",
    title: "",
    jobs: "",
    kbns: "",
    conversationlist: [],
  });

  const [selectedstart, setSelectedStart] = useState(() => {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    return d;
  });
  const [selectedend, setSelectedEnd] = useState(new Date());
  const kbnOptions = [
    { label: "再就職キャリア相談", value: "chat" },
    { label: "NEWS", value: "news" },
  ];
  const [selectedKbn, setSelectedKbn] = useState(kbnOptions);
  const [selectedJob, setSelectedJob] = useState(jobOptions);
  const [openDelModal, setOpenDelModal] = useState({
    open: false,
    alumniid: "",
  });
  const MemoDel = useMemo(() => {
    return (
      <ShowCommon
        open={openDelModal.open}
        title="削除しますか？"
        handleClose={() => {
          setOpenDelModal((prev) => ({ ...prev, open: false }));
        }}
        handleCallBack={() => handleDell(openDelModal.alumniid)}
      ></ShowCommon>
    );
  }, [openDelModal.open]);
  const initproc = async () => {
    setLoading(true);
    const d = new Date();
    d.setDate(d.getDate() - 7);
    setSelectedStart(d);
    setSelectedEnd(new Date());
    /* DataGrid定義 */
    setAlumniListColumns(
      headCells.map((v) => {
        if (v.id == "issend") {
          return {
            field: v.id,
            headerName: v.label,
            flex: 1,
            valueGetter: (value, row) => {
              if (row.issend == "1") {
                return "送信済";
              } else {
                return "未";
              }
            },
            renderCell: (params) => {
              if (params.row.issend == "1") {
                return <StyledStatusOK>送信済</StyledStatusOK>;
              } else {
                return <StyledStatusNG>未</StyledStatusNG>;
              }
            },
          };
        }

        if (v.id == "jobs") {
          return {
            field: v.id,
            headerName: v.label,
            flex: 2,
            valueGetter: (value, row) => {
              let retstr = "";
              const joblist = row.jobs.split("_");

              for (let i = 0; i < joblist.length; i++) {
                const fil = jobOptions.filter((f) => f.value == joblist[i]);
                if (fil.length > 0) {
                  retstr += fil[0].label + " ";
                }
              }

              return retstr;
            },
          };
        }
        if (v.id == "option") {
          return {
            field: v.id,
            headerName: v.label,
            flex: 1,

            renderCell: (params) => {
              return (
                <ShowAlumniButtonSelect
                  handleRun={() => handleRun(params.row)}
                  handleDell={() => setOpenDelModal((prev) => ({ ...prev, open: true, alumniid: params.row.alumniid }))}
                ></ShowAlumniButtonSelect>
              );
            },
          };
        }
        if (v.id == "kbns") {
          return {
            field: v.id,
            headerName: v.label,
            flex: 1,
            valueGetter: (value, row) => {
              if (row.kbns == "chat") {
                return "再就職キャリア相談";
              } else {
                return "NEWS";
              }
            },
            renderCell: (params) => {
              if (params.row.kbns == "chat") {
                return <StyledColChat>再就職キャリア相談</StyledColChat>;
              } else {
                return <StyledColNews>NEWS</StyledColNews>;
              }
            },
          };
        }
        if (v.id == "time" || v.id == "title") {
          return {
            field: v.id,
            headerName: v.label,
            flex: 2,
          };
        }
        return { field: v.id, headerName: v.label, flex: 1 };
      })
    );
    fetchdata(d, new Date(), selectedKbn, selectedJob);
  };
  const fetchdata = async (from, to, kbns, jobs) => {
    /* データ取得 */
    let honbuid = localStorage.getItem("honbuid");
    let joinkbns = kbns
      .map((e) => {
        return e.value;
      })
      .join("_");
    let joinjobs = jobs
      .map((e) => {
        return e.value;
      })
      .join("_");
    const body = {
      honbuid: honbuid,
      kbns: joinkbns,
      jobs: joinjobs,
      from: dateFormat.format(from, "yyyy/MM/dd"),
      to: dateFormat.format(to, "yyyy/MM/dd"),
    };
    const result = await custom_axios_post(API_URL + "/GetAlumniList", body);

    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setAlumniList(result.data.list);
    }
  };

  useEffect(() => {
    if (open) {
      initproc();
    }
  }, [open]);

  const handleRun = async (row) => {
    setSelectedAlumni({
      open: true,
      alumniid: row.alumniid,
      isnew: false,
      issend: row.issend,
      title: row.title,
      jobs: row.jobs,
      kbns: row.kbns,
      conversationlist: row.conversationlist,
    });
  };
  const handleDell = async (q) => {
    const result = await custom_axios(API_URL + `/delAlumni?alumniid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setOpenDelModal((prev) => ({ ...prev, open: false }));
      fetchdata(selectedstart, selectedend, selectedKbn, selectedJob);
    }
  };
  const handleAlumniClose = () => {
    setSelectedAlumni((prev) => ({ ...prev, open: false }));
  };
  const MemoAlumniChat = useMemo(() => {
    return (
      <ShowAlumniChat
        open={selectedAlumni.open}
        alumniid={selectedAlumni.alumniid}
        isnew={selectedAlumni.isnew}
        issend={selectedAlumni.issend}
        title={selectedAlumni.title}
        kbns={selectedAlumni.kbns}
        jobs={selectedAlumni.jobs}
        conversationlist={selectedAlumni.conversationlist}
        handleClose={handleAlumniClose}
        afterProc={() => {
          handleAlumniClose();
          fetchdata(selectedstart, selectedend, selectedKbn, selectedJob);
        }}
      />
    );
  }, [selectedAlumni]);
  const [sortModel, setSortModel] = React.useState([]);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({});
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <StyledDialogTitle id="max-width-dialog-title">
        {MemoAlumniChat}
        {MemoDel}
        <div className="show-member-search">
          <div className="show-member-search">メッセージ送信</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={async () => {
                let honbuid = localStorage.getItem("honbuid");
                const result = await custom_axios(API_URL + `/getAlumniId?honbuid=${honbuid}`);
                const status = result.status;
                if (status === 200) {
                  var id = result.data.id;
                  setSelectedAlumni({
                    open: true,
                    alumniid: id,
                    isnew: true,
                    issend: "",
                    title: "",
                    jobs: "",
                    kbns: "",
                    conversationlist: [],
                  });
                }
              }}
              variant="outlined"
            >
              メッセージ作成
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={() => handleClose()} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledRoot>
          <div className="user-select-area">
            <div className="user-select-subarea">
              <div className="date-select">期間:</div>
              <div className="date-select">
                <LocalizationProvider adapterLocale={ja} dateAdapter={AdapterDateFns} dateFormats={{ year: "yyyy年" }}>
                  <DatePicker
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    id="date-picker-dialog"
                    format="yyyy/MM/dd"
                    value={selectedstart}
                    onChange={(e) => {
                      setSelectedStart(e);
                      fetchdata(e, selectedend, selectedKbn, selectedJob);
                    }}
                    slotProps={{ textField: { size: "small" }, calendarHeader: { format: "yyyy/MM" } }}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-select">～</div>
              <div className="date-select">
                <LocalizationProvider adapterLocale={ja} dateAdapter={AdapterDateFns} dateFormats={{ year: "yyyy年" }}>
                  <DatePicker
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    id="date-picker-dialog"
                    format="yyyy/MM/dd"
                    value={selectedend}
                    onChange={(e) => {
                      setSelectedEnd(e);
                      fetchdata(selectedstart, e, selectedKbn, selectedJob);
                    }}
                    slotProps={{ textField: { size: "small" }, calendarHeader: { format: "yyyy/MM" } }}
                  />
                </LocalizationProvider>
              </div>
              {/* 区分 */}
              <div className="multi-select">
                <MultiSelect
                  options={kbnOptions}
                  value={selectedKbn}
                  onChange={(e) => {
                    setSelectedKbn(e);
                    fetchdata(selectedstart, selectedend, e, selectedJob);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              {/* 職種 */}
              <div className="multi-select">
                <MultiSelect
                  options={jobOptions}
                  value={selectedJob}
                  onChange={(e) => {
                    setSelectedJob(e);
                    fetchdata(selectedstart, selectedend, selectedKbn, e);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
            </div>
          </div>

          {loading ? (
            <StyledRapDataGrid>
              <StyledCircularProgress />
            </StyledRapDataGrid>
          ) : (
            <StyledRapDataGrid>
              <StyledDataGrid>
                <DataGrid
                  columnVisibilityModel={columnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                  filterModel={filterModel}
                  onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                  getRowId={(row) => row.alumniid}
                  hideFooterSelectedRowCount={true}
                  rows={alumniList}
                  columns={alumniListColumns}
                  pagination
                  density={"compact"}
                  // checkboxSelection
                  disableRowSelectionOnClick
                  slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination,
                  }}
                  // initialState={{
                  //   columns: {
                  //     columnVisibilityModel: {
                  //       honbuid: false,
                  //     },
                  //   },
                  // }}
                  // localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                ></DataGrid>
              </StyledDataGrid>
            </StyledRapDataGrid>
          )}
        </StyledRoot>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowAlumni;
