/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
function ShowBukaiAdminSelect({ initvalue, callBack, honbuid, editable }) {
  const [role, setRole] = React.useState("0");

  /* 横断部屋は、システム管理者のみが付与できる */
  const handleChange = (event) => {
    setRole(event.target.value);
    callBack(honbuid, event.target.value);
  };
  useEffect(() => {
    setRole(initvalue);
  }, [initvalue]);
  return (
    <FormControl size="small">
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={role}
        onChange={handleChange}
        inputProps={{ readOnly: !editable }}
      >
        <MenuItem value={"0"}>-</MenuItem>
        <MenuItem value={"1"}>管理</MenuItem>
        <MenuItem value={"2"}>部屋</MenuItem>
      </Select>
    </FormControl>
  );
}

export default ShowBukaiAdminSelect;
