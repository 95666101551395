/* eslint-disable react/prop-types */
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

function ShowCommonDetailMessage({ open, title, detailMessage, handleClose, handleCallBack }) {
  const splitDetailMessage = detailMessage.split("@");

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">{title}</StyledDialogTitle>
      <DialogContent>
        {splitDetailMessage.map((ele) => {
          return <p key={ele}>{ele}</p>;
        })}
        <div className="logoff-wrap">
          <Button onClick={handleCallBack} style={{ margin: "5px" }} variant="contained" color="primary">
            はい
          </Button>
          <Button onClick={handleClose} style={{ margin: "5px" }} variant="contained">
            いいえ
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ShowCommonDetailMessage;
