/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { CALENDAR_URL } from "../Common/Common";
//import { Store } from "../Store/store";
import "react-quill/dist/quill.snow.css"; // ES6
import styled from "@emotion/styled";
import "./ShowNote.css";
import Iframe from "react-iframe";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;

function ShowCalendar({ open, handleClose, roomid, honbuid }) {
  //const ref = useRef(null);

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">カレンダー</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                handleClose();
              }}
              variant="outlined"
            >
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent className="quill-scroll-container">
        <Iframe
          url={CALENDAR_URL + "?honbuid=" + honbuid + "&roomid=" + roomid}
          width="100%"
          height="100%"
          id=""
          className=""
          display="block"
          position="relative"
        />
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowCalendar;
