/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "./ShowUserInfo.css";
import React from "react";
import Dialog from "@mui/material/Dialog";
function ShowBotImageInfo({ open, url, handleClose }) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <div className="">
        <img
          src={url}
          // width="200"
          // height="200"
          alt=""
          className="show-image"
        />
      </div>
    </Dialog>
  );
}
export default ShowBotImageInfo;
